import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  imports: [
  ],
  declarations: [
    DashboardComponent,
  ],
})
export class DashboardModule { }
