<nb-card>
  <nb-card-header>
    <div>
    <h2 class="card-title">PEOPLE</h2>
      <input nbInput type="text" (keyup)="search()" [(ngModel)]="searchPerson" placeholder="Search...">
    </div>
    <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditPerson('')">+Add</button>
  </nb-card-header>
  <nb-card-body [nbSpinner]="loading" style="max-height: 75svh;overflow: auto;"
                nbSpinnerStatus="primary"
                nbSpinnerSize="giant">
    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>
      <ng-container *ngFor="let column of columnsHead; let index = index"
                    [nbTreeGridColumnDef]="column"
                    [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
          {{column}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column.toLowerCase().split(' ').join('_')] || '-'}}</td>
      </ng-container>
      <ng-container [nbTreeGridColumnDef]="'actions'">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditPerson(row.data.id)">Edit</button>
        </td>
      </ng-container>
    </table>
  </nb-card-body>
</nb-card>
