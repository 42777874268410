import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NbDialogService, NbTabComponent, NbTabsetComponent, NbToastrService} from '@nebular/theme';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'ngx-add-edit-entry',
  templateUrl: './add-edit-entry.component.html',
  styleUrls: ['./add-edit-entry.component.scss'],
})
export class AddEditEntryComponent {
  entry: any = {
    horses: [],
    payer: {},
    payee: {},
    riders: [],
    trainer: {},
  };
  searching = {
    type: '',
    modalHeader: '',
    search: '',
    horseOwner: 0,
  };
  entryNumber: any;
  checkEntryAPI = false;
  getEntryAPI = false;
  getSearch = false;
  noResults = false;

  @ViewChild('dialogSearch', {static: true})
  dialogSearch: ElementRef;
  @ViewChild('personHorseModal', {static: true})
  personHorseModal: ElementRef;
  @ViewChild('dialogCompetitionSection', {static: true})
  dialogCompetitionSection: ElementRef;
  @ViewChild('dialogFolio', {static: true})
  dialogFolio: ElementRef;
  @ViewChild('dialogPayment', {static: true})
  dialogPayment: ElementRef;
  @ViewChild('autoInput') input;
  @ViewChild('tabSet') tabSetEl: NbTabsetComponent;
  @ViewChild('entryTab') entryTabEl: NbTabComponent;

  competitionSectionNumber: any = '';
  competitionSectionSearch: string = '';
  competitionSectionSelected: any = null;
  competitionSectionType: any = '';
  competitionSectionRider: any;
  competitionSectionSearchResult: boolean = false;

  // resultsCompetitionModal: any = [];
  options: any[] = [];
  resultsCompetitionModal = of(this.options);
  billing = {
    tab: 0,
    amount: 0,
    cheque: {
      initials: '',
      cheque_number: '',
      memo: '',
    },
  };
  editEntryElement = '';
  entries: any[] = [];
  selectedEntry: any = '';
  resultElements: any[] = [];
  folios: any[] = [];
  competitions: any[] = [];
  entryBilling: any;
  entryBillingBehavior: any;
  selectedCompetition: any = null;
  horseShow: any;
  protected readonly Object = Object;
  editUuid: string = '';
  edit: boolean = false;
  editEntryData: any = {};
  editFolio: { folio: any; index: number, comment: string };
  date: Date;

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService, private dialogService: NbDialogService) {

    this.horseShow = JSON.parse(localStorage.getItem('sms_default_horse_show'));

    this.route.params.subscribe(params => {
      if (params['uuid']) {
        const id = params['uuid'];
        this.getEntryAPI = true;
        this.showService.getHSEntryById(id).then(
          (res: any) => {
            this.setEntry(res);
          },
        );
      } else {
        this.resetForm();
        this.getEntryTypes();
      }
    });
  }

  setEntry(entry: any) {
    this.getEntryAPI = false;
    this.edit = true;
    this.editEntryData = JSON.parse(JSON.stringify(entry));
    this.entry.horses = entry.horses;
    this.entry.payee = entry.payee;
    this.entry.payer = entry.payer;
    this.entry.trainer = entry.trainer;
    this.entry.riders = entry.riders;
    this.entryNumber = entry.entry_number;
    this.getEntryTypes();
  }

  searchEntry() {
    if (!this.checkEntryAPI) {
      this.checkEntryAPI = true;
      setTimeout(() => {
        if (this.checkEntryAPI) {
          this.showService.getHSEntryByNumber(this.entryNumber).then(
            res => {
              this.setEntry(res);
              this.checkEntryAPI = false;
            }, error => {
              const number = this.entryNumber;
              this.resetForm();
              this.entryNumber = number;
              this.checkEntryAPI = false;
            });
        }
      }, 1500);
    }
  }

  searchAdd() {
    if (this.searching.modalHeader.toLowerCase() === 'horses') {
      this.editElement('horses', 0, true);
    } else {
      this.editElement('trainer', 0, true);
    }
  }

  removeHorseOwner(horsePosition: number, ownerPosition: number) {
    this.entry.horses[horsePosition].owners.splice(ownerPosition, 1);
  }

  searchCompetitionsSections() {
    this.competitionSectionSearchResult = false;
    if (!this.checkEntryAPI) {
      this.checkEntryAPI = true;
      setTimeout(() => {
        if (this.checkEntryAPI) {
          if (this.competitionSectionType === 'competition') {
            this.showService.getCompetitionByNumber(this.competitionSectionNumber).then(
              res => {
                this.checkEntryAPI = false;
                this.input.nativeElement.value = res.name;
                this.competitionSectionSelected = res;
              }, error => {
                this.checkEntryAPI = false;
                this.input.nativeElement.value = '';
                this.competitionSectionSearchResult = true;
                this.competitionSectionSelected = null;
              });
          } else {
            this.showService.getSectionByNumber(this.competitionSectionNumber).then(
              res => {
                this.checkEntryAPI = false;
                this.input.nativeElement.value = res.name;
                this.competitionSectionSelected = res;
              }, error => {
                this.checkEntryAPI = false;
                this.input.nativeElement.value = '';
                this.competitionSectionSearchResult = true;
                this.competitionSectionSelected = null;
              });
          }
        }
      }, 1500);
    }
  }

  getEntryTypes() {
    this.showService.getEntries().then(
      res => {
        if (res.length === 1) {
          this.selectedEntry = res[0];
        }
        this.entries = res;
        if (this.edit) {
          res.forEach((el: any) => {
            if (el.entry_type_number === this.editEntryData?.entry_type_number) {
              this.selectedEntry = el;
            }
          });
        }
      },
    );
  }

  searchModal(type: string, header: string, horseOwner: number) {
    this.searching.modalHeader = header;
    this.searching.horseOwner = horseOwner;
    this.searching.type = type;
    this.resultElements = [];
    this.searching.search = '';
    // @ts-ignore
    this.dialogService.open(this.dialogSearch, {closeOnBackdropClick: false});
  }

  search() {
    if (!this.getSearch) {
      this.getSearch = true;
      this.noResults = false;
      setTimeout(() => {
        if (this.searching.type === 'horse') {
          this.showService.getSearchHorse(this.searching.search).then(
            res => {
              this.noResults = res.length === 0;
              res.forEach(el => {
                el.name = el.current_name;
              });
              this.resultElements = res;
              this.getSearch = false;
            }, error => {
              this.getSearch = false;
            },
          );
        } else if (this.searching.type === 'entity') {
          this.showService.getSearchEntity(this.searching.search).then(
            res => {
              this.noResults = res.length === 0;
              this.resultElements = res;
              this.getSearch = false;
            }, error => {
              this.getSearch = false;
            },
          );
        } else if (this.searching.type === 'person') {
          this.showService.getSearchPeople(this.searching.search).then(
            res => {
              res.forEach(el => {
                el.name = el.first_name + ' ' + el.last_name;
              });
              this.noResults = res.length === 0;
              this.resultElements = res;
              this.getSearch = false;
            }, error => {
              this.getSearch = false;
            },
          );
        }
      }, 1500);
    }
  }

  addElement(element: any) {
    if (this.searching.type === 'person') {
      // get the person by id
      this.showService.getPersonById(element.id).then(
        res => {
          res.email_addresses.forEach((email: any) => {
            if (email.contact_preference === 1) {
              res.email = email.email_address;
            }
          });
          res.telephone_numbers.forEach((phone: any) => {
            if (phone.contact_preference === 1) {
              res.phone = '+' + phone.country_code + phone.telephone_number;
            }
          });
          if (this.searching.modalHeader.toLowerCase() === 'riders') {
            this.entry[this.searching.modalHeader.toLowerCase()].push(res);
          } else if (this.searching.modalHeader.toLowerCase() === 'owner') {
            this.entry.horses[this.searching.horseOwner].owners[this.searching.horseOwner] = res;
          } else {
            this.entry[this.searching.modalHeader.toLowerCase()] = res;
          }
        },
      );
    } else if (this.searching.type === 'horse') {
      this.showService.getHorseMemberships(element.id).then(
        res => {
          element.memberships = res;
          element.owners = [];
          this.entry[this.searching.modalHeader.toLowerCase()].push(element);
        },
      );
    }
  }

  editElement(type: string, i: number, create: boolean) {
    this.editEntryElement = type;
    if (!create) {
      if (type === 'horses' || type === 'riders') {
        this.editUuid = this.entry[type][i].id;
      } else if (type === 'owner' || type === 'payer' || type === 'payee' || type === 'trainer') {
        this.editUuid = this.entry[type].id;
      }
    }
    // @ts-ignore
    this.dialogService.open(this.personHorseModal, {closeOnBackdropClick: false});
  }

  changeEntry(next: boolean) {
    this.showService.getHSEntryNext(this.editEntryData.entry_number, next).then(
      res => {
        this.setEntry(res);
      },
    );
  }

  resetForm() {
    // go on first tab
    this.edit = false;
    this.selectedEntry = '';
    this.entryNumber = '';
    this.entry = {
      horses: [],
      payer: {},
      payee: {},
      riders: [],
      trainer: {},
    };
    this.tabSetEl.selectTab(this.entryTabEl);

  }

  saveEntry() {
    if (this.entryNumber === undefined || this.entryNumber === '') {
      this.toastService.warning('', 'Please check the BRIDLE NUMBER');
      return;
    }
    if (this.selectedEntry === '') {
      this.toastService.warning('', 'Please SELECT ENTRY');
      return;
    }
    if (this.entry.horses.length === 0) {
      this.toastService.warning('', 'Please add a HORSE');
      return;
    }
    if (this.entry.payer?.id === undefined) {
      this.toastService.warning('', 'Please add a PAYER');
      return;
    }
    if (this.entry.payee?.id === undefined) {
      this.toastService.warning('', 'Please add a PAYEE');
      return;
    }
    if (this.entry.riders.length === 0) {
      this.toastService.warning('', 'Please add a RIDER');
      return;
    }
    if (this.entry.trainer?.id === undefined) {
      this.toastService.warning('', 'Please add a TRAINER');
      return;
    }

    if (!this.edit) {
      const body = {
        horse_show_id: this.horseShow.id,
        entry_number: this.entryNumber,
        entry_type_number: this.selectedEntry.entry_type_number,
        horses: [],
        payer_id: this.entry.payer.id,
        payee_id: this.entry.payee.id,
        rider_ids: [],
        trainer_id: this.entry.trainer.id,
      };
      let addOwner = false;
      this.entry.horses.forEach((horse: any) => {
        const owners = [];
        if (horse.owners.length > 0) {
          horse.owners.forEach((owner: any) => {
            owners.push(owner.id);
          });
        } else if (horse.owners.length === 0) {
          this.toastService.warning('', 'Please add an OWNER for horse ' + horse.current_name);
          addOwner = true;
        }
        body.horses.push({
          horse_id: horse.id,
          owner_ids: owners,
        });
      });

      this.entry.riders.forEach(rider => {
        body.rider_ids.push(rider.id);
      });
      if (!addOwner) {
        this.showService.postEntry(body).then(
          res => {
            this.toastService.success('', 'Entry created successfully');
            this.resetForm();
          },
        );
      }
    } else {
      // PUT UPDATE
      // check if the elements are changed
      const body: any = {};
      if (JSON.stringify(this.entry.horses) !== JSON.stringify(this.editEntryData.horses)) {
        if (this.entry.horses.length > 0) {
          // check if the horses have the same owner as original, if not send the new owner of the horse
          this.entry.horses.forEach((horse: any) => {
            console.log(this.editEntryData.horses.some((obj: any) => obj.id === horse.id));
            let sameHorse = false;
            let index = 0;
            this.editEntryData.horses.forEach((horseData: any, i: number) => {
              index = i;
              if (horse.id === horseData.id) {
                sameHorse = true;
                // check owner
                if (JSON.stringify(horse.owners) !== JSON.stringify(horseData.owners)) {
                  if (horse.owners.length > 0) {
                    const newOwners = [];
                    horse.owners.forEach((el: any) => {
                      newOwners.push(el.id);
                    });
                    // update owner
                    this.showService.putEntryHorsOwner(this.editEntryData.id, horse.id, newOwners).then(
                      () => {
                        this.toastService.success('', 'Horse Owner changed successfully');
                      },
                    );
                  } else {
                    this.toastService.warning('', 'Please add an owner');
                  }
                }
              }
            });
            if (this.editEntryData.horses.length === index + 1 && !sameHorse) {
              // need to add the horse
              body.horses.push(horse.id);
            }
          });
        } else {
          this.toastService.warning('', 'Please add a horse');
          return;
        }
      }


      if (this.entry.riders.length > 0) {
        this.entry.riders.forEach((rider: any) => {
          let index = 0;
          this.editEntryData.riders.forEach((riderData: any, i: number) => {
            index = i;
            let sameRider = false;
            if (rider.id === riderData.id) {
              sameRider = true;
            }
            if (this.editEntryData.riders.length === index + 1 && !sameRider) {
              body.riders.push(rider.id);
            }
          });
        });
      } else {
        this.toastService.warning('', 'Please add a rider');
      }

      if (this.editEntryData.payee.id !== this.entry.payee.id) {
        this.showService.putEntryEntity(this.editEntryData.id, 'payee', this.entry.payee.id).then(
          () => {
            this.toastService.success('', 'Payee change successfully');
          },
        );
      }
      if (this.editEntryData.payer.id !== this.entry.payer.id) {
        this.showService.putEntryEntity(this.editEntryData.id, 'payer', this.entry.payer.id).then(
          () => {
            this.toastService.success('', 'Payer change successfully');
          },
        );
      }
      if (this.editEntryData.trainer.id !== this.entry.trainer.id) {
        this.showService.putEntryEntity(this.editEntryData.id, 'trainer', this.entry.trainer.id).then(
          () => {
            this.toastService.success('', 'Trainer change successfully');
          },
        );
      }
    }
  }

  handleReturn(event: any) {
    // if editUuid === '', add return
    if (this.editUuid === '') {
      // store
      if (this.searching.modalHeader.toLowerCase() === 'riders') {
        this.entry[this.searching.modalHeader.toLowerCase()].push(event);
      } else if (this.searching.modalHeader.toLowerCase() === 'horses') {
        event.owners = [];
        this.entry.horses.push(event);
      } else if (this.searching.modalHeader.toLowerCase() === 'owner') {
        this.entry.horses[this.searching.horseOwner].owners.push(event);
      } else {
        this.entry[this.searching.modalHeader.toLowerCase()] = event;
      }
    } else {
      if (this.editEntryElement === 'horses' || this.editEntryElement === 'riders') {
        this.entry[this.editEntryElement].forEach((el: any) => {
          if (el.id === event.id) {
            el = event;
          }
        });
      } else {
        this.entry[this.editEntryElement] = event;
      }
    }
  }

  // COMPETITION TAB
  selectCompetition(competition: any) {
    if (!competition.clicked) {
      this.competitions.forEach(el => {
        el.clicked = false;
      });
    }
    competition.clicked = !competition.clicked;

    if (competition.clicked) {
      this.selectedCompetition = competition;
    } else {
      this.selectedCompetition = null;
    }
  }

  addCompetitionSectionModal(type: string) {
    this.competitionSectionType = type;
    this.competitionSectionNumber = '';

    // open modal to create competition
    // @ts-ignore
    this.dialogService.open(this.dialogCompetitionSection, {closeOnBackdropClick: false});
    if (this.editEntryData.riders.length === 1) {
      this.competitionSectionRider = this.editEntryData.riders[0];
    }
    setTimeout(() => {
      this.input.nativeElement.value = '';
    }, 100);
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  searchCompetitionModal() {
    if (!this.checkEntryAPI) {
      this.checkEntryAPI = true;
      setTimeout(() => {
        if (this.checkEntryAPI) {
          this.showService.getCompetitionSectionSearch(this.competitionSectionSearch, this.competitionSectionType).then(
            res => {
              this.options = res;
              this.checkEntryAPI = false;
              this.resultsCompetitionModal = this.getFilteredOptions(this.input.nativeElement.value);
            }, error => {
              this.checkEntryAPI = false;
            });
        }
      }, 1500);
    }
  }

  changeRider() {
    if (this.selectedCompetition === null) {
      this.toastService.warning('', 'Please select competition');
      return;
    }
    // if 2 riders circle throw them
    // if more show modal or dropdown to select
    if (this.entry.riders.length === 2) {
      // change automatically to the other rider
      this.entry.riders.forEach((rider: any) => {
        if (rider.id !== this.selectedCompetition.rider_entity_id)
          this.selectedRider(rider);
      });
    } else {
      // show riders to select from
    }
  }

  getRidersForCompetition() {
    const riders = Object.assign([], this.entry.riders);
    riders.forEach((rider: any, i: number, object: any) => {
      if (rider.id === this.selectedCompetition.rider_entity_id) {
        object.splice(i, 1);
      }
    });
    return riders;
  }


  selectedRider(rider: any) {
    if (rider.id !== this.selectedCompetition.competition_entry_id) {
      this.showService.changeCompetitionRider(this.selectedCompetition.competition_entry_id, rider.id)
        .then((res: any) => {
          this.getEntryCompetitions();
        });
    }
  }

  scratchCompetition() {
    if (this.selectedCompetition === null) {
      this.toastService.warning('', 'Please select competition');
      return;
    }
    // endpoint for scratching

  }

  onSelectionChange(event: any) {
    this.competitionSectionNumber = event.competition_number;
    this.input.nativeElement.value = event?.name;
    this.competitionSectionSelected = event;
  }

  voidCompetition() {
    if (this.selectedCompetition === null) {
      this.toastService.warning('', 'Please select competition');
      return;
    }
    // endpoint to void competition
  }

  applyLateFee() {
    if (this.selectedCompetition === null) {
      this.toastService.warning('', 'Please select competition');
      return;
    }
  }

  private getEntryCompetitions() {
    this.showService.getCompetitionsEntry(this.editEntryData.id).then(
      res => {
        this.selectedCompetition = null;
        this.competitions = res;
        this.competitions.forEach(el => {
          el.clicked = false;
        });
      },
    );
  }

  private getEntryBilling() {
    this.showService.getBillingEntry(this.editEntryData.id).then(
      res => {
        this.entryBilling = res;
      },
    );
  }

  private getBillingBehavior() {
    this.showService.getBillingBehaviorEntry(this.editEntryData.id).then(
      res => {
        this.entryBillingBehavior = res;
      },
    );
  }

  createCompetitionSection() {
    if (this.competitionSectionRider.id === null) {
      this.toastService.warning('', 'Please select rider!');
      return;
    }
    if (this.competitionSectionNumber === '' || this.competitionSectionNumber === undefined) {
      this.toastService.warning('', `Please add ${this.competitionSectionType}!`);
      return;
    }
    if (this.competitionSectionSelected !== null) {
      const body: any = {
        entry_id: this.editEntryData.id,
        rider_entity_id: this.competitionSectionRider.id,
      };
      if (this.competitionSectionType === 'competition') {
        body.competition_number = this.competitionSectionNumber;
      } else {
        body.section_number = this.competitionSectionNumber;
      }
      this.showService.postCompetitionSection(body, this.competitionSectionType).then(
        res => {
          this.getEntryCompetitions();
        },
      );
    }
  }

  // BILLING TAB
  billingAdd(billing: any) {
    const body: any = {
      horse_show_id: this.editEntryData.horse_show_id,
      entry_number: this.editEntryData.entry_number,
      entry_id: this.editEntryData.id,
      billing_code: billing.billing_code,
      billing_item_template_id: billing.billing_item_template_id,
    };
    // console.log(this.entryBillingBehavior);
    this.entryBillingBehavior.forEach((el: any) => {
      if (el.code === billing.billing_code) {
        if (el.amount_based && !el.quantity_based) {
          billing.amount = billing.unit_price + billing.add;
          body.amount = billing.amount;
        } else if (!el.amount_based && el.quantity_based) {
          billing.quantity = billing.quantity + billing.add;
          billing.amount = billing.quantity * billing.unit_price;
          body.quantity = billing.add;
        } else if (!el.amount_based && !el.quantity_based) {
          // console.log('THIS IS THE SPECIAL ONE');
        }
      }
    });

    this.showService.postBillingEntryItem(body).then(
      res => {
        this.getEntryBilling();
      },
    );
    billing.add = '';
  }

  tabChange(event: any) {
    if (event.tabTitle === 'COMPETITIONS') {
      this.getEntryCompetitions();
    } else if (event.tabTitle === 'BILLING') {
      this.getEntryBilling();
      this.getBillingBehavior();
    } else if (event.tabTitle === 'FOLIO') {
      this.getFolio();
    }
  }

  billingPay(pay: boolean) {
    this.billing.amount = this.entryBilling.balance;
    // @ts-ignore
    this.dialogService.open(this.dialogPayment, {closeOnBackdropClick: false});
  }

  // FOLIO TAB

  getFolio() {
    this.showService.getEntryFolio(this.editEntryData.id).then(
      res => {
        this.folios = res;
      });
  }


  toggleFolio(folio: any, index: number) {
    const comment = '';
    this.editFolio = {folio, index, comment};
    this.folios[index].voided = false;

    // show the add voided message
    // @ts-ignore
    this.dialogService.open(this.dialogFolio, {closeOnBackdropClick: false});
  }

  folioVoid(b: boolean) {
    if (b) {
      const body = {
        itemType: this.editFolio.folio.itemType,
        comment: this.editFolio.comment,
      };
      this.showService.putVoidFolio(this.editFolio.folio.itemId, body).then(
        res => {
          this.getFolio();
        });
    }
  }

  submitPayment() {
    console.log(this.billing);
    switch (this.billing.tab) {
      // process cheque
      case 0: {
        if (this.billing.cheque.memo === '' ||
          this.billing.cheque.initials === '' || this.billing.cheque.cheque_number === '') {
          this.toastService.warning('', 'Please check the CHEQUE fields');
          break;
        }
        const body = {
          horse_show_id: this.horseShow.id,
          entry_number: this.editEntryData.entry_number,
          entry_id: this.editEntryData.id,
          amount: this.billing.amount,
          memo: this.billing.cheque.memo,
          initials: this.billing.cheque.initials,
          cheque_number: this.billing.cheque.cheque_number,
        };
        this.showService.postBillingCheque(body).then(
          res => {
            console.log(res);
          },
        );
        break;
      }
      case 1: {
        break;
      }
      case 2: {
        break;
      }
      case 3: {
        break;
      }
      case 4: {
        break;
      }
    }
  }

  openPayPal() {

  }
}
