<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} HORSE</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="horseForm" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-4 mb-3">
          <label>CURRENT NAME</label>
          <input nbInput fullWidth type="text" formControlName="current_name" id="current_name" name="current_name"
                 placeholder="Current name" [status]="form('current_name') ?'danger':'basic'">
        </div>
        <div class="col-4 mb-3">
          <label>BIRTH NAME</label>
          <input nbInput fullWidth type="text" formControlName="birth_name" id="birth_name" name="birth_name"
                 placeholder="Birth name (if different from current)"
                 [status]="form('birth_name') ?'danger':'basic'">
        </div>
        <div class="col-4 mb-3">
          <label>COMMERCIAL NAME</label>
          <input nbInput fullWidth type="text" formControlName="commercial_name" id="commercial_name"
                 name="commercial_name" [status]="form('commercial_name') ?'danger':'basic'"
                 placeholder="Commercial name (if different from current)">
        </div>


        <div class="col-3 mb-3">
          <label>DATE OF BIRTH</label>
          <input nbInput fullWidth placeholder="Date of birth" [nbDatepicker]="date_of_birth"
                 formControlName="date_of_birth" [status]="form('date_of_birth') ?'danger':'basic'">
          <nb-datepicker #date_of_birth></nb-datepicker>
        </div>
        <div class="col-3 mb-3">
          <label>COLOR</label>
          <nb-select fullWidth placeholder="Color" (selectedChange)="setColor($event)"
                     formControlName="color" [status]="form('color') ?'danger':'basic'">
            <nb-option *ngFor="let color of _colors" [value]="color.color_name">{{ color.color_name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-3 mb-3">
          <label>COLOR COMPLEMENT</label>
          <nb-select fullWidth placeholder="Color compliment (if applies)"
                     formControlName="color_complement" [status]="form('color_complement') ?'danger':'basic'">
            <nb-option *ngFor="let complements of colorComplements" [value]="complements">{{ complements }}</nb-option>
          </nb-select>
        </div>
        <div class="col-1 check-col mb-3" style="display: grid; justify-content: center;">
          <label>PONY?</label>
          <nb-checkbox status="basic" formControlName="pony"
                       style="display: flex; justify-content: center"></nb-checkbox>
        </div>
        <div class="col-2 mb-3">
          <label>SIZE</label>
          <nb-select fullWidth placeholder="SIZE - S, M, L" [status]="form('size_code') ?'danger':'basic'"
                     formControlName="size_code">
            <nb-option [value]="'S'">S</nb-option>
            <nb-option [value]="'M'">M</nb-option>
            <nb-option [value]="'L'">L</nb-option>
          </nb-select>
        </div>


        <div class="col-3 mb-3">
          <label>GENDER</label>
          <nb-select fullWidth placeholder="Gender"
                     [status]="form('gender') ?'danger':'basic'"
                     formControlName="gender">
            <nb-option [value]="'MALE'">MALE</nb-option>
            <nb-option [value]="'FEMALE'">FEMALE</nb-option>
          </nb-select>
        </div>
        <div class="col-1 check-col mb-3" style="display: grid; justify-content: center;">
          <label>GELDED (IF MALE)</label>
          <nb-checkbox status="basic" formControlName="gelded"
                       style="display: flex; justify-content: center"></nb-checkbox>
        </div>
        <div class="col-3 mb-3">
          <label>GELDED DATE (IF GELDING)</label>
          <input nbInput fullWidth placeholder="Gelded Date" [nbDatepicker]="gelded_date" formControlName="gelded_date">
          <nb-datepicker #gelded_date></nb-datepicker>
        </div>
        <div class="col-2 mb-3">
          <label>HEIGHT (HANDS)</label>
          <input nbInput fullWidth type="text" formControlName="height_hands" id="height_hands" name="height_hands"
                 placeholder="Hands" [status]="form('height_hands') ?'danger':'basic'">
        </div>
        <div class="col-1 mb-3">
          <label>HEEL</label>
          <input nbInput fullWidth type="text" formControlName="heel_measurement" id="heel_measurement"
                 name="heel_measurement"
                 placeholder="Heel" [status]="form('heel_measurement') ?'danger':'basic'">
        </div>
        <div class="col-2 mb-3">
          <label>HEIGHT (CM)</label>
          <input nbInput fullWidth type="number" formControlName="height_cm" id="height_cm" name="height_cm"
                 placeholder="Height CM" [status]="form('height_cm') ?'danger':'basic'">
        </div>


        <div class="col-12 mb-3">
          <label>MARKINGS</label>
          <input nbInput fullWidth type="text" formControlName="markings" id="markings" name="markings"
                 placeholder="Markings" [status]="form('markings') ?'danger':'basic'">
        </div>


        <div class="col-4 mb-3">
          <label>BREED</label>
          <nb-select fullWidth placeholder="Breed"
                     formControlName="breed">
            <nb-option *ngFor="let breed of _breeds" [value]="breed.name">{{ breed.name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-4 mb-3">
          <label>BREED (OTHER)</label>
          <input nbInput fullWidth type="text" formControlName="breed_other" id="breed_other" name="breed_other"
                 placeholder="If not option from chooser">
        </div>
        <div class="col-4 mb-3">
          <label>BREEDER</label>
          <input nbInput fullWidth type="text" formControlName="breeder" id="breeder" name="breeder"
                 placeholder="Breeder name" [status]="form('breeder') ?'danger':'basic'">
        </div>


        <div class="col-4 mb-3">
          <label>SIRE</label>
          <input nbInput fullWidth type="text" formControlName="sire" id="sire" name="sire"
                 placeholder="Sire name" [status]="form('sire') ?'danger':'basic'">
        </div>
        <div class="col-4 mb-3">
          <label>DAM</label>
          <input nbInput fullWidth type="text" formControlName="dam" id="dam" name="dam"
                 placeholder="Dam name" [status]="form('dam') ?'danger':'basic'">
        </div>
        <div class="col-4 mb-3">
          <label>DAM SIRE</label>
          <input nbInput fullWidth type="text" formControlName="dam_sire" id="dam_sire" name="dam_sire"
                 placeholder="Dam sire name" [status]="form('dam_sire') ?'danger':'basic'">
        </div>


        <div class="col-4 mb-3">
          <label>COUNTRY OF ORIGIN</label>
          <nb-select fullWidth placeholder="Birth country ()"
                     [status]="form('country_of_origin') ?'danger':'basic'"
                     formControlName="country_of_origin">
            <nb-option [value]="'USA'">USa</nb-option>
            <nb-option [value]="'CAN'">CAN</nb-option>
          </nb-select>
        </div>
        <div class="col-4 mb-3">
          <label>MICROCHIP NUMBER</label>
          <input nbInput fullWidth type="text" formControlName="microchip_value" id="microchip_value"
                 name="microchip_value" [status]="form('microchip_value') ?'danger':'basic'"
                 placeholder="Microchip #">
        </div>
        <div class="col-4 mb-3">
          <label>UELN</label>
          <input nbInput fullWidth type="text" formControlName="ueln" id="ueln" name="ueln"
                 placeholder="UELN" [status]="form('ueln') ?'danger':'basic'">
        </div>


        <div class="col-4 mb-3">
          <label>CURRENT FEDERATION</label>
          <nb-select fullWidth placeholder="Current federation (USEF/EC/FEI)"
                     [status]="form('current_national_federation') ?'danger':'basic'"
                     formControlName="current_national_federation">
            <nb-option [value]="'USEF'">USEF</nb-option>
            <nb-option [value]="'EC'">EC</nb-option>
            <nb-option [value]="'FEI'">FEI</nb-option>
          </nb-select>
        </div>
        <div class="col-4 mb-3">
          <label>CURRENT FEDERATION #</label>
          <input nbInput fullWidth type="text" formControlName="current_national_id" id="current_national_id"
                 name="current_national_id" [status]="form('current_national_id') ?'danger':'basic'"
                 placeholder="Microchip #">
        </div>
        <div class="col-4 mb-3">
          <label>CURRENT FEI ID</label>
          <input nbInput fullWidth type="text" formControlName="current_fei_id" id="current_fei_id"
                 name="current_fei_id" [status]="form('current_fei_id') ?'danger':'basic'"
                 placeholder="UELN">
        </div>
      </div>

      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

        <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
                class="btn-save">{{ edit ? 'Update' : 'Save' }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="edit" class="col-8 mb-3">
  <nb-card-header>
    <label>MEMBERSHIP</label>
    <div class="row">
      <nb-select [(ngModel)]="membershipType" class="col-3 mb-3">
        <nb-option value="USEF">USEF</nb-option>
        <nb-option value="EC">EC</nb-option>
        <nb-option value="FEI">FEI</nb-option>
      </nb-select>

      <input nbInput type="text" [(ngModel)]="membershipId" style="margin: 0 10px" class="col-6 mb-3"
             placeholder="MEMBERSHIP ID">

      <button nbButton shape="semi-round" status="primary" class="col-2 mb-3"
              style="float:right" (click)="addHorseMembership()">Save
      </button>

    </div>
  </nb-card-header>

  <nb-card-body *ngIf="_horseMemberships.length > 0">
    <nb-list role="list">
      <nb-list-item *ngFor="let membership of _horseMemberships" class="row">
        <p class="data col-2">{{ membership.federation }}</p>
        <p class="data col-10">{{ membership.membership_id }}</p>
      </nb-list-item>
    </nb-list>
  </nb-card-body>
</nb-card>
