import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {NbToastrService} from '@nebular/theme';
import {Horse_show} from "../../../../models/horse_show";

@Component({
  selector: 'ngx-rule-add-edit',
  templateUrl: './rule-add-edit.component.html',
  styleUrls: ['./rule-add-edit.component.scss'],
})
export class RuleAddEditComponent {
  @Input() isModal: boolean = false;
  @Output() ruleEvent: EventEmitter<any> = new EventEmitter<any>();
  horse_show: Horse_show;
  ruleForm: FormGroup = new FormGroup({
    federation_code: new FormControl(''),
    discipline_code: new FormControl(''),
    article: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    listing_order: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });
  edit: boolean = false;
  _discipline = [
    {name: 'Jumper', option: 'J'},
    {name: 'Hunter', option: 'H'},
    {name: 'Equitation', option: 'E'},
    {name: 'Dressage', option: 'D'},
    {name: 'Driving', option: 'A'},
    {name: 'Combined Training', option: 'X'},
  ];

  constructor(private showService: HorseShowService, private toastService: NbToastrService) {
    this.horse_show = JSON.parse(localStorage.getItem('sms_default_horse_show'));
    this.ruleForm.controls['federation_code'].setValue(this.horse_show.federation_code);

  }

  onSubmit() {
    if (this.ruleForm.valid) {
      const body: any = {
        discipline_code: this.ruleForm.controls['discipline_code'].value,
        federation_code: this.ruleForm.controls['federation_code'].value,
        article: this.ruleForm.controls['article'].value,
        name: this.ruleForm.controls['name'].value,
        listing_order: this.ruleForm.controls['listing_order'].value,
      };

        this.showService.postCompetitionRule(body).then(res => {
          this.ruleForm.reset();
          this.toastService.success('', 'Rule created');
          if (this.isModal) {
            this.ruleEvent.emit(res);
          }
        }, error => {
          this.toastService.danger('', 'Error creating tax template');
        });
    } else {
      this.toastService.warning('', 'Please check the form');
    }
  }

  editExistingOrganizer(edit: boolean) {
    if (edit) {

    } else {
      this.ruleForm.controls['organizer_number'].setValue('');
    }
  }

  resetForm() {
    this.ruleForm.reset();
    // set the federation
    this.ruleForm.controls['federation_code'].setValue(this.horse_show.federation_code);
  }
}
