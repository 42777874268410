import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import {HorseShowOrganizerService} from './horse-show-organizer.service';
import {HorseShowService} from './horse-show.service';

import {Horse_show_organizer} from '../models/horse_show_organizer';
import {Horse_show} from '../models/horse_show';
import {User} from '../models/user';
import {AppSettings} from '../app.settings';

@Injectable({
  providedIn: 'root',
})
export class SessionService {

  private _user: Subject<User> = new BehaviorSubject<User>(new User({}));
  private _horse_show: Subject<Horse_show> = new BehaviorSubject<Horse_show>(new Horse_show({}));
  private _horse_show_organizer: Subject<Horse_show_organizer> =
    new BehaviorSubject<Horse_show_organizer>(new Horse_show_organizer({}));
  user$: Observable<User> = this._user.asObservable();
  horse_show_organizer$: Observable<Horse_show_organizer> = this._horse_show_organizer.asObservable();
  horse_show$: Observable<Horse_show> = this._horse_show.asObservable();

  constructor() {
  }

  setUser(user: User) {
    this._user.next(user);
  }

  setHorse_show_organizer(horse_show_organizer: Horse_show_organizer | null) {
    if (horse_show_organizer != null && horse_show_organizer.id) {
      localStorage.setItem(AppSettings.DEFAULT_HORSE_SHOW_ORGANIZER_STORAGE, JSON.stringify(horse_show_organizer));
    } else {
      localStorage.removeItem(AppSettings.DEFAULT_HORSE_SHOW_ORGANIZER_STORAGE);
    }
    if (horse_show_organizer != null) {
      this._horse_show_organizer.next(horse_show_organizer);
    }
  }

  setHorse_show(horse_show: Horse_show | null) {
    if (horse_show != null && horse_show.id) {
      localStorage.setItem(AppSettings.DEFAULT_HORSE_SHOW_STORAGE, JSON.stringify(horse_show));
    } else {
      localStorage.removeItem(AppSettings.DEFAULT_HORSE_SHOW_STORAGE);
    }
    if (horse_show != null) {
      this._horse_show.next(horse_show);
    } else {
      this._horse_show.next(new Horse_show({}));
    }
  }


}
