<nb-card class="list-card">
  <nb-card-header>
    <h2 class="card-title">Tax Templates</h2>
    <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addTax('')">+Add</button>
  </nb-card-header>
  <nb-card-body>
    <!--    <nb-list role="list">-->
    <!--      <nb-list-item *ngFor="let tax of _taxTemplate" style="display: inline-grid">-->
    <!--        <div class="row">-->
    <!--          <div class="col-2 mb-3">-->
    <!--            <label for="code">Code</label>-->
    <!--            <p id="code">{{ tax?.code }}</p>-->
    <!--          </div>-->
    <!--          <div class="col-3 mb-3">-->
    <!--            <label for="name">Name</label>-->
    <!--            <p id="name">{{ tax?.name }}</p>-->
    <!--          </div>-->
    <!--          <div class="col-2 mb-3">-->
    <!--            <nb-checkbox status="basic" [ngModel]="tax.applies_to_entry_fees" disabled>APPLIES TO ENTRY FEES</nb-checkbox>-->
    <!--          </div>-->
    <!--          <div class="col-2">-->
    <!--            <div class="form-check-label mb-3">-->
    <!--              <label for="effective">Effective date</label>-->
    <!--              <p id="effective">{{ tax.effective_date | date }}</p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="col-2">-->
    <!--            <div class="form-check-label mb-3">-->
    <!--              <label for="sunset">Sunset Date</label>-->
    <!--              <p id="sunset">{{ tax.sunset_date | date }}</p>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="col-1 mb-3">-->
    <!--            <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addTax(tax?.code)">Edit</button>-->
    <!--          </div>-->
    <!--          </div>-->
    <!--      </nb-list-item>-->
    <!--    </nb-list>-->
    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>
      <ng-container *ngFor="let column of columnsHead; let index = index"
                    [nbTreeGridColumnDef]="column"
                    [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
          {{ column }}
        </th>
        <td nbTreeGridCell
            *nbTreeGridCellDef="let row">{{ row.data[column.toLowerCase().split(' ').join('_')] || '-' }}
        </td>
      </ng-container>
      <ng-container [nbTreeGridColumnDef]="'actions'">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addTax(row.data.code)">
            Edit
          </button>
        </td>
      </ng-container>
    </table>
  </nb-card-body>
</nb-card>
