<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} TAX ITEM TEMPLATE</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="taxForm" class="needs-validation" novalidate>
      <div class="row">
        <div class="col-6 mb-3">
          <label>CODE</label>
          <input nbInput fullWidth type="text" formControlName="code" id="code" name="code"
                 placeholder="Code" required>
        </div>
        <div class="col-6 mb-3" style="margin-top: 30px">
          <nb-checkbox status="basic" formControlName="flag" disabled>This tax Applies to {{ horse_show.name }}
          </nb-checkbox>
        </div>

        <div class="col-12 mb-3">
          <label>NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="Name" required>
        </div>

        <div class="col-6 mb-3">
          <label>LISTING ORDER</label>
          <input nbInput fullWidth type="text" formControlName="listing_order" id="listing_order" name="listing_order"
                 placeholder="Listing order">
        </div>
        <div class="col-6 mb-3"  style="margin-top: 30px">
          <nb-checkbox status="basic" formControlName="applies_to_entry_fees" disabled>APPLIES TO ENTRY FEES
          </nb-checkbox>
        </div>

        <div class="col-6 mb-3">
          <label>EFFECTIVE DATE</label>
          <input nbInput fullWidth placeholder="Effective date" [nbDatepicker]="effective_date"
                 formControlName="effective_date">
          <nb-datepicker #effective_date></nb-datepicker>
        </div>
        <div class="col-6 mb-3">
          <label>SUNSET DATE</label>
          <input nbInput fullWidth placeholder="Sunset date" [nbDatepicker]="sunset_date" formControlName="sunset_date">
          <nb-datepicker #sunset_date></nb-datepicker>
        </div>

        <div class="col-6 mb-3"  style="margin-top: 30px">
          <nb-radio-group formControlName="percent_based">
            <nb-radio [value]="true">Percentage based</nb-radio>
            <nb-radio [value]="false">Flat amount</nb-radio>
          </nb-radio-group>
        </div>

        <div class="col-6">
          <label>PERCENTAGE (ENTER AS DECIMAL NUMBER)</label>
          <input nbInput fullWidth type="text" formControlName="percentage_rate" id="percentage_rate"
                 name="percentage_rate"
                 class=" mb-3" placeholder="Percentage (ENTER AS DECIMAL NUMBER)">

          <label>AMOUNT TO COLLECT PER ACCOUNT</label>
          <input nbInput fullWidth type="text" formControlName="amount" id="amount" name="amount"
                 class=" mb-3" placeholder="Amount to collect per account">
        </div>
      </div>
    </form>

    <div class="row" style="justify-content: center">
      <nb-list class="col-8">
        <nb-list-item *ngFor="let bill of _billing_templates">
          <nb-checkbox status="basic" [(ngModel)]="bill.check">{{ bill.code }} {{ bill.name }}</nb-checkbox>
        </nb-list-item>
      </nb-list>
    </div>
    <div style="display: flex; justify-content: space-between">
      <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>
      <button nbButton shape="semi-round" status="primary" (click)="onSubmit()"
            class="btn-save">{{ edit ? 'Update' : 'Save' }}
    </button>
    </div>

  </nb-card-body>
</nb-card>
