<nb-card accent="info">
  <nb-card-header>
    <h2 class="card-title"> ADD BILL ITEM CATEGORY</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()" class="arena-validation" novalidate>
      <br>
        <div class="col-6 mb-3">
          <label>CODE</label>
          <input nbInput fullWidth type="text" formControlName="code" name="name"
                 placeholder="CODE" required>
        </div>

        <div class="col-6 mb-3">
          <label>NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" name="name"
                 placeholder="NAME" required>
        </div>

        <div class="col-6 mb-3">
          <label>LISTING ORDER</label>
          <input nbInput fullWidth type="number" formControlName="listing_order" name="name"
                 placeholder="LISTING ORDER" required>
        </div>
      <button nbButton shape="semi-round" status="primary" type="submit">Save</button>
    </form>
  </nb-card-body>
</nb-card>
