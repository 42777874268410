<nb-card [accent]="!edit ? 'info' : 'warning'" [nbSpinner]="getEntryAPI" nbSpinnerStatus="primary">
  <nb-card-header>
    <h2 class="card-title">{{ edit ? 'EDIT' : 'ADD' }} ENTRY</h2>
    <p>ENTRY (BRIDLE) #</p>
    <div style="display: flex; justify-content: space-between">
      <input nbInput type="text" (keyup)="searchEntry()" [(ngModel)]="entryNumber" style="width: 30svw"
             placeholder="ENTER BRIDLE NUMBER">

      <nb-select placeholder="SELECT ENTRY" *ngIf="entries.length > 0" style="width: 20svw;"
                 [(selected)]="selectedEntry">
        <nb-option *ngFor="let entry of entries" [value]="entry"
                   (selectionChange)="selectedEntry = entry">{{ entry.name }}
        </nb-option>
      </nb-select>
    </div>
  </nb-card-header>
  <nb-tabset (changeTab)="tabChange($event)" id="tabSet" name="tabSet" #tabSet>
    <nb-tab tabTitle="ENTRY COMPONENTS" id="entryTab" name="entryTab" #entryTab>

      <ng-template #dialogSearch let-data let-ref="dialogRef">
        <nb-card size="large" style="width: 40svw;">
          <nb-card-header>SEARCH {{ searching.modalHeader }}
            <input nbInput fullWidth type="text" [(ngModel)]="searching.search" name="searchInput"
                   placeholder="Search..." (keyup)="search()" required>
          </nb-card-header>
          <nb-card-body>
            <nb-list>
              <nb-list-item *ngIf="noResults">
                <p>There are no results found!</p>
              </nb-list-item>
              <nb-list-item *ngFor="let element of resultElements" (click)="addElement(element); ref.close()"
                            style="cursor: pointer">
                {{ element.name }}
              </nb-list-item>
            </nb-list>
          </nb-card-body>
          <nb-card-footer style="justify-content: space-around;display: flex;">
            <button nbButton status="primary" (click)="searchAdd();ref.close()">+ADD</button>
            <button nbButton status="danger" (click)="ref.close()">CLOSE</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>

      <ng-template #personHorseModal let-data let-ref="dialogRef">
        <nb-card style="width: 90svw">
          <nb-card-body>
            <ngx-person-add-edit *ngIf="editEntryElement !== '' && editEntryElement !== 'horses'" [isModal]="true"
                                 [personId]="editUuid" (personEvent)="handleReturn($event)"></ngx-person-add-edit>
            <ngx-horse-add-edit *ngIf="editEntryElement !== '' && editEntryElement === 'horses'" [isModal]="true"
                                [horseId]="editUuid" (horseEvent)="handleReturn($event)"></ngx-horse-add-edit>
            <!--            <ngx-horse-add-edit  *ngIf="editEntryElement !== '' && editEntryElement === 'horses'" [isModal]="true" [horseId]="editUuid" (horseEvent)="handleHorse($event)"></ngx-horse-add-edit>-->
          </nb-card-body>
          <nb-card-footer style="justify-content: end;display: flex;">
            <button nbButton status="danger" (click)="ref.close()">CLOSE</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>

      <nb-card>
        <h4>HORSE / OWNER</h4>
        <div class="row">
          <div class="col-12" style="display: inline-flex;" *ngIf="entry.horses.length === 0">
            <div class="row" name="HORSE" style="width: inherit;">
              <div class="col-1" [style]="Object.keys(entry.horses).length === 0 ? 'display: flex' : ''">
                <span class="add-button" (click)="searchModal('horse', 'HORSES',0)"><nb-icon
                  icon="plus-outline"></nb-icon></span>
              </div>
              <div class="col-5">
                <p style="margin: 40px">PLEASE ADD HORSE</p>
              </div>
            </div>
          </div>
          <div *ngIf="entry.horses.length !== 0">
            <div *ngFor="let horse of entry.horses, let i = index" class="row" style="margin: auto">
              <div class="col-10">
                <div name="HORSE" class="row">
                  <div class="col-1">
                  <span *ngIf="i === 0" class="add-button" (click)="searchModal('horse', 'HORSES', 0)"><nb-icon
                    icon="plus-outline"></nb-icon></span>
                    <nb-icon icon="edit-2-outline" (click)="editElement('horses', i, false)" class="edit-button"></nb-icon>
                  </div>

                  <div class="col-5">
                    <label>HORSE NAME</label>
                    <p style="font-weight: bold">{{ horse.current_name }}</p>
                    <div class="block-details">
                      <div>
                        <label>COLOR
                          <span>{{ horse.color }}</span>
                        </label>
                      </div>
                      <div>
                        <label>GENDER <span>{{ horse.gender }}</span></label>
                      </div>
                      <div>
                        <label>BIRTH-DATE <span>{{ horse.date_of_birth | date }}</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-2" style="display: grid; text-align: end">
                    <label>FEDERATION</label>
                    <p *ngFor="let member of horse.memberships">{{ member.federation }}
                      <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                      <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                    </p>
                  </div>
                  <div class="col-1" style="display: grid; text-align: start">
                    <label>ID #</label>
                    <p *ngFor="let member of horse.memberships">{{ member.membership_id }}</p>
                  </div>
                  <div class="col-1" style="display: grid; text-align: end">
                     <span class="minus-button" (click)="entry.horses.splice(i,1)">
                      <nb-icon icon="minus-outline"></nb-icon>
                     </span>
                  </div>
                  <div class="col-2">
                    <div class="block-info ">
                      <p>USEF: [active]</p>
                      <p>date1 - date2</p>
                      <p>MICROCHIP PRESENT OWNER: SWIFT, T</p>
                    </div>
                  </div>
                </div>
                <div name="OWNER" *ngIf="horse.owners.length === 0" class="row">
                  <div class="col-1" [style]="Object.keys(entry.horses).length === 0 ? 'display: flex' : ''">
                      <span class="add-button" (click)="searchModal('person', 'OWNER', i)"
                            style="margin: 30px 10px 0 0;"><nb-icon
                        icon="plus-outline"></nb-icon></span>
                  </div>
                  <div class="col-5">
                    <p style="margin: 40px">PLEASE ADD OWNER</p>
                  </div>
                </div>
                <div name="OWNER" *ngFor="let owner of horse.owners; let j = index" class="row">
                  <div class="col-1" [style]="Object.keys(owner).length === 0 ? 'display: flex' : ''">
                <span class="add-button" (click)="searchModal('person', 'OWNER', i)"
                      [style]="horse.owners.length !== 0 ? 'background-color: #00a4fc' : ''"><nb-icon
                  [icon]="horse.owners.length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
                    <nb-icon *ngIf="horse.owners.length !== 0" icon="edit-2-outline"
                             (click)="editElement('owner',0, false)" class="edit-button"></nb-icon>
                  </div>
                  <div class="col-5">
                    <label>OWNER NAME</label>
                    <p style="font-weight: bold">{{ owner.name }}</p>
                    <div class="block-details">
                      <div>
                        <label>ADDRESS
                          <span>{{ owner?.postal_address?.locality }} {{ owner?.postal_address?.address_line1 }}</span></label>
                      </div>
                      <div>
                        <label>BIRTH DATE <span>{{ owner?.birth_date | date }}</span></label>
                      </div>
                      <div>
                        <label>EMAIL <span>{{ owner?.email }}</span></label>
                      </div>
                      <div>
                        <label>PHONE <span>{{ owner?.phone }}</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-2" style="display: grid; text-align: end" *ngIf="horse.owners.length !== 0">
                    <label>FEDERATION</label>
                    <p *ngFor="let member of owner.memberships">{{ member.federation }}
                      <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                      <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                    </p>
                  </div>
                  <div class="col-1" style="display: grid; text-align: start"
                       *ngIf="horse.owners.length !== 0">
                    <label>ID #</label>
                    <p *ngFor="let member of owner.memberships">{{ member.membership_id }}</p>
                  </div>
                  <div class="col-1" style="display: grid; text-align: end" *ngIf="horse.owners.length !== 0">
                <span class="minus-button" (click)="removeHorseOwner(i,j)"><nb-icon
                  icon="minus-outline"></nb-icon></span>
                  </div>
                  <div class="col-2" *ngIf="horse.owners.length !== 0">
                    <div class="block-info ">
                      <p>USEF: [active]</p>
                      <p>date1 - date2</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2" *ngIf="entry.horses.length !== 0">
                <p>OWNER SIGNATURES</p>
                <p>
                  <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  ENTRY AGREEMENT ELECTRONIC
                </p>
                <p>
                  <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  WAIVER / RELEASE ELECTRONIC
                </p>
              </div>
              <hr style="width:60%">
            </div>
          </div>
        </div>
      </nb-card>

      <div class="row" style="justify-content: space-around">
        <nb-card class="col-5" style="padding: 1px">
          <nb-card-body class="payee-payer">
            <div style="margin-right: 20px; display: grid;">
              <span class="add-button" (click)="searchModal('person', 'PAYER', 0)"
                    [style]="Object.keys(entry.payer).length !== 0 ? 'background-color: #00a4fc' : ''"><nb-icon
                [icon]="Object.keys(entry.payer).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
              <nb-icon *ngIf="Object.keys(entry.payer).length !== 0"
                       (click)="editElement('payer', 0, false)" icon="edit-2-outline" class="edit-button"></nb-icon>
            </div>
            <div style="margin: auto">
              <label><span class="data">PAYER</span> - Entity that pays for this entry</label>
              <p style="font-weight: bold" *ngIf="Object.keys(entry.payer).length !== 0">{{ entry.payer?.name }}</p>
              <p *ngIf="Object.keys(entry.payer).length === 0">PLEASE ADD PAYER</p>
            </div>
            <span class="minus-button" *ngIf="Object.keys(entry.payer).length !== 0" (click)="entry['payer'] = {}"><nb-icon
              icon="minus-outline"></nb-icon></span>
          </nb-card-body>
        </nb-card>

        <nb-card class="col-5" style="padding: 1px">
          <nb-card-body class="payee-payer">
            <div style="margin-right: 20px; display: grid;">
              <span class="add-button" (click)="searchModal('person', 'PAYEE',0)"
                    [style]="Object.keys(entry.payee).length !== 0 ? 'background-color: #00a4fc' : ''"><nb-icon
                [icon]="Object.keys(entry.payee).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
              <nb-icon *ngIf="Object.keys(entry.payee).length !== 0"
                       (click)="editElement('payee', 0, false)" icon="edit-2-outline" class="edit-button"></nb-icon>
            </div>
            <div style="margin: auto">
              <label><span class="data">PAYEE</span> - Entity that receives prize money for this entry</label>
              <p *ngIf="Object.keys(entry.payee).length !== 0" style="font-weight: bold">{{ entry.payee?.name }}</p>
              <p *ngIf="Object.keys(entry.payee).length === 0">PLEASE ADD PAYEE</p>
            </div>
            <span class="minus-button" *ngIf="Object.keys(entry.payee).length !== 0" (click)="entry['payee'] = {}"><nb-icon
              icon="minus-outline"></nb-icon></span>
          </nb-card-body>
        </nb-card>
      </div>

      <nb-card>
        <div style="display: flex">
          <h4>RIDERS</h4>
          <span class="add-button" style="margin-left: 20px;" (click)="searchModal('person', 'RIDERS',0)"><nb-icon
            icon="plus-outline"></nb-icon></span>
        </div>

        <div class="row">
          <div class="col-12" *ngIf="entry.riders.length === 0">
            <p style="margin: 40px">PLEASE ADD RIDER</p>
          </div>
          <div class="col-10" *ngIf="entry.riders.length !== 0">
            <div class="row" name="RIDER" *ngFor="let rider of entry.riders, let i = index">
              <div class="col-1">
                <nb-icon icon="edit-2-outline" (click)="editElement('riders', i, false)" class="edit-button"></nb-icon>
              </div>
              <div class="col-5">
                <label>NAME</label>
                <p style="font-weight: bold">{{ rider.name }}</p>
                <div class="block-details">
                  <div>
                    <label>ADDRESS
                      <span>{{ rider?.postal_address?.locality }} {{ rider?.postal_address?.address_line1 }}</span></label>
                  </div>
                  <div>
                    <label>BIRTH DATE <span>{{ rider?.birth_date | date }}</span></label>
                  </div>
                  <div>
                    <label>EMAIL <span>{{ rider?.email }}</span></label>
                  </div>
                  <div>
                    <label>PHONE <span>{{ rider?.phone }}</span></label>
                  </div>
                </div>
              </div>
              <div class="col-2" style="display: grid; text-align: end">
                <label>FEDERATION</label>
                <p *ngFor="let member of rider.memberships">{{ member.federation }}
                  <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                </p>
              </div>
              <div class="col-1" style="display: grid; text-align: start">
                <label>ID #</label>
                <p *ngFor="let member of rider.memberships">{{ member.membership_id }}</p>
              </div>
              <div class="col-1" style="display: grid; text-align: end">
                <span class="minus-button" (click)="entry.riders.splice(i,1)"><nb-icon
                  icon="minus-outline"></nb-icon></span>
              </div>
              <div class="col-2">
                <div class="block-info ">
                  <p>USEF: [active]</p>
                  <p>date1 - date2</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2" *ngIf="entry.riders.length !== 0">
            <label>RIDER SIGNATURES</label>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              ENTRY AGREEMENT ELECTRONIC
            </p>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              WAIVER / RELEASE ELECTRONIC
            </p>
          </div>
        </div>
      </nb-card>
      <nb-card>
        <div style="display: flex">
          <h4>TRAINER</h4>
        </div>

        <div class="row">
          <div class="col-10" *ngIf="Object.keys(entry.trainer).length === 0">
              <span class="add-button" (click)="searchModal('person', 'TRAINER',0)"
                    style="margin: 30px 10px 0 0;"
                    [style]="Object.keys(entry.trainer).length !== 0 ? 'background-color: #00a4fc' : ''">
                <nb-icon
                  [icon]="Object.keys(entry.trainer).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon></span>
            <p style="margin: 40px">PLEASE ADD TRAINER</p>
          </div>
          <div class="col-10" *ngIf="Object.keys(entry.trainer).length !== 0">
            <div class="row" name="trainer">
              <div class="col-1">
                <span class="add-button" (click)="searchModal('person', 'TRAINER',0)"
                      [style]="Object.keys(entry.trainer).length !== 0 ? 'background-color: #00a4fc' : ''">
                  <nb-icon
                    [icon]="Object.keys(entry.trainer).length === 0 ? 'plus-outline' : 'flip-2-outline'"></nb-icon>
                </span>
                <nb-icon icon="edit-2-outline" (click)="editElement('trainer', 0, false)" class="edit-button"></nb-icon>
              </div>
              <div class="col-5">
                <label>NAME</label>
                <p style="font-weight: bold">{{ entry.trainer.name }}</p>
                <div class="block-details">
                  <div>
                    <label>ADDRESS
                      <span>{{ entry.trainer?.postal_address?.locality }} {{ entry.trainer?.postal_address?.address_line1 }}</span></label>
                  </div>
                  <div>
                    <label>BIRTH DATE <span>{{ entry.trainer?.birth_date | date }}</span></label>
                  </div>
                  <div>
                    <label>EMAIL <span>{{ entry.trainer?.email }}</span></label>
                  </div>
                  <div>
                    <label>PHONE <span>{{ entry.trainer?.phone }}</span></label>
                  </div>
                </div>
              </div>
              <div class="col-2" style="display: grid; text-align: end">
                <label>FEDERATION</label>
                <p *ngFor="let member of entry.trainer.memberships">{{ member.federation }}
                  <nb-icon *ngIf="member.valid" icon="checkmark-square-2-outline" class="check-green"></nb-icon>
                  <nb-icon *ngIf="!member.valid" icon="close-square-outline" class="check-red"></nb-icon>
                </p>
              </div>
              <div class="col-1" style="display: grid; text-align: start">
                <label>ID #</label>
                <p *ngFor="let member of entry.trainer.memberships">{{ member.membership_id }}</p>
              </div>
              <div class="col-1" style="display: grid; text-align: end">
                <span class="minus-button" (click)="entry['trainer'] = {}"><nb-icon
                  icon="minus-outline"></nb-icon></span>
              </div>
              <div class="col-2">
                <div class="block-info ">
                  <p>USEF: [active]</p>
                  <p>date1 - date2</p>
                </div>
              </div>
            </div>

          </div>
          <div class="col-2" *ngIf="Object.keys(entry.trainer).length !== 0">
            <label>RIDER SIGNATURES</label>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              ENTRY AGREEMENT ELECTRONIC
            </p>
            <p>
              <nb-icon icon="checkmark-square-2-outline" class="check-green"></nb-icon>
              WAIVER / RELEASE ELECTRONIC
            </p>
          </div>
        </div>
      </nb-card>

    </nb-tab>

    <nb-tab tabTitle="COMPETITIONS" [disabled]="!edit">
      <ng-template #dialogCompetitionSection let-data let-ref="dialogRef">
        <nb-card size="large" style="width: 60svw; height: 40svh">
          <nb-card-header>ADD {{ competitionSectionType | uppercase }} TO
            <span>#{{ editEntryData.entry_number }} {{ editEntryData.name }} </span>
          </nb-card-header>
          <nb-card-body>
            <h4><span *ngIf="editEntryData.riders.length > 1">CHOOSE </span>RIDER</h4>
            <div class="row">
              <div class="col-4">
                <p *ngIf="editEntryData.riders.length === 1">{{ editEntryData.riders[0].name }}</p>
              </div>
              <div class="col-4">
                <input nbInput type="number" (keyup)="searchCompetitionsSections()"
                       [(ngModel)]="competitionSectionNumber"
                       placeholder="{{competitionSectionType | uppercase}} #">
              </div>
              <div class="col-4">
                <input #autoInput
                       nbInput
                       type="text"
                       (input)="searchCompetitionModal()"
                       [(ngModel)]="competitionSectionSearch"
                       placeholder="Search.."
                       [nbAutocomplete]="auto"/>

                <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
                  <nb-option *ngFor="let option of resultsCompetitionModal | async" [value]="option">
                    {{ option.name }}
                  </nb-option>

                </nb-autocomplete>
              </div>
            </div>
            <p class="error" *ngIf="competitionSectionSearchResult">The {{ competitionSectionType }}
              #{{ competitionSectionNumber }} does not exit</p>
            <button nbButton class="modal-competition-section" shape="semi-round" status="primary" type="submit"
                    (click)="createCompetitionSection()">
              <nb-icon icon="save-outline"></nb-icon>
              SAVE
            </button>
          </nb-card-body>
          <nb-card-footer style="justify-content: space-around;display: flex;">
            <button nbButton status="danger" (click)="ref.close()">CLOSE</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>

      <table>
        <tr>
          <th>COMP #</th>
          <th>RIDER</th>
          <th>FLAGS</th>
          <th>STATUS</th>
          <th>DATE</th>
          <th>LOCATION</th>
          <th>PLACING</th>
          <th>PRIZE</th>
        </tr>
        <tr *ngFor="let competition of competitions" class="competition-table" (click)="selectCompetition(competition)"
            [ngStyle]="{'background-color': competition.clicked ? '#1385FDCD' : ''}">
          <td>{{ competition.competition_number }}</td>
          <td>{{ competition.rider_name }}</td>
          <td>{{ competition.competitor_flags }}</td>
          <td>{{ competition.competitor_status }}</td>
          <td>{{ competition.competition_date | date }}</td>
          <td>{{ competition?.location }}</td>
          <td>{{ competition.placing }}</td>
          <td>{{ competition.prize_money }}</td>
        </tr>
      </table>
      <div class="row">
        <button nbButton shape="semi-round" status="primary" class="btn add-button"
                (click)="addCompetitionSectionModal('competition')">ADD COMPETITION
        </button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button"
                (click)="addCompetitionSectionModal('section')">ADD SECTION
        </button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button"
                (click)="changeRider()" [nbPopover]="(selectedCompetition !== null ) ? templateRef : ''">CHANGE RIDER
        </button>
        <ng-template #templateRef style="border: black 1px solid; border-radius: 5px">
          <nb-list *ngIf="entry.riders.length > 1">
            <nb-list-item *ngFor="let rider of getRidersForCompetition()" class="change-rider"
                          (click)="selectedRider(rider)">
              {{ rider.name }}
            </nb-list-item>
          </nb-list>
        </ng-template>
        <button nbButton shape="semi-round" status="primary" class="btn add-button"
                (click)="scratchCompetition()">SCRATCH
        </button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button"
                (click)="voidCompetition()">VOID
        </button>
        <button nbButton shape="semi-round" status="primary" class="btn add-button"
                (click)="applyLateFee()">APPLY LATE FEE
        </button>
      </div>
    </nb-tab>

    <nb-tab tabTitle="BILLING" [disabled]="!edit">
      <ng-template #dialogPayment let-data let-ref="dialogRef">
        <nb-card size="large" style="width: 90svw; height: 90svh">
          <nb-card-header>
            PAY BALANCE ON ACCOUNT {{ horseShow.name }}
          </nb-card-header>
          <nb-card-body>
            <div class="row">
              <div class="col-md-8">
                <div class="row" style="margin-bottom: 20px">
                  <div class="col-6" style="display: grid; height: fit-content">
                    <label>AMOUNT</label>
                    <div style="margin: 10px 0">
                    $<input nbInput type="number" name="add" [(ngModel)]="billing.amount">
                    </div>
                  </div>
                  <div class="col-6">
                    <label>COMMENT</label>
                    <textarea rows="5" nbInput fullWidth placeholder="DESCRIPTION" required></textarea>
                  </div>
                </div>
                <div>
                  <nb-accordion class="billing-payment">
                    <nb-accordion-item>
                      <nb-accordion-item-header (click)="billing.tab = 0">
                        CHEQUE
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="row">
                          <div class="col-6" style="display: grid;">
                            <label>INITIALS (FOR BALANCING)</label>
                            <input nbInput type="text" name="initials" [(ngModel)]="billing.cheque.initials">
                          </div>
                          <div class="col-6" style="display: grid;">
                            <label>CHEQUE</label>
                            <input nbInput type="text" name="cheque" [(ngModel)]="billing.cheque.cheque_number">
                          </div>
                          <div class="col-12" style="display: grid;">
                            <label>MEMO</label>
                            <input nbInput fullWidth type="text" name="memo" [(ngModel)]="billing.cheque.memo">
                          </div>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>

                    <nb-accordion-item>
                      <nb-accordion-item-header (click)="billing.tab = 1">
                        CARD ON FILE
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <table>
                          <tr>
                            <th>HS#</th>
                            <th>ENTRY#</th>
                            <th>HORSE</th>
                            <th>DATE</th>
                            <th>TRANS ID</th>
                            <th>AMMOUNT</th>
                            <th></th>
                          </tr>
                          <tr>
                            <th>22</th>
                            <th>234</th>
                            <th>HORSE DEMO</th>
                            <th>OCT 23, 2024</th>
                            <th>sdFDj4oeis324</th>
                            <th>$1234</th>
                            <th>
                              <nb-checkbox status="basic">
                              </nb-checkbox>
                            </th>
                          </tr>
                          <tr>
                            <th>22</th>
                            <th>234</th>
                            <th>HORSE DEMO2</th>
                            <th>OCT 23, 2024</th>
                            <th>sdFDj4oeis324</th>
                            <th>$1234</th>
                            <th>
                              <nb-checkbox status="basic" checked>
                              </nb-checkbox>
                            </th>
                          </tr>
                          <tr>
                            <th>22</th>
                            <th>333</th>
                            <th>HORSE DEMO3</th>
                            <th>OCT 23, 2024</th>
                            <th>sdFDj4oeis324</th>
                            <th>$1234</th>
                            <th>
                              <nb-checkbox status="basic">
                              </nb-checkbox>
                            </th>
                          </tr>
                        </table>
                      </nb-accordion-item-body>
                    </nb-accordion-item>

                    <nb-accordion-item>
                      <nb-accordion-item-header (click)="billing.tab = 2">
                        INPUT NEW CARD
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="row" style="border-bottom: 1px solid black; padding-bottom: 5px">
                          <div class="col-6" style="display: grid;">
                            <nb-select fullWidth placeholder="SELECT ACCOUNT OWNER">
                              <!--                              <nb-option *ngFor="let arena of _arenas" [value]="arena.arena_number">{{ arena.name }}</nb-option>-->
                            </nb-select>
                          </div>
                          <div class="col-6" style="display: grid;">
                            <button nbButton shape="semi-round" status="primary" class="btn add-button">
                              ADD NEW
                            </button>
                          </div>
                          <div class="col-8" style="display: grid;">
                            <label>CARDHOLDER NAME</label>
                            <input nbInput fullWidth type="text" name="name">
                          </div>
                          <div class="col-8" style="display: grid;">
                            <label>ADDRESS</label>
                            <input nbInput fullWidth type="text" name="address">
                          </div>
                          <div class="col-6" style="display: grid;">
                            <label>CITY</label>
                            <input nbInput fullWidth type="text" name="city">
                          </div>
                          <div class="col-6" style="display: grid;">
                            <label>STATE</label>
                            <nb-select fullWidth placeholder="SELECT STATE">
                              <!--                              <nb-option *ngFor="let arena of _arenas" [value]="arena.arena_number">{{ arena.name }}</nb-option>-->
                            </nb-select>
                          </div>
                          <div class="col-6" style="display: grid;">
                            <label>ZIP CODE</label>
                            <input nbInput fullWidth type="text" name="city">
                          </div>
                          <div class="col-6" style="display: grid;">
                            <label>COUNTRY</label>
                            <nb-select fullWidth placeholder="SELECT COUNTRY">
                              <!--                              <nb-option *ngFor="let arena of _arenas" [value]="arena.arena_number">{{ arena.name }}</nb-option>-->
                            </nb-select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6" style="display: grid;">
                            <label>CARD #</label>
                            <input nbInput fullWidth type="text" name="card">
                          </div>
                          <div class="col-4" style="display: grid;">
                            <label>CVV#</label>
                            <input nbInput fullWidth type="text" name="cvv">
                          </div>
                          <div class="col-4" style="display: grid;">
                            <label>EXP MONTH</label>
                            <input nbInput fullWidth type="text" name="month">
                          </div>
                          <div class="col-4" style="display: grid;">
                            <label>EXP YEAR</label>
                            <input nbInput fullWidth type="text" name="year">
                          </div>
                        </div>
                        <script src="https://js.braintreegateway.com/web/dropin/1.43.0/js/dropin.js"></script>
                        <div id="dropin-container"></div>
                        <button id="submit-button" class="button button--small button--green" (click)="openPayPal()">Purchase</button>
                      </nb-accordion-item-body>
                    </nb-accordion-item>

                    <nb-accordion-item>
                      <nb-accordion-item-header (click)="billing.tab = 3">
                        RECORD CARD PAYMENT PROCESSED OUTSIDE SMS
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="row">
                          <div class="col-8" style="display: grid;">
                            <label>CARDHOLDER NAME</label>
                            <input nbInput type="text" name="initials">
                          </div>
                          <div class="col-8" style="display: grid;">
                            <label>CARD#</label>
                            <input nbInput type="text" name="card">
                          </div>
                          <div class="col-6" style="display: grid;">
                            <label>TRANSACTION ID</label>
                            <input nbInput fullWidth type="text" name="transition">
                          </div>
                          <div class="col-6" style="display: grid;">
                            <label>AUTHORIZATION CODE</label>
                            <input nbInput fullWidth type="text" name="memo">
                          </div>
                          <div class="col-12" style="margin-top: 10px">
                            <nb-checkbox status="basic">CREATE BILLING ITEM FOR $30 TO ACCOUNT FOR CONVENIENCE FEE?
                            </nb-checkbox>
                          </div>
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>

                    <nb-accordion-item>
                      <nb-accordion-item-header (click)="billing.tab = 4">
                        CASH
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div class="col-6" style="display: grid;">
                          <label>INITIALS (FOR BALANCING)</label>
                          <input nbInput type="text" name="cash">
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
                  </nb-accordion>
                </div>
              </div>
              <div class="col-md-4">
                <label>POSTED DATE</label>
                <nb-calendar [(date)]="date" size="large"></nb-calendar>
                <button nbButton style="color: white;border-radius: 5px; background-color: #333a3e"  (click)="submitPayment()">SUBMIT</button>
              </div>
            </div>
          </nb-card-body>
          <nb-card-footer style="justify-content: space-around;display: flex;">
            <button nbButton status="danger" (click)="ref.close()">CLOSE</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>

      <table>
        <tr>
          <th></th>
          <th>CODE</th>
          <th>DESCRIPTION</th>
          <th>ADD</th>
          <th>QUANTITY</th>
          <th>PRICE</th>
          <th>TOTAL</th>
        </tr>
        <tr *ngFor="let billing of entryBilling?.billingItemSummaries; let i = index">
          <td>{{ billing.listing_order }}</td>
          <td>{{ billing.billing_code }}</td>
          <td>{{ billing.billing_item_name }}</td>
          <td><input nbInput type="number" style="width: 10svw" [(ngModel)]="billing.add"
                     (focusout)="billingAdd(billing)"
                     (keyup.enter)="billingAdd(billing)" name="add" placeholder=""></td>
          <td>{{ billing.quantity }}</td>
          <td>${{ billing.unit_price }}</td>
          <td style="text-align: end">${{ billing.amount }}</td>
        </tr>
      </table>
      <div class="row" *ngIf="entryBilling?.balance">
        <div class="col-md-2">
          <button nbButton shape="semi-round" style="margin-bottom: 20px" fullWidth
                  [nbPopover]="billingRef">Billing Menu
          </button>
          <ng-template #billingRef style="border: black 1px solid; border-radius: 5px">
            <nb-list data-columns="2" class="billing-menu-popover">
              <nb-list-item>Create Split Payment</nb-list-item>
              <nb-list-item>Issue Refund</nb-list-item>
              <nb-list-item>Move Credits</nb-list-item>
              <nb-list-item>Owner Billing Lookup</nb-list-item>
              <nb-list-item>Reissue Bridle Number (Change #)</nb-list-item>
              <nb-list-item>Reverify Federation Status</nb-list-item>
              <nb-list-item>Send Payment Link to Account</nb-list-item>
              <nb-list-item>Holder's User Account</nb-list-item>
              <nb-list-item>Split Shared Items</nb-list-item>
              <nb-list-item>Trainer Billing Lookup</nb-list-item>
              <nb-list-item>Validate and Repair Account</nb-list-item>
              <nb-list-item>View Categorized Billing Items</nb-list-item>
              <nb-list-item>View Entry Form</nb-list-item>
              <nb-list-item>View Invoice</nb-list-item>
              <nb-list-item>View Payments</nb-list-item>
              <nb-list-item>View Prize Cheques</nb-list-item>
              <nb-list-item>View Prize Money</nb-list-item>
              <nb-list-item>View Results</nb-list-item>
              <nb-list-item>View Store Orders</nb-list-item>
              <nb-list-item>View W9 Info</nb-list-item>
              <nb-list-item>View Waivers</nb-list-item>
            </nb-list>
          </ng-template>
          <button nbButton shape="semi-round" fullWidth>Next Page</button>
        </div>
        <div class="col-md-8">
          <table>
            <tr>
              <td>Prize Money Won:</td>
              <td class="billing-table" style="color: blue">${{ entryBilling.totalPrizeMoney }}</td>
              <td>Billing Items:</td>
              <td class="billing-table">${{ entryBilling.totalBillingItems }}</td>
            </tr>
            <tr>
              <td>Credits to Date:</td>
              <td class="billing-table" style="color: red">${{ entryBilling.totalCredits }}</td>
              <td>Entry Fees:</td>
              <td class="billing-table">${{ entryBilling.totalEntryFees }}</td>
            </tr>
            <tr>
              <td>Refunds to Date:</td>
              <td class="billing-table" style="color: red">${{ entryBilling.totalRefunds }}</td>
              <td>Taxes:</td>
              <td class="billing-table">${{ entryBilling.totalTaxItems }}</td>
            </tr>
            <tr style="border-bottom: 1px black solid;">
              <td>Payments to Date:</td>
              <td class="billing-table" style="color: red">${{ entryBilling.totalPayments }}</td>
              <td>Total:</td>
              <td class="billing-table">${{ entryBilling.grandTotal }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Balance:</td>
              <td class="billing-table">${{ entryBilling.balance }}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-2" style="text-align: center">
          <!--          <h4>Payment:</h4>-->
          <!--          <input nbInput type="number" name="payment" placeholder="">-->
          <button nbButton *ngIf="entryBilling.balance >= 0"
                  style="color: white;border-radius: 5px; background-color: #333a3e" (click)="billingPay(true)">PAY
            BALANCE
          </button>
          <button nbButton *ngIf="entryBilling.balance < 0" status="danger" (click)="billingPay(false)">ISSUE REFUND
          </button>

        </div>
      </div>
    </nb-tab>

    <nb-tab tabTitle="JOURNAL" [disabled]="!edit">
    </nb-tab>

    <nb-tab tabTitle="FOLIO" [disabled]="!edit">
      <ng-template #dialogFolio let-data let-ref="dialogRef">
        <nb-card size="large" style="width: 40svw;">
          <nb-card-header>
            <h4>Confirm Void?</h4>
          </nb-card-header>
          <nb-card-body>
            <h5>Are you sure you want to void this item?
              The user making this decision and their address will be recorded with this action.
            </h5>

            <span>Add a note to this void?</span>

            <textarea rows="5" nbInput fullWidth type="text" class="form-control" style="margin-top: 20px"
                      id="folioVoidNote" [ngModel]="editFolio.comment"
                      name="address_2" placeholder=""></textarea>
          </nb-card-body>
          <nb-card-footer style="justify-content: space-around;display: flex;">
            <button nbButton status="danger" (click)="ref.close(); folioVoid(false) ">CANCEL</button>
            <button nbButton status="success" (click)="ref.close(); folioVoid(true)">YES</button>
          </nb-card-footer>
        </nb-card>
      </ng-template>

      <table>
        <tr>
          <th>TYPE</th>
          <th>DESCRIPTION</th>
          <th>AMOUNT</th>
          <th>DATE</th>
          <th>USER</th>
          <th style="justify-content: space-around; display: flex;">VOIDED</th>
          <th>VOIDED DATE</th>
          <th>VOIDED BY</th>
        </tr>
        <tr *ngFor="let folio of folios; let i = index">
          <td>{{ folio.itemType }}</td>
          <td>{{ folio.description }}</td>
          <td>${{ folio.amount }}</td>
          <td>{{ folio.createdDate | date }}</td>
          <td>{{ folio.creatorUserName }}</td>
          <td style="justify-content: space-around; display: flex;">
            <nb-icon style="cursor: pointer" *ngIf="!folio.voided" icon="square-outline" (click)="toggleFolio(folio, i)"></nb-icon>
            <nb-icon *ngIf="folio.voided" icon="checkmark-square-2-outline"></nb-icon>
<!--            <nb-checkbox (checkedChange)="toggleFolio(folio, i)" ></nb-checkbox>-->
          </td>
          <td>{{ folio.voidedDate | date }}</td>
          <td>{{ folio.voiderUserName }}</td>
        </tr>
      </table>
    </nb-tab>
  </nb-tabset>
  <nb-card-footer style="width: 100%; justify-content: space-between; display: flex">
    <div>
      <button nbButton shape="semi-round" (click)="changeEntry(false)" style="width: 70px; margin-right: 30px">
        <nb-icon icon="arrowhead-left-outline"></nb-icon>
      </button>
      <button nbButton shape="semi-round" (click)="changeEntry(true)" style="width: 70px;">
        <nb-icon icon="arrowhead-right-outline"></nb-icon>
      </button>
    </div>
    <button nbButton shape="semi-round" status="warning" (click)="resetForm()">
      <nb-icon icon="trash-2-outline"></nb-icon>
      RESET
    </button>
    <button nbButton shape="semi-round" status="primary" type="submit" (click)="saveEntry()">
      <nb-icon icon="save-outline"></nb-icon>
      SAVE
    </button>
  </nb-card-footer>
</nb-card>
