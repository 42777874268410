import {Component} from '@angular/core';
import {HorseShowService} from '../../../providers/horse-show.service';
import {DatePipe, NgForOf} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';
import {
  NbButtonModule,
  NbCardModule,
  NbListModule,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
  NbTreeGridModule,
} from '@nebular/theme';

@Component({
  selector: 'ngx-app-venues',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    NbButtonModule,
    DatePipe,
    NbCardModule,
    NbListModule,
    NbTreeGridModule,
  ],
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
})
export class VenuesComponent {
  _venues: any[] = [];
  columnsHead = ['Venue Number', 'Name', 'City', 'State'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];

  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getVenues();
  }

  private getVenues() {
    this.showService.getShowVenues().then(venues => {
      this._venues = venues;
      venues.forEach((el: any) => {
        el.country = el.country_iso_alpha_3;
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addEditVenue(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['venue-add-edit', {uuid: uuid}]).then();
    } else {
      this.router.navigate(['venue-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
