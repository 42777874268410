<div>

  <nb-tabset>
    <nb-tab tabTitle="LOOKUP ADDRESS">
      <input placeholder="Search for location" autocorrect="off" autocapitalize="off" spellcheck="off"
             type="text" class="form-control search-location" #search>
      <div class="address-envelope">
        <div class="top-envelope">
          <div class="lines">
            <hr>
            <hr>
            <hr>
          </div>
          <div class="stamp"></div>
        </div>
        <!--        <div   class="loadingContainer1">-->
        <div>
          <div *ngIf="!searchedAddress" class="progress" role="progressbar" aria-valuenow="95" aria-valuemin="0"
               aria-valuemax="100" style="width: 50%; margin: 30px auto;">
            <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%;"></div>
          </div>
          <div *ngIf="searchedAddress" style="justify-content: center;display: grid">
            <p>{{addressForm.controls['address_line1']?.value}}</p>
            <p>{{addressForm.controls['address_line2']?.value}}</p>
            <p>{{addressForm.controls['locality']?.value}}  {{addressForm.controls['region']?.value}}  {{addressForm.controls['postal_code']?.value}}</p>
          </div>
        </div>
      </div>
    </nb-tab>
    <nb-tab tabTitle="ENTER ADDRESS">
      <form [formGroup]="addressForm" (ngSubmit)="onSubmitAddress()" class="needs-validation" novalidate>
        <div class="row">
          <div class="col-12 mb-3">
            <label>ADDRESS LINE 1</label>
            <input nbInput fullWidth type="text" formControlName="address_line1" id="address_line1"
                   name="address_line1"
                   placeholder="House # Street name" required>
          </div>

          <div class="col-12 mb-3">
            <label>ADDRESS LINE 2</label>
            <input nbInput fullWidth type="text" formControlName="address_line2" id="address_line2" name="address_line2"
                   placeholder="Mailbox # /Unit ..." required>
          </div>

          <div class="col-12 mb-3">
            <label>CITY</label>
            <input nbInput fullWidth type="text" formControlName="locality" id="locality" name="locality"
                   placeholder="City" required>
          </div>
          <div class="col-12 mb-3">
            <label>COUNTRY</label>
            <nb-select fullWidth placeholder="Country" (selectedChange)="setCountry()"
                       formControlName="country_code">
              <nb-option *ngFor="let country of countries" [value]="country.abbreviation">{{ country.name }}</nb-option>
            </nb-select>
          </div>
          <div class="col-6 mb-3">
            <label>STATE</label>
            <nb-select *ngIf="this.states.length > 0" fullWidth placeholder="State" (change)="setCountry()"
                       formControlName="region" id="region" name="region">
              <nb-option *ngFor="let state of states" [value]="state.abbreviation">{{ state.name }}</nb-option>
            </nb-select>
            <input nbInput fullWidth type="text" formControlName="region" name="region" *ngIf="this.states.length === 0"
                   placeholder="State">
          </div>
          <div class="col-6 mb-3">
            <label>ZIP CODE</label>
            <input nbInput fullWidth type="text" formControlName="postal_code" id="postal_code"
                   name="postal_code"
                   placeholder="Zip code" required>
          </div>
        </div>
      </form>
    </nb-tab>
  </nb-tabset>
  <button nbButton shape="semi-round" status="danger" (click)="addressAdded.emit('')"
          class="btn-save">CANCEL
  </button>
  <button nbButton shape="semi-round" status="primary" (click)="onSubmitAddress()" style="margin-right: 20px"
          class="btn-save">+ ADD
  </button>
</div>
