import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute} from '@angular/router';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'ngx-person-add-edit',
  templateUrl: './person-add-edit.component.html',
  styleUrls: ['./person-add-edit.component.scss'],
})
export class PersonAddEditComponent implements OnInit {
  edit: boolean = false;
  addAddress: boolean = false;
  selectedTab: string = '';

  organizationForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });
  personForm: FormGroup = new FormGroup({
    last_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    first_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    middle_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    gender: new FormControl('', [Validators.required, Validators.minLength(1)]),
    birth_date: new FormControl(new Date(), [Validators.required, Validators.minLength(1)]),
  });

  detailsForm: FormGroup = new FormGroup({
    membership_type: new FormControl(''),
    membership_id: new FormControl(''),
    email_type: new FormControl(''),
    email: new FormControl(''),
    email_fav: new FormControl(''),
    phone: new FormControl(''),
    mobile: new FormControl(false),
    mobile_fav: new FormControl(false),
  });
  editPerson: any;
  memberships: any;
  @Input() isModal: boolean  = false;
  @Input() personId: string = '';
  @Output() personEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService, private dialogService: NbDialogService) {
  }

  ngOnInit() {
    if (!this.isModal) {
      this.route.params.subscribe(params => {
        if (params['uuid']) {
          this.getPerson(params['uuid']);
        }
      });
    } else {
      this.getPerson(this.personId);
    }
  }

  onSubmit() {
    if (this.personForm.valid) {
      const body = {
        last_name: this.personForm.controls['last_name'].value,
        first_name: this.personForm.controls['first_name'].value,
        middle_name: this.personForm.controls['middle_name'].value,
        birth_date: this.personForm.controls['birth_date'].value.toISOString(),
        gender: this.personForm.controls['gender'].value,
      };

      if (!this.edit) {
        this.showService.postPerson(body).then(res => {
          this.editPerson = res;
          this.edit = true;
          if (this.isModal) {
            this.personEvent.emit(res);
          }
          this.toastService.success('', 'Person created');
        }, error => {
          this.toastService.danger('', 'Error creating person');
        });
      } else {
        // @ts-ignore
        body.id = this.editPerson.id;
        // @ts-ignore
        this.showService.putPerson(this.editPerson.id, body).then(res => {
          this.toastService.success('', 'Person updated');
          if (this.isModal) {
            this.personEvent.emit(res);
          }
        }, error => {
          this.toastService.danger('', 'Error updating person');
        });
      }
    } else {
      this.toastService.warning('', 'Please check the form');
    }
  }


  onSubmitOrganization() {
    if (this.organizationForm.valid) {
      const body = {
        name: this.organizationForm.controls['name'].value,
      };

      if (!this.edit) {
        this.showService.postOrganization(body).then(res => {
          this.editPerson = res;
          this.edit = true;
          this.toastService.success('', 'Organization created');
        }, error => {
          this.toastService.danger('', 'Error creating the organization');
        });
      } else {
        // @ts-ignore
        body.id = this.editPerson.id;
        // @ts-ignore
        this.showService.putOrganization(this.editPerson.id, body).then(res => {
          this.toastService.success('', 'Organization updated');
        }, error => {
          this.toastService.danger('', 'Error updating organization');
        });
      }
    } else {
      this.toastService.warning('', 'Please check the form');
    }
  }


  private getPerson(param: any) {
    this.showService.getPersonById(param).then(res => {
      this.edit = true;
      this.editPerson = res;
      this.personForm.controls['last_name'].setValue(res.last_name);
      this.personForm.controls['first_name'].setValue(res.first_name);
      this.personForm.controls['middle_name'].setValue(res.middle_name);
      this.personForm.controls['gender'].setValue(res.gender.replace(/\s/g, ''));
      this.personForm.controls['birth_date'].setValue(new Date(res.birth_date));

      if (res.telephone_numbers.length > 0) {
        res.telephone_numbers.forEach((el: any) => {
          if (el.contact_preference === 1) {
            this.detailsForm.controls['mobile_fav'].setValue(el.id);
          }
        });
      }

      if (res.email_addresses.length > 0) {
        res.email_addresses.forEach((el: any) => {
          if (el.contact_preference === 1) {
            this.detailsForm.controls['email_fav'].setValue(el.id);
          }
        });
      }
      // set the person form
    });
  }

  protected readonly CountryISO = CountryISO;
  protected readonly SearchCountryField = SearchCountryField;
  position: google.maps.LatLngLiteral | google.maps.LatLng;

  addNumber() {
    const phone = this.detailsForm.controls['phone'].value;
    const body = {
      country_code: Number(phone.dialCode.replace('+', '')),
      telephone_number: Number(phone.e164Number.replace(phone.dialCode, '')),
      mobile: this.detailsForm.controls['mobile'].value,
    };
    this.showService.postPersonNumber(body, this.editPerson.id, 1).then(res => {
      this.editPerson.telephone_numbers.push(res);
      this.detailsForm.controls['phone'].setValue('');
    });
  }

  onSubmitEmail() {
    const body = {
      email_address: this.detailsForm.controls['email'].value,
    };
    const priority = this.detailsForm.controls['email_type'].value;
    this.showService.postPersonEmail(body, this.editPerson.id, priority).then(res => {
      this.detailsForm.controls['email_type'].setValue('');
    });
  }

  onSubmitMembership() {
    const body = {
      federation: this.detailsForm.controls['membership_type'].value,
      membership_id: this.detailsForm.controls['membership_id'].value,
    };
    this.showService.postPersonMemberships(body, this.editPerson.id).then(res => {
      this.detailsForm.controls['membership_id'].setValue('');
      this.editPerson.memberships.push(res);
    });
  }

  handleAddress(event: any) {
    if (event !== '') {
      this.showService.postPersonAddress(event, this.editPerson.id).then(res => {
        this.addAddress = false;
        this.editPerson.postal_address = res;
      });
    } else {
      this.addAddress = false;
    }
  }

  selectedEmail(event: Event) {
  }

  selectedPhone(event: Event) {
    // set the selected to priority 1 rest to prio 2;

    // this.editPerson.telephone_numbers.forEach((el: any) => {
    //   console.log(el);
    //   this.showService.postPersonNumber(el, this.editPerson.id,
    //     el.id === this.detailsForm.controls['mobile_fav'].value ? 1 : 2).then();
    // });
  }

  selectTab(event: any) {
    this.selectedTab = event.tabTitle;
  }

  resetForm() {
    this.edit = false;
    this.editPerson = {};
    this.personForm.reset();
    this.organizationForm.reset();
  }
}
