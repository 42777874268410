import {Component} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgForOf} from '@angular/common';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {NbButtonModule, NbCardModule, NbInputModule, NbToastrService} from '@nebular/theme';

@Component({
  selector: 'ngx-app-billing-item-category',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
  ],
  templateUrl: './billing-item-category.component.html',
  styleUrls: ['./billing-item-category.component.scss'],
})
export class BillingItemCategoryComponent {
  categoryForm: FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    listing_order: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });
  // @ts-ignore
  private horseShow = JSON.parse(localStorage.getItem('sms_default_horse_show'));

  constructor(private showService: HorseShowService, private toastService: NbToastrService) {
  }

  onSubmit() {
    if (this.categoryForm.valid) {
      //   'horse_show_id': 'string',
      //   'horse_show_number': 0,
      //   'id': 'string',
      //   'code': 'string',
      //   'name': 'string',
      //   'listing_order': 0

      const body = {
        code: this.categoryForm.controls['code'].value,
        name: this.categoryForm.controls['name'].value,
        listing_order: this.categoryForm.controls['listing_order'].value,
        horse_show_id: this.horseShow.id,
        horse_show_number: this.horseShow.number,
      };

      this.showService.postBillItemCategory(body).then((res: any) => {
        this.categoryForm.reset();
        this.toastService.success('', 'Billing item category created');
      }, error => {
        this.toastService.danger('', 'Error creating category');
      });
    }
  }
}
