import {Component} from '@angular/core';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';
import {NbToastrService, NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';

@Component({
  selector: 'ngx-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent {
  columnsHead = ['First name', 'Last name', 'Gender', 'Birth Date'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  searchPerson: string = '';
  searching = false;
  private data = [];
  loading: boolean = false;

  constructor(private showService: HorseShowService, private router: Router, private toastService: NbToastrService,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getPeoples();
  }

  private getPeoples() {
    this.showService.getPeople().then(people => {
      this.loading = true;
      people.forEach((el: any) => {
        el.birth_date = new Date(el.birth_date).toDateString();
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.loading = false;
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addEditPerson(id: string) {
    if (id !== '') {
      this.router.navigate(['person-add-edit', {uuid: id}]).then();
    } else {
      this.router.navigate(['person-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  search() {
    if (this.searchPerson === '') {
      this.getPeoples();
      return;
    }
    if (!this.searching) {
      this.loading = true;
      setTimeout(() => {
        this.showService.getSearchPeople(this.searchPerson).then(people => {
          if (people.length === 0) {
            this.toastService.warning('', 'No search results');
            this.loading = false;
            this.searching = false;
          } else {
            this.data = [];
            people.forEach((el: any) => {
              el.birth_date = new Date(el.birth_date).toDateString();
              Object.keys(el).forEach(key => {
                if (typeof el[key] === 'boolean') {
                  el[key] = el[key].toString();
                }
              });
              this.data.push({data: el});
            });
            this.searching = false;
            this.loading = false;
            this.dataSource = this.dataSourceBuilder.create(this.data);
          }
        }, error => {
          this.toastService.warning('', 'No search results');
          this.loading = false;            this.searching = false;

        })
        ;
      }, 1500);
    }
    this.searching = true;
  }
}
