import {Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {NgForOf, NgIf} from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute} from '@angular/router';
// @ts-ignore
import * as countryData from '../../../../../assets/data/countries-states.json';
import {Venues} from '../../../../models/venues';
import {
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule, NbDialogService,
  NbInputModule,
  NbOptionModule,
  NbSelectModule, NbSpinnerModule, NbToastrService, NbToggleModule,
} from '@nebular/theme';
import {Position} from '../../../../models/position';

@Component({
  selector: 'ngx-app-venue-add-edit',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    NgIf,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
    NbInputModule,
    NbOptionModule,
    NbSelectModule,
    NbToggleModule,
    NbSpinnerModule,
  ],
  templateUrl: './venue-add-edit.component.html',
  styleUrls: ['./venue-add-edit.component.scss'],
})
export class VenueAddEditComponent implements OnInit {
  @ViewChild('dialogVenue', {static: true})
  dialogVenue: ElementRef;

  @ViewChild('search', {static: true})
  searchElementRef: ElementRef;
  @Output()
  positionChanged: EventEmitter<Position> = new EventEmitter<Position>();
  @Output() addressAdded: EventEmitter<any> = new EventEmitter<any>();

  // @ts-ignore
  countries = countryData['default'];
  venueForm: FormGroup = new FormGroup({
    venue_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    description: new FormControl(''),
    fei_name: new FormControl(''),
    country: new FormControl('USA'),
    address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postal_code: new FormControl(''),
    website: new FormControl(''),
    phone: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    utc_offset: new FormControl(''),
  });
  states: any = [];
  editVenue: any = {};
  edit: boolean = false;
  user: any = {};
  timeStamps: any = [];
  getVenueAPI: boolean = false;
  @Input() isModal!: boolean;
  @Output() venueEvent = new EventEmitter<unknown>();


  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private dialogService: NbDialogService, private ngZone: NgZone,
              private toastService: NbToastrService) {

    this.setCountry();
  }

  ngOnInit() {
    if (!this.isModal) {
      this.route.params.subscribe(params => {
        if (params['uuid']) {
          this.getVenue(params['uuid']);
        }
      });
    }
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
    );

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.positionChanged.emit(new Position(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ));

        this.venueForm.controls['latitude']
          .setValue(place.geometry.viewport[Object.keys(place.geometry.viewport)[0]].lo);
        this.venueForm.controls['longitude']
          .setValue(place.geometry.viewport[Object.keys(place.geometry.viewport)[1]].lo);
        this.venueForm.controls['address'].setValue(place.formatted_address);
        place.address_components.forEach((el: any) => {
          if (el.types.includes('locality')) {
            this.venueForm.controls['city'].setValue(el.long_name);
          } else if (el.types.includes('administrative_area_level_2') && this.venueForm.controls['city'].value === '') {
            this.venueForm.controls['city'].setValue(el.long_name);
          } else if (el.types.includes('administrative_area_level_1')) {
            this.venueForm.controls['state'].setValue(el.short_name);
          } else if (el.types.includes('postal_code')) {
            this.venueForm.controls['postal_code'].setValue(el.long_name);
          }

          if (this.venueForm.controls['city'].value === '') {
            this.venueForm.controls['city'].setValue(el.long_name);
          }
        });
      });
    });
  }

  onSubmit() {
    const body: any = {
      venue_number: this.venueForm.controls['venue_number'].value,
      name: this.venueForm.controls['name'].value,
      description: this.venueForm.controls['description'].value,
      fei_name: this.venueForm.controls['fei_name'].value,
      country_iso_alpha_3: this.venueForm.controls['country'].value,
      latitude: this.venueForm.controls['latitude'].value,
      longitude: this.venueForm.controls['longitude'].value,
      address: this.venueForm.controls['address'].value,
      city: this.venueForm.controls['city'].value,
      state: this.venueForm.controls['state'].value,
      postal_code: this.venueForm.controls['postal_code'].value,
      website: this.venueForm.controls['website'].value,
      phone: this.venueForm.controls['phone'].value,
      utc_offset: this.venueForm.controls['utc_offset'].value,
    };

    if (this.venueForm.valid) {
      if (!this.edit) {
        this.showService.postVenue(body).then(res => {
          this.venueForm.reset();
          this.toastService.success('', 'Venue created');
          if (this.isModal) {
            this.venueEvent.emit(res);
          }
        }, error => {
          this.toastService.danger('', 'Error creating venue');
        });
      } else {
        body.id = this.editVenue.id;
        // @ts-ignore
        this.showService.putVenue(this.editVenue.id, body).then(res => {
          this.toastService.success('', 'Venue updated');
        }, error => {
          this.toastService.danger('', 'Error updating venue');
        });
      }
    } else {
      this.toastService.warning('Please check the form!', 'Invalid form');
    }
  }

  getLocation() {
    if (!navigator.geolocation) {
    } else {
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.venueForm.controls['latitude'].setValue(position.coords.latitude);
        this.venueForm.controls['longitude'].setValue(position.coords.longitude);
      });
    }
  }

  setCountry() {
    this.states = [];
    this.venueForm.controls['state'].setValue('');
    const countrySelected = this.venueForm.controls['country'].value;
    this.countries.forEach((country: any) => {
      if (country.abbreviation === countrySelected) {
        this.states = country.states;
        this.timeStamps = country.time_stamps;
      }
    });
  }

  getVenue(param: any) {
    this.showService.getVenue(param).then((editVenue: Venues) => {
        this.setVenue(editVenue);
      },
      error => {
      },
    )
    ;
  }

  checkVenue() {
    if (!this.getVenueAPI && this.venueForm.controls['venue_number'].value !== null) {
      this.getVenueAPI = true;
      setTimeout(() => {
        this.showService.getVenueByNumber(this.venueForm.controls['venue_number'].value).then((editVenue: Venues) => {
          this.editVenue = editVenue;
          // @ts-ignore
          this.dialogService.open(this.dialogVenue, {closeOnBackdropClick: false});
        });
        this.getVenueAPI = false;
      }, 2000);
    }
  }

  setVenue(venue: any) {
    this.edit = true;
    this.editVenue = venue;
    for (const key in venue) {
      if (this.venueForm.get(key)) {
        this.venueForm.controls[key].setValue(venue[key]);
      }
    }
  }

  editExistingVenue(edit: boolean) {
    if (edit) {
      this.venueForm.reset();
      this.edit = true;
      this.setVenue(this.editVenue);
    } else {
      this.venueForm.controls['venue_number'].setValue('');
    }
  }

  resetForm() {
    this.edit = false;
    this.editVenue = {};
    this.venueForm.reset();
  }
}
