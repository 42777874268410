import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService, NbToastrService,
} from '@nebular/theme';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SessionService} from '../../../providers/session.service';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  horseShowOrganizers = [];
  horseShows = [];
  private destroy$: Subject<void> = new Subject<void>();
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{title: 'Log out'}];
  horseShow: any = {};
  horseShowOrganizer: any = {};
  private selectedItem: string;
  @ViewChild('dialogHS', {static: true})
  dialogHS: ElementRef;
  private organizerSelected: any;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService, private router: Router,
              private themeService: NbThemeService, private dialogService: NbDialogService,
              private sessionService: SessionService, private toastService: NbToastrService,
              private horseShowService: HorseShowService,
              private nbMenuService: NbMenuService,
              private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.horseShow = JSON.parse(localStorage.getItem('sms_default_horse_show'));
    this.horseShowOrganizer = JSON.parse(localStorage.getItem('sms_default_horse_show_organizer'));
    this.currentTheme = this.themeService.currentTheme;
    this.user = JSON.parse(localStorage.getItem('sms_cloud_app_client_access_token'));

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'context-menu'),
        map(({item: {title}}) => title),
      )
      .subscribe(title => {
        if (title === 'Log out') {
          localStorage.clear();
          location.reload();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changeHS() {
    this.horseShowService.getHorseShowsOrganizers().then(res => {
      this.horseShowOrganizers = res;
      // @ts-ignore
      this.dialogService.open(this.dialogHS);
    });
  }

  getHorseSows(organizer: any) {
    this.organizerSelected = organizer;
    this.horseShowService.getHorseShows(organizer.id).then(res => {
      if (res.length === 0) {
        this.router.navigate(['horse-show-add-edit']).then(() => {
          this.sessionService.setHorse_show_organizer(this.organizerSelected);
          this.sessionService.setHorse_show(null);
          location.reload();
        });
      }
      this.horseShows = res;
    });
  }

  setHorseShow(show: any) {
    this.sessionService.setHorse_show_organizer(this.organizerSelected);
    this.sessionService.setHorse_show(show);
    location.reload();
  }
}
