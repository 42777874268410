import {Injectable} from '@angular/core';
import {ModalLoginComponent} from '../pages/modal-login/modal-login.component';
import {User} from '../models/user';

@Injectable({
    providedIn: 'root',
})

export class ModalLoginService {

    constructor() {
    }

    async getSession(error_message_if_any: String): Promise<User> {

        // const modal_login = await this.modal.open(ModalLoginComponent, {size: 'lg'});

        return new Promise((resolve, reject) => {

            // modal_login.componentInstance.user_logged_in$.subscribe((user: User) => {
            //
            //     modal_login.close();
            //     modal_login.dismiss();
            //     console.log(user);
            //
            //     if (user.hasOwnProperty('id') && user.enabled) {
            //         console.log("User is enabled, resolving");
            //         resolve(user as User);
            //     } else {
            //         console.log("User is not enabled, rejecting");
            //         reject(user);
            //     }
            // }, (error: any) => reject(error as any));


        });


    }


}
