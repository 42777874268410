<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} PRIZE MONEY GROUP</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="prizeForm" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>PRIZE GROUP #</label>
          <input nbInput fullWidth type="number" formControlName="prize_group_number"
                 placeholder="PRIZE GROUP #" required>
        </div>
        <div class="col-8 mb-3">
          <label>NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" placeholder="NAME">
        </div>
      </div>
    </form>

    <span>PLACINGS</span>
    <div class="row mb-3">
      <div class="col-lg-2 col-md-4 col-sm-6 mb-3" *ngFor="let place of places ; let i = index">
        <label>{{i+1}}</label>
        <input nbInput fullWidth type="number" [placeholder]="i+1"
               [(ngModel)]="place.price" >
      </div>
    <div class="col-12" style="justify-content: flex-end; display: inline-grid">
      <button nbButton shape="semi-round" status="primary" type="submit" style="width: 200px"
              class="btn btn-primary" (click)="addPlaces()">Add more places
      </button>
    <span style="text-align: end;padding-top: 5px">Total prize money: $ {{ getTotalPrize() }}</span>
    </div>


    <div class="col-12" style="display: flex; justify-content: space-between">
      <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>
      <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
              class="btn-save">{{ edit ? 'Update' : 'Save' }}
      </button>
    </div>
    </div>

  </nb-card-body>
</nb-card>
