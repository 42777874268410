<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" routerLink="" (click)="navigateHome()">SHOW MANAGEMENT SYSTEM</a>
  </div>
<!--  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">-->
<!--    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>-->
<!--  </nb-select>-->
</div>
<ng-template #dialogHS let-data let-ref="dialogRef">
  <nb-card style="border-radius: 5px" accent="info" size="medium">
    <nb-card-header>
      <nb-icon icon="arrow-back-outline" *ngIf="horseShows.length > 0" (click)="horseShows = []"></nb-icon>
      PLEASE SELECT A HORSE SHOW {{horseShows.length === 0 ? 'ORGANIZER' : ''}}</nb-card-header>
    <nb-card-body>
      <nb-list *ngIf="horseShows.length === 0">
        <nb-list-item *ngFor="let organizer of horseShowOrganizers" (click)="getHorseSows(organizer)" style="cursor: pointer">
          {{ organizer.name }}
        </nb-list-item>
      </nb-list>
      <nb-list *ngIf="horseShows.length > 0">
        <nb-list-item *ngFor="let show of horseShows" (click)="setHorseShow(show)" style="cursor: pointer">
          {{ show.name }}
        </nb-list-item>
      </nb-list>
    </nb-card-body>
  </nb-card>
</ng-template>

<div class="header-container">
  <p style="margin: auto; font-weight: bold">{{horseShowOrganizer?.name}} \ {{horseShow?.name}}</p>
  <nb-icon icon="flip-2-outline" style="margin: 10px; cursor: pointer" (click)="changeHS()"></nb-icon>


  <nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="context-menu"
               [name]="user?.username">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
