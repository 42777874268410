<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} PERSON / ORGANIZATION</h2>
  </nb-card-header>
  <nb-card-body>
    <nb-tabset (changeTab)="selectTab($event)">
      <nb-tab tabTitle="PERSON">
        <form [formGroup]="personForm" class="needs-validation" novalidate>
          <div class="row">
            <div class="col-4 mb-3">
              <label>LAST NAME</label>
              <input nbInput fullWidth type="text" formControlName="last_name" id="last_name" name="last_name"
                     placeholder="Last name" required>
            </div>
            <div class="col-4 mb-3">
              <label>FIRST NAME</label>
              <input nbInput fullWidth type="text" formControlName="first_name" id="first_name" name="first_name"
                     placeholder="First name" required>
            </div>
            <div class="col-4 mb-3">
              <label>MIDDLE NAME</label>
              <input nbInput fullWidth type="text" formControlName="middle_name" id="middle_name" name="middle_name"
                     placeholder="Middle name" required>
            </div>

            <div class="col-3 form-floating mb-3">
              <label>GENDER</label>
              <nb-select fullWidth placeholder="Select gender" formControlName="gender">
                <nb-option [value]="'Male'">MALE</nb-option>
                <nb-option [value]="'Female'">FEMALE</nb-option>
                <nb-option [value]="'Non-binary'">NON BINARY</nb-option>
              </nb-select>
            </div>
            <div class="col-3 mb-3">
              <label>DATE OF BIRTH</label>
              <input nbInput fullWidth placeholder="MM-DD-YYYY" [nbDatepicker]="birth_date"
                     formControlName="birth_date">
              <nb-datepicker #birth_date></nb-datepicker>
            </div>

            <div class="col-6 mb-3"></div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>
            <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
                    class="btn-save">{{ edit ? 'Update' : 'Save' }}
            </button>
          </div>
        </form>
      </nb-tab>
      <nb-tab tabTitle="ORGANIZATION">
        <form [formGroup]="organizationForm" class="needs-validation" novalidate>
          <div class="row">
            <div class="col-8 mb-3">
              <label>NAME</label>
              <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                     placeholder="Organization name" required>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>
            <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmitOrganization()"
                    class="btn-save">{{ edit ? 'Update' : 'Save' }}
            </button>
          </div>
        </form>
      </nb-tab>
    </nb-tabset>
    <br><br><br><br><br>
    <form [formGroup]="detailsForm" class="needs-validation" novalidate *ngIf="edit">
      <div class="row" style="justify-content: space-between">
        <nb-card class="col-6 mb-3 card">
          <nb-card-header class="header">
            <label>ADD MEMBERSHIP</label>
            <div style="display: flow-root">
              <nb-select placeholder="TYPE" style="margin-right: 5px"
                         formControlName="membership_type">
                <nb-option [value]="'USEF'">USEF</nb-option>
                <nb-option [value]="'EC'">EC</nb-option>
                <nb-option [value]="'FEI'">FEI</nb-option>
              </nb-select>

              <input nbInput type="text" formControlName="membership_id" id="membership_id"
                     name="membership_id"
                     placeholder="Membership ID" required>
              <button nbButton shape="semi-round" status="primary" style="float: right"
                      (click)="onSubmitMembership()">+ ADD
              </button>
            </div>

          </nb-card-header>
          <nb-card-body>
            <p *ngIf="editPerson?.memberships.length === 0">No membership added</p>

            <nb-list *ngIf="editPerson?.memberships.length > 0">
              <nb-list-item *ngFor="let membership of editPerson?.memberships">
                <nb-icon icon="credit-card-outline"></nb-icon>
                {{ membership?.federation + ' ' + membership?.membership_id }}
              </nb-list-item>
            </nb-list>
          </nb-card-body>
        </nb-card>

        <nb-card class="col-6 mb-3 card">
          <nb-card-header class="header">
            <div style="display: flow-root">
              <label>ADDRESS</label>
              <button nbButton shape="semi-round" status="primary" style="float: right"
                      (click)="addAddress = !addAddress">+ ADD ADDRESS
              </button>
            </div>
          </nb-card-header>
          <nb-card-body>
            <div *ngIf="!addAddress && editPerson?.postal_address === null">
              <p>No personal address added</p>
            </div>
            <div class="row address-display" *ngIf="!addAddress && editPerson?.postal_address !== null">
              <div class="col-12">
                <label>STREET ADDRESS</label>
                <p>{{ editPerson?.postal_address.address_line1 }}</p>
              </div>
              <div class="col-6">
                <label>CITY</label>
                <p>{{ editPerson?.postal_address.locality }}</p>
              </div>
              <div class="col-6">
                <label>STATE</label>
                <p>{{ editPerson?.postal_address.region }}</p>
              </div>
              <div class="col-6">
                <label>ZIP CODE</label>
                <p>{{ editPerson?.postal_address.postal_code }}</p>
              </div>
              <div class="col-6">
                <label>COUNTRY</label>
                <p>{{ editPerson?.postal_address.country_code }}</p>
              </div>
            </div>
            <div *ngIf="addAddress">
              <ngx-address-form *ngIf="addAddress" (addressAdded)="handleAddress($event)"></ngx-address-form>
            </div>
          </nb-card-body>
        </nb-card>

        <nb-card class="col-6 mb-3 card">
          <nb-card-header class="header">
            <label>EMAIL</label>
            <br>
            <div style="display: flow-root">
              <nb-select placeholder="TYPE" style="margin-right: 5px"
                         formControlName="email_type">
                <nb-option [value]="'1'">PERSONAL</nb-option>
                <nb-option [value]="'2'">WORK</nb-option>
              </nb-select>
              <input nbInput type="text" style="min-width: auto" formControlName="email" id="email"
                     name="email" placeholder="EMAIL ADDRESS" required>
              <button nbButton shape="semi-round" status="primary" style="float: right"
                      (click)="onSubmitEmail()">+ ADD
              </button>
            </div>
          </nb-card-header>
          <nb-card-body>
            <p *ngIf="editPerson?.email_addresses.length === 0">
              No personal address added
            </p>
            <nb-radio-group *ngIf="editPerson?.email_addresses.length > 0" formControlName="email_fav" name="email_fav"
                            (valueChange)="selectedEmail($event)">
              <nb-radio *ngFor="let email of editPerson?.email_addresses" [value]="email.id">
                <nb-icon icon="email-outline"></nb-icon>
                {{ email.email_address }}
              </nb-radio>
            </nb-radio-group>
          </nb-card-body>
        </nb-card>

        <nb-card class="col-6 mb-3 card">
          <nb-card-header class="header">
            <label>PHONE</label>
            <div style="display: flow-root">
              <nb-checkbox status="basic" formControlName="mobile" class="col-2">Mobile</nb-checkbox>
              <ngx-intl-tel-input
                class="phone-input"
                [cssClass]="'phone-input'"
                [preferredCountries]="[CountryISO.UnitedStates, CountryISO.Canada]"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.UnitedStates"
                [maxLength]="15"
                [phoneValidation]="true"
                name="phone"
                formControlName="phone"
              ></ngx-intl-tel-input>
              <button nbButton shape="semi-round" status="primary" (click)="addNumber()"
                      style="float: right" class="col-2">+ Add
              </button>
            </div>
          </nb-card-header>
          <nb-card-body>
            <p *ngIf="editPerson?.telephone_numbers.length === 0">
              No phone number added
            </p>
            <nb-radio-group *ngIf="editPerson?.telephone_numbers.length > 0" formControlName="mobile_fav"
                            name="phone_fav"
                            (valueChange)="selectedPhone($event)">
              <nb-radio [value]="phone.id" *ngFor="let phone of editPerson?.telephone_numbers">
                <nb-icon *ngIf="!phone.mobile" icon="phone-outline"></nb-icon>
                <nb-icon *ngIf="phone.mobile" icon="smartphone-outline"></nb-icon>
                {{ '+' + phone.country_code + phone.telephone_number }}
              </nb-radio>
            </nb-radio-group>
          </nb-card-body>
        </nb-card>
      </div>
    </form>
  </nb-card-body>
</nb-card>
