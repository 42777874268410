<div>
  <input placeholder="Search for location" autocorrect="off" autocapitalize="off" spellcheck="off"
         type="text" class="form-control search-location" #search>
  <br>
  <br>
  <p style="text-align: center; width: 100%;">OR</p>
  <br>
  <form [formGroup]="addressForm" (ngSubmit)="onSubmitAddress()" class="needs-validation" novalidate>
    <div class="row">
      <div class="col-4 mb-3">
        <label>STREET NUMBER</label>
        <input nbInput fullWidth type="text" formControlName="street_number" id="street_number"
               name="street_number"
               placeholder="Street number" required>
      </div>
      <div class="col-8 mb-3">
        <label>STREET NAME</label>
        <input nbInput fullWidth type="text" formControlName="address_line1" id="address_line1"
               name="address_line1"
               placeholder="Street name" required>
      </div>

      <div class="col-12 mb-3">
        <label>CITY</label>
        <input nbInput fullWidth type="text" formControlName="locality" id="locality" name="locality"
               placeholder="City" required>
      </div>

      <div class="col-6 mb-3">
        <label>STATE</label>
        <input nbInput fullWidth type="text" formControlName="region" id="region" name="region"
               placeholder="State" required>
      </div>
      <div class="col-6 mb-3">
        <label>ZIP CODE</label>
        <input nbInput fullWidth type="text" formControlName="postal_code" id="postal_code"
               name="postal_code"
               placeholder="Zip code" required>
      </div>
      <div class="col-12 mb-3">
        <label>COUNTRY</label>
        <input nbInput fullWidth type="text" formControlName="country_code" id="country_code"
               name="country_code"
               placeholder="Country" required>
      </div>

    </div>

    <button nbButton shape="semi-round" status="danger" (click)="addressAdded.emit('')"
            class="btn-save">CANCEL
    </button>
    <button nbButton shape="semi-round" status="primary" type="submit" style="margin-right: 20px"
            class="btn-save">+ ADD
    </button>
  </form>
</div>
