import {Component} from '@angular/core';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';
import {NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';

@Component({
  selector: 'ngx-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
})
export class EntryComponent {
  columnsHead = ['Entry Type Number', 'Name', 'Closing Date'];
  allColumns = [...this.columnsHead, 'entry_available', 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];

  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    if (localStorage.getItem('sms_default_horse_show_organizer')) {
      this.getEntries();
    }
  }

  private getEntries() {
    this.showService.getEntries().then(sections => {
      sections.forEach((el: any) => {
        el.closing_date = new Date(el.closing_date).toDateString();
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addEditEntry(id: string) {
    if (id !== '') {
      this.router.navigate(['entry-type-add-edit', {uuid: id}]).then();
    } else {
      this.router.navigate(['entry-type-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
