<nb-card [accent]="!edit ? 'info' : 'warning'" [nbSpinner]="getEntryAPI" nbSpinnerStatus="primary">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} ENTRY</h2>
  </nb-card-header>
  <nb-card-body>

    <ng-template #dialogEntry let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>ENTRY ALREADY EXISTS</nb-card-header>
        <nb-card-body>DO YOU WANT TO EDIT THE EXISTING ENTRY
          #{{ this.entryForm.controls['entry_type_number'].value }}?
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close(); editExistingEntry(false)">CLOSE</button>
          <button nbButton status="primary" (click)="ref.close(); editExistingEntry(true)">EDIT</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <form [formGroup]="entryForm" class="needs-validation" novalidate>
      <div class="row">
        <div class="col-2 mb-3">
          <label># NUMBER</label>
          <input nbInput fullWidth type="number" formControlName="entry_type_number" id="number" name="number"
                 placeholder="Number" (keyup)="checkEntry()" required>
        </div>
        <div class="col-4 mb-3">
          <label>NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="Name" required>
        </div>
        <div class="col-4 mb-3">
          <label>DISCIPLINE</label>
          <input nbInput fullWidth type="text" formControlName="discipline" id="discipline" name="discipline"
                 placeholder="Discipline" >
        </div>
        <div class="col-2 form-floating mb-3">
          <label># HORSES</label>
          <input nbInput fullWidth type="text" formControlName="number_of_horses" id="number_of_horses"
                 name="number_of_horses"
                 placeholder="Number of horses" >
        </div>


        <div class="col-7 mb-3">
          <label>DESCRIPTION</label>
          <input nbInput fullWidth type="text" formControlName="description" id="description" name="description"
                 placeholder="Description" >
        </div>
        <div class="col-5 mb-3">
          <label>CLOSING DATE</label>
          <input nbInput fullWidth placeholder="Closing date" [nbDatepicker]="closing_date"
                 formControlName="closing_date">
          <nb-datepicker #closing_date></nb-datepicker>
        </div>


        <div class="col-1 check-col mb-3">
          <label>ENTRY AVAILABLE</label>
          <nb-checkbox status="basic" formControlName="entry_available"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>PAYEE </label>
          <nb-checkbox status="basic" formControlName="payee_"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>PREVENT SKIP</label>
          <nb-checkbox status="basic" formControlName="no_skip_payee"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>COMPETITION ENTRY ALLOWED</label>
          <nb-checkbox status="basic" formControlName="allow_competition_entry"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>SELECTION ENTRY ALLOWED</label>
          <nb-checkbox status="basic" formControlName="hide_section_entry"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>SKIP ADD COMP</label>
          <nb-checkbox status="basic" formControlName="skip_competition_add"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>COMPETITION ENTRY </label>
          <nb-checkbox status="basic" formControlName="competition_entry_"></nb-checkbox>
        </div>
        <div class="col-5 mb-3">
          <label>DEFAULT COMPETITION #</label>
          <input nbInput fullWidth type="text" formControlName="default_competition_number"
                 id="default_competition_number" name="default_competition_number"
                 placeholder="Default competition number" >
        </div>


        <div class="col-2 check-col mb-3">
          <label>ARRIVAL DATE </label>
          <nb-checkbox status="basic" formControlName="arrival_date_"></nb-checkbox>
        </div>
        <div class="col-2 check-col mb-3">
          <label>COLLECT ENTRY FEES</label>
          <nb-checkbox status="basic" formControlName="collect_entry_fees"></nb-checkbox>
        </div>
        <div class="col-2 check-col mb-3">
          <label>AUTH CARDS ONLY</label>
          <nb-checkbox status="basic" formControlName="auth_only"></nb-checkbox>
        </div>
        <div class="col-2 check-col mb-3">
          <label>AUTH FULL AMOUNT</label>
          <nb-checkbox status="basic" formControlName="auth_full_amount"></nb-checkbox>
        </div>
        <div class="col-4 mb-3">
          <label>AUTH OTHER AMOUNT</label>
          <input nbInput fullWidth type="text" formControlName="other_auth_amount" id="other_auth_amount"
                 name="other_auth_amount"
                 placeholder="Other $ amount to auth" >
        </div>


        <div class="col-2 check-col mb-3">
          <label>INVITATION </label>
          <nb-checkbox status="basic" formControlName="invitation_"></nb-checkbox>
        </div>
        <div class="col-10 mb-3">
          <label>MESSAGE ABOUT INVITATIONS</label>
          <input nbInput fullWidth type="text" formControlName="invitation_description" id="invitation_description"
                 name="invitation_description"
                 placeholder="Invitation  message" >
        </div>


        <div class="col-1 check-col mb-3">
          <label>QUALIFICATION </label>
          <nb-checkbox status="basic" formControlName="qualification_"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>NOTIFY STALL COUNT</label>
          <nb-checkbox status="basic" formControlName="notify_stall_count"></nb-checkbox>
        </div>
        <div class="col-1 check-col mb-3">
          <label>CONTROL CAPACITY</label>
          <nb-checkbox status="basic" formControlName="capacity_controlled"></nb-checkbox>
        </div>
        <div class="col-4 mb-3">
          <label>CAPACITY</label>
          <input nbInput fullWidth type="text" formControlName="capacity" id="capacity" name="capacity"
                 placeholder="Other $ amount to auth" >
        </div>
        <div class="col-1 check-col mb-3">
          <label>ENFORCE CLOSING DATE</label>
          <nb-checkbox status="basic" formControlName="strict_closing_date"></nb-checkbox>
        </div>
        <div class="col-4 mb-3">
          <label>CLOSING TIME</label>
          <input nbInput fullWidth placeholder="Closing TIME" [nbDatepicker]="closing_time"
                 formControlName="closing_time">
          <nb-datepicker #closing_time></nb-datepicker>
        </div>


        <div class="col-12 mb-3">
          <label>BILLING MESSAGE</label>
          <input nbInput fullWidth type="text" formControlName="billing_message" id="billing_message"
                 name="billing_message"
                 placeholder="Message to be displayed to competitors on billing screen" >
        </div>


        <div class="col-2 check-col mb-3">
          <label>RESTRICT TO 1 RIDER</label>
          <nb-checkbox status="basic" formControlName="restrict_to_one_rider"></nb-checkbox>
        </div>
        <div class="col-10 mb-3">
          <label>RIDER RESTRICTION MESSAGE</label>
          <input nbInput fullWidth type="text" formControlName="restrict_rider_message" id="restrict_rider_message"
                 name="restrict_rider_message"
                 placeholder="Message to display if user tries to add additional rider" >
        </div>


      </div>
      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>
        <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
                class="btn-save">{{ edit ? 'Update' : 'Save' }}
        </button>
      </div>

    </form>
  </nb-card-body>
</nb-card>
