import {Component, ElementRef, ViewChild} from '@angular/core';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute} from '@angular/router';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {Arena} from '../../../../models/arena';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Venues} from '../../../../models/venues';

@Component({
  selector: 'ngx-competition-add-edit',
  templateUrl: './competition-add-edit.component.html',
  styleUrls: ['./competition-add-edit.component.scss'],
})
export class CompetitionAddEditComponent {
  @ViewChild('dialogCompetition', {static: true})
  dialogCompetition: ElementRef;
  @ViewChild('dialogGroup', {static: true})
  dialogGroup: ElementRef;

  competitionForm: FormGroup = new FormGroup({
    competition_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    entry_fee: new FormControl(''),
    point_group_number: new FormControl(''),
    prize_group_number: new FormControl(''),
    nomination_fees: new FormControl(''),
    unit: new FormControl(''),
    rule_id: new FormControl(''),
    level: new FormControl(''),
    date: new FormControl(''),
    scheduled_start_time: new FormControl(''),
    venue_number: new FormControl(''),
    arena_number: new FormControl(''),
    type: new FormControl(''),
    flat: new FormControl(true),
    usef_section_code: new FormControl(''),
    sponsor: new FormControl(''),
    sponsor_type: new FormControl(''),
  });

  public edit: boolean = false;
  _prizes: any[] = [];
  _units = [{name: 'Meter', option: 'M'}, {name: 'Inches', option: 'I'}, {name: 'Standards', option: 'L'}];
  _arenas: Arena[] = [];
  _venues: any[] = [];
  _levels: any[] = [];
  _tax: any[] = [];
  _rules: any[] = [];
  _points: any[] = [
    {name: 'first', point_group_number: 10},
    {name: 'second', point_group_number: 2},
    {name: 'hello', point_group_number: 9}];
  horse_show: any = JSON.parse(localStorage.getItem('sms_default_horse_show'));
  showSelectVenue: boolean = false;
  selected: any;
  section: any;
  private editCompetition: any;
  private getCompetitionAPI: boolean = false;

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService, private dialogService: NbDialogService) {
    this.getPrizes();
    this.getVenues();
    this.getNominatingFees();
    this.getArenas();
    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getCompetition(params['uuid']);
        this.edit = true;
      }
      if (params['section']) {
        this.section = JSON.parse(localStorage.getItem('section'));
        this.getRules();
      } else {
      }
    });
    this.showSelectVenue = this.horse_show.venue_id === null || this.horse_show.venue_id === '';
  }

  getPrizes() {
    this.showService.getPrizeGroups().then((prizes: []) => {
      this._prizes = prizes;
    });
  }

  public getArenas() {
    if (this.horse_show.venue_id !== null) {
      this.showService.getArenas(this.horse_show.venue_id).then((arenas: Arena[]) => {
        this._arenas = arenas;
        if (arenas.length === 0) {
          this.toastService.danger('', `The venue doesn't have any ARENAS`);
        }
      });
    } else {
      // show VENUE selector
    }
  }

  private getVenues() {
    this.showService.getShowVenues().then(venues => {
      this._venues = venues;
    });
  }

  private getCompetition(param: any) {
    this.showService.getCompetition(param).then((competition: any) => {
      this.showService.getSection(competition.section_id).then((section: any) => {
        this.section = section;
        this.getRules();
      });

      this.editCompetition = competition;
      this.competitionForm.controls['arena_number'].setValue(competition.arena_number);

      this.competitionForm.controls['competition_number'].setValue(competition.competition_number);
      this.competitionForm.controls['name'].setValue(competition.name);
      this.competitionForm.controls['entry_fee'].setValue(competition.entry_fee);
      this.competitionForm.controls['point_group_number'].setValue(competition.point_group_number);
      this.competitionForm.controls['prize_group_number'].setValue(competition.prize_group_number);
      if (competition.nominating_fees !== null) {
        this.competitionForm.controls['nomination_fees'].setValue(competition.nominating_fees);
      }
      this.competitionForm.controls['unit'].setValue(competition.unit);
      this.competitionForm.controls['rule_id'].setValue(competition.rule_id);
      this.competitionForm.controls['level'].setValue(competition.level);
      this.competitionForm.controls['date'].setValue(new Date(competition.date));
      const timeCompetition = competition.scheduled_start_time.split(':');
      if (competition.start_time_tod === 'PM') {
        timeCompetition[0] = Number(timeCompetition[0]) + 12;
      }
      const time = new Date(competition.date);
      time.setHours(timeCompetition[0]);
      time.setMinutes(timeCompetition[1]);
      this.competitionForm.controls['scheduled_start_time'].setValue(time);
      this.competitionForm.controls['type'].setValue(competition.type);
      this.competitionForm.controls['flat'].setValue(competition.flat);
      this.competitionForm.controls['usef_section_code'].setValue(competition.usef_section_code);
    });
  }

  onSubmit() {
    const time = this.competitionForm.controls['scheduled_start_time'].value.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    if (this.competitionForm.valid) {
      const body: any = {
        competition_number: this.competitionForm.controls['competition_number'].value,
        name: this.competitionForm.controls['name'].value,
        horse_show_number: this.horse_show.horse_show_number,
        horse_show_id: this.horse_show.id,
        entry_fee: this.competitionForm.controls['entry_fee'].value,
        section_number: this.section.section_number,
        section_id: this.section.id,
        level: this.competitionForm.controls['level'].value,
        unit: this.competitionForm.controls['unit'].value,
        rule_id: this.competitionForm.controls['rule_id'].value,
        date: this.competitionForm.controls['date'].value.toISOString(),
        scheduled_start_time: time.split(' ')[0],
        start_time_tod: time.split(' ')[1],
        arena_number: this.competitionForm.controls['arena_number'].value,
        flat: this.competitionForm.controls['flat'].value,
        usef_section_code: this.competitionForm.controls['usef_section_code'].value,
        type: this.competitionForm.controls['type'].value,
        point_group_number: this.competitionForm.controls['point_group_number'].value,
        prize_group_number: this.competitionForm.controls['prize_group_number'].value,
        nominating_fees: [],
      };

      this._tax.forEach((tax: any) => {
        if (this.competitionForm.controls['nomination_fees'].value.includes(tax.id)) {
          body.nominating_fees.push(tax);
        }
      });

      if (!this.edit) {
        this.showService.postCompetition(body).then(res => {
          this.toastService.success('', 'Competition created');
          // set to edit and enable add competition
          this.editCompetition = res;
          this.edit = true;
        }, error => {
          this.toastService.danger('', 'Error creating competition');
        });
      } else {
        const updateBody: any = {};
        updateBody.id = this.editCompetition.id;
        Object.keys(this.competitionForm.controls).forEach((key: string) => {
          if (!this.competitionForm.controls[key].pristine) {
            if (key === 'scheduled_start_time') {
              updateBody.scheduled_start_time = time.split(' ')[0];
              updateBody.start_time_tod = time.split(' ')[1];
            } else {
              updateBody[key] = this.competitionForm.controls[key].value;
            }
          }
        });
        this.showService.putCompetition(this.editCompetition.id, updateBody).then(res => {
          this.toastService.success('', 'Competition updated');
        }, error => {
          this.toastService.danger('', 'Error updating competition');
        });
      }
    } else {
      this.toastService.warning('', 'Please check the form');
    }

  }

  private getNominatingFees() {
    this.showService.getBillingNominatingFees().then((res: any) => {
      this._tax = res;
    });
  }

  private getRules() {
    if (this.section.fee_option !== 'C') {
      this.competitionForm.get('entry_fee').disable();
    }
    this.showService.getCompetitionsRules(this.section.discipline_code).then((rules: []) => {
      this._rules = rules;
    });
  }


  editExistingCompetition(edit: boolean) {
    if (edit) {
      this.competitionForm.reset();
      this.edit = true;
      this.getCompetition(this.editCompetition.id);
    } else {
      this.competitionForm.controls['competition_number'].setValue('');
    }
  }

  checkCompetition() {
    if (!this.getCompetitionAPI && this.competitionForm.controls['competition_number'].value !== null) {
      this.getCompetitionAPI = true;
      setTimeout(() => {
        this.showService.getCompetitionByNumber(this.competitionForm.controls['competition_number'].value)
          .then((editVenue: Venues) => {
            this.editCompetition = editVenue;
            // @ts-ignore
            this.dialogService.open(this.dialogCompetition, {closeOnBackdropClick: false});
          });
        this.getCompetitionAPI = false;
      }, 2000);
    }
  }

  addPrizeGroup() {
    // @ts-ignore
    this.dialogService.open(this.dialogGroup, {closeOnBackdropClick: false});
  }

  handleGroupEvent(event: any) {
    this.getPrizes();
  }

  selectedVenue() {
    // set venue this.horse_show.venue and this.getArenas
    this.horse_show.venue_id = this.competitionForm.controls['venue_number'].value;
    this.getArenas();
  }
}
