import {AfterContentInit, Component} from '@angular/core';
import {DatePipe, NgForOf, NgIf} from '@angular/common';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';
import {Arena} from '../../../models/arena';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Venues} from '../../../models/venues';
import {
  NbButtonModule,
  NbCardModule,
  NbListModule,
  NbOptionModule,
  NbSelectModule, NbTreeGridDataSource, NbTreeGridDataSourceBuilder,
  NbTreeGridModule,
} from '@nebular/theme';

@Component({
  selector: 'ngx-app-arenas',
  standalone: true,
  imports: [
    DatePipe,
    NgForOf,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NbButtonModule,
    NbCardModule,
    NbListModule,
    NbOptionModule,
    NbSelectModule,
    NbTreeGridModule,
  ],
  templateUrl: './arenas.component.html',
  styleUrls: ['./arenas.component.scss'],
})
export class ArenasComponent implements AfterContentInit {
  _arenas: Arena[] = [];
  _venues: Venues[] = [];
  venueSelected: any = '';
  gotArenas: boolean = false;
  columnsHead = ['Arena Number', 'Name'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];
  horse_show = JSON.parse(localStorage.getItem('sms_default_horse_show'));
  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
  }

  ngAfterContentInit(): void {
    this.getVenues();
  }

  private getVenues() {
    this.showService.getShowVenues().then(venues => {
      this._venues = venues;
      setTimeout(() => {
        this.venueSelected = this.horse_show.venue_id;
        this.getArenas();
      }, 10);

    });
  }

  addEditArena(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['arena-add-edit', {uuid: uuid, venue: this.venueSelected}]).then();
    } else {
      this.router.navigate(['arena-add-edit', {venue: this.venueSelected}]).then();
    }
  }

  getArenas() {
    this.data = [];
    this.showService.getArenas(this.venueSelected).then((arenas: Arena[]) => {
      this.gotArenas = true;
      // @ts-ignore
      this._arenas = arenas;
      arenas.forEach((el: any) => {
        el.gate_latitude = el.gate_latitiude;
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
