import {Component} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgForOf} from '@angular/common';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute} from '@angular/router';
import {
  NbButtonModule, NbCardModule,
  NbCheckboxModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule, NbToastrService,
  NbToggleModule,
} from '@nebular/theme';

@Component({
  selector: 'ngx-app-billing-item-template',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    NbButtonModule,
    NbSelectModule,
    NbInputModule,
    NbRadioModule,
    NbCheckboxModule,
    NbToggleModule,
    NbCardModule,
  ],
  templateUrl: './billing-item-template.component.html',
  styleUrls: ['./billing-item-template.component.scss'],
})
export class BillingItemTemplateComponent {
  edit: boolean = false;

  billingForm: FormGroup = new FormGroup({
    code: new FormControl(''),
    category: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    unit_price: new FormControl('', [Validators.required, Validators.minLength(1)]),
    quantity_amount: new FormControl(true),
    listing_order: new FormControl('', [Validators.required, Validators.minLength(1)]),
    charge_quantity: new FormControl(''),
    charge_amount: new FormControl(''),
    add_to_item_check: new FormControl(true),
    nominating_check: new FormControl(false),
    request_check: new FormControl(false),
    stabling_check: new FormControl(false),
    chargeable_check: new FormControl(false),
  });

  categories: any[] = [
    {
      id: '',
      name: 'No category',
    },
  ];
  editBillingItem: any = {};
  // @ts-ignore
  private horseShow = JSON.parse(localStorage.getItem('sms_default_horse_show'));

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService) {
    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getBillingTemplate(params['uuid']);
      }
    });
    this.getCategories();
  }

  onSubmit() {
    if (this.billingForm.valid) {
      const body = {
        code: this.billingForm.controls['code'].value,
        horse_show_id: this.horseShow.id,
        unit_price: this.billingForm.controls['unit_price'].value,
        name: this.billingForm.controls['name'].value,
        category_id: this.billingForm.controls['category'].value,
        add_to_item: this.billingForm.controls['add_to_item_check'].value,
        default_precharge_quantity: this.billingForm.controls['charge_quantity'].value,
        default_precharge_amount: this.billingForm.controls['charge_amount'].value,
        quantity_based: this.billingForm.controls['quantity_amount'].value,
        amount_based: !this.billingForm.controls['quantity_amount'].value,
        listing_order: this.billingForm.controls['listing_order'].value,
        nominating_fee: this.billingForm.controls['nominating_check'].value,
        chargeable: this.billingForm.controls['chargeable_check'].value,
        categorize_as_stabling: this.billingForm.controls['stabling_check'].value,
        request_before_charging: this.billingForm.controls['request_check'].value,
      };
      if (!this.edit) {
        this.showService.postBillingTemplates(body).then(res => {
          this.resetForm();
          this.toastService.success('', 'Billing template created');
        }, error => {
          this.toastService.danger('', 'Error creating billing template');
        });
      } else {
        // @ts-ignore
        this.showService.putBillingTemplates(this.editBillingItem.id, body).then(res => {
          this.toastService.success('', 'Bill item updated');
        }, error => {
          this.toastService.danger('', 'Error updating bill item');
        });
      }
    } else {
      this.toastService.warning('', 'Please check the form');
    }
  }

  private getBillingTemplate(param: any) {
    this.edit = true;
    // get the billing template and inject into form
    this.showService.getBillingTemplate(param).then(res => {
      this.editBillingItem = res;
      this.billingForm.controls['code'].setValue(res.code);
      this.billingForm.controls['name'].setValue(res.name);
      this.billingForm.controls['unit_price'].setValue(res.unit_price);
      this.billingForm.controls['category'].setValue(res.category_id);
      this.billingForm.controls['add_to_item_check'].setValue(res.add_to_item);
      this.billingForm.controls['charge_quantity'].setValue(res.default_precharge_quantity);
      this.billingForm.controls['charge_amount'].setValue(res.default_precharge_amount);
      this.billingForm.controls['quantity_amount'].setValue(res.quantity_based);
      this.billingForm.controls['listing_order'].setValue(res.listing_order);
      this.billingForm.controls['nominating_check'].setValue(res.nominating_fee);
      this.billingForm.controls['chargeable_check'].setValue(res.chargeable);
      this.billingForm.controls['stabling_check'].setValue(res.categorize_as_stabling);
      this.billingForm.controls['request_check'].setValue(res.request_before_charging);
    });
  }

  resetForm() {
    this.edit = false;
    this.billingForm.reset();
    this.billingForm.controls['quantity_amount'].setValue(true);
    this.billingForm.controls['add_to_item_check'].setValue(true);
    this.editBillingItem = {};
  }

  private getCategories() {
    this.showService.getBillingCategories().then(res => {
      if (res.length > 0 ) {
        this.categories = res;
      }
    });
  }
}
