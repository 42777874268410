import {Injectable} from '@angular/core';
import {SessionService} from '../providers/session.service';
import {SmsApiSessionService} from '../providers/sms-api-session.service';
import {ModalLoginService} from '../providers/modal-login.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(private sessionService: SessionService, private smsApiSessionService: SmsApiSessionService,
              private router: Router, private modalLoginService: ModalLoginService) {
  }

  canActivate() {
    return this.smsApiSessionService.getSession().then(user => {
      if (user.enabled) {
        this.sessionService.setUser(user);
        return true;
      } else {
        // console.log(`User ain't enabled, whoa big fella! We will call the modal login for you/`);
        return this.getUserViaModalLogin('');
      }
    }).catch(error => {
      // console.log('Something went wrong so calling the modal login. Maybe there is no session present?');
      if (error === null || (error.status && error.status === 403)) {
        // location.reload();
        return this.getUserViaModalLogin('No exising session found, please login');
      } else {
        if (error.message) {
          return this.getUserViaModalLogin(error.message);
        } else {
          return this.getUserViaModalLogin(error);
        }

      }

    });
  }

  private getUserViaModalLogin(error_message: string): boolean {
    // this.router.navigate(['auth/login']).then();
    // location.reload();
    this.modalLoginService.getSession(error_message).then((session: any) => {
      if (session.enabled) {
        location.reload();
        return true;
      } else {
        // go to login page
        // this.getUserViaModalLogin('User not enabled, please try again');
        this.router.navigate(['auth/login']).then();
        return false;
      }

    }).catch((error: any) => {
      // console.log('Calling get user from recursion 2');
      // this.getUserViaModalLogin(error);
      this.router.navigate(['auth/login']).then();

    });
    return false;
  }
}
