<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} RULE</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="ruleForm" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-2 mb-3">
          <label>FEDERATION</label>
          <nb-select fullWidth formControlName="federation_code">
            <nb-option value="USEF">USEF</nb-option>
            <nb-option value="EC">EC</nb-option>
            <nb-option value="FEI">FEI</nb-option>
          </nb-select>
        </div>
        <div class="col-2 mb-3">
          <label>DISCIPLINE CODE</label>
          <nb-select fullWidth placeholder="Select discipline"
                     formControlName="discipline_code">
            <nb-option *ngFor="let discipline of _discipline" [value]="discipline.option">{{ discipline.name }}
            </nb-option>
          </nb-select>
        </div>
        <div class="col-2 mb-3">
          <label>ARTICLE</label>
          <input nbInput fullWidth type="text" formControlName="article" id="article" name="article"
                 placeholder="">
        </div>
        <div class="col-3 mb-3">
          <label>NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="" required>
        </div>
        <div class="col-2 mb-3">
          <label>LISTING ORDER</label>
          <input nbInput fullWidth type="text" formControlName="listing_order" id="listing_order" name="listing_order"
                 placeholder="" required>
        </div>
      </div>

      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

        <button nbButton shape="semi-round" status="primary" (click)="onSubmit()"
                class="btn-save">{{ edit ? 'Update' : 'Save' }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
