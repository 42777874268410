import {Component} from '@angular/core';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatePipe, NgForOf} from '@angular/common';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbListModule,
  NbToggleModule, NbTreeGridDataSource, NbTreeGridDataSourceBuilder,
  NbTreeGridModule,
} from '@nebular/theme';


@Component({
  selector: 'ngx-app-billing-item-template-list',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    NbButtonModule,
    NbCardModule,
    DatePipe,
    NbListModule,
    NbToggleModule,
    NbCheckboxModule,
    NbTreeGridModule,
  ],
  templateUrl: './billing-item-template-list.component.html',
  styleUrls: ['./billing-item-template-list.component.scss'],
})
export class BillingItemTemplateListComponent {
  public _templates: any = [];
  columnsHead = ['Code', 'Listing Order', 'Name', 'Add To Item', 'Default Precharge Quantity', 'Unit Price'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];

  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    if (localStorage.getItem('sms_default_horse_show_organizer')) {
      this.getBillingTemplates();
    }
  }

  getBillingTemplates() {
    const id = JSON.parse(localStorage.getItem('sms_default_horse_show')).id;
    this.showService.getBillingTemplates(id).then((templates: []) => {
      // @ts-ignore
      this._templates = templates;
      templates.forEach((el: any) => {
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addTemplate(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['billing-template', {uuid: uuid}]).then();
    } else {
      this.router.navigate(['billing-template']).then();
    }
  }

  addCategory(uuid: string) {
    this.router.navigate(['billing-category', {uuid: uuid}]).then();
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
