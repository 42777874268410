<nb-card [accent]="!edit ? 'info' : 'warning'" [nbSpinner]="getHorseShowAPI" nbSpinnerStatus="primary">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} HORSE SHOW</h2>
  </nb-card-header>
  <nb-card-body>

    <ng-template #dialogHorseShow let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>HORSE SHOW ALREADY EXISTS</nb-card-header>
        <nb-card-body>DO YOU WANT TO EDIT THE EXISTING HORSE SHOW
          #{{ this.horseShowForm.controls['horse_show_number'].value }}?
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close(); editExisting(false)">CLOSE</button>
          <button nbButton status="primary" (click)="ref.close(); editExisting(true)">EDIT</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <ng-template #venueDialog let-data let-ref="dialogRef" >
      <nb-card class="dialog-create">
        <nb-card-body>
          <ngx-app-venue-add-edit [isModal]="true" (venueEvent)="handleVenue($event)"></ngx-app-venue-add-edit>
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close()">CLOSE</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <form [formGroup]="horseShowForm" class="needs-validation" novalidate>
      <div class="row">
        <div class="col-6 mb-3">
          <label>Horse show number</label>
          <input nbInput fullWidth type="number" formControlName="horse_show_number" id="validationCustom02" name="name"
                 placeholder="Horse show number" required (keyup)="checkHorseShow()">
        </div>
        <div class="col-6 mb-3">
          <label>Select organizer</label>
          <nb-select fullWidth [selected]="selected" placeholder="Select organizer"
                     formControlName="horse_show_organizer_id">
            <nb-option *ngFor="let organizer of _organizers" [value]="organizer.id">{{ organizer.name }}</nb-option>
          </nb-select>
        </div>
      </div>

      <div class="form-floating mb-3">
        <label>Name</label>
        <input nbInput fullWidth type="text" formControlName="name" id="validationCustom03" name="name"
               placeholder="Name" required>
      </div>
      <div class="form-floating mb-3">
        <label>Location</label>
        <input nbInput fullWidth type="text" formControlName="location" id="validationCustom04" name="location"
               placeholder="Location" >
      </div>

      <div class="row">
        <div class="col-6 form-floating mb-3">
          <label>Short name</label>
          <input nbInput fullWidth type="text" formControlName="short_name" id="validationCustom05" name="short_name"
                 placeholder="Short name" >
        </div>
        <div class="col-6 mb-3" style="align-items: center;display: flex; padding-top: 20px;">
          <nb-checkbox status="basic" formControlName="credit_prize_money">Credit Prize Money</nb-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label>Start date</label>
          <input nbInput fullWidth placeholder="Start date" [nbDatepicker]="startDate" formControlName="start_date">
          <nb-datepicker #startDate></nb-datepicker>
        </div>
        <div class="col-6 mb-3">
          <label>End date</label>
          <input nbInput fullWidth placeholder="End date" [nbDatepicker]="endDate" formControlName="end_date">
          <nb-datepicker #endDate></nb-datepicker>
        </div>
        <div class="col-6 mb-3">
          <label></label>
          <label style="display: block;">
            Select Venue
            <span class="add-group-button" (click)="createVenue()">+ADD</span>
          </label>
          <nb-select fullWidth [selected]="selected" placeholder="Select Venue"
                     formControlName="venue_id">
            <nb-option *ngFor="let venue of _venues" [value]="venue.id">{{ venue.fei_name !== '' ? venue.fei_name : venue.name }}</nb-option>
          </nb-select>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label>Select Federation code</label>
          <nb-select fullWidth placeholder="Select Federation code"
                     formControlName="federation_code">
            <nb-option value="USEF">USEF</nb-option>
            <nb-option value="EC">EC</nb-option>
            <nb-option value="FEI">FEI</nb-option>
          </nb-select>
        </div>
        <div class="col-6 form-floating mb-3">
          <label>Select Country iso</label>
          <nb-select fullWidth placeholder="Select Country iso"
                     formControlName="country_iso_3">
            <nb-option value="USA">USA</nb-option>
            <nb-option value="CAN">CAN</nb-option>
            <nb-option value="MEX">MEX</nb-option>
          </nb-select>
        </div>
      </div>

      <!--USEF-->
      <div class="row">
        <div class="col-6 form-floating mb-3">
          <label>Usef numbe</label>
          <input nbInput fullWidth type="text" formControlName="usef_number" id="usef_number" name="usef_number"
                 placeholder="Usef number" >
        </div>
        <div class="col-6 form-floating mb-3">
          <label>Usef zone</label>
          <input nbInput fullWidth type="text" formControlName="usef_zone" id="usef_zone" name="usef_zone"
                 placeholder="Usef zone" >
        </div>
      </div>
      <div class="row">
        <div class="col-6 form-floating mb-3">
          <label>Manager usef ID</label>
          <input nbInput fullWidth type="text" formControlName="manager_usef_id" id="manager_usef_id"
                 name="manager_usef_id"
                 placeholder="Manager usef ID" >
        </div>
        <div class="col-6 form-floating mb-3">
          <label>Secretary usef ID</label>
          <input nbInput fullWidth type="text" formControlName="secretary_usef_id" id="secretary_usef_id"
                 name="secretary_usef_id"
                 placeholder="Secretary usef ID" >
        </div>
      </div>

      <div class="row">
        <div class="col-6 form-floating mb-3">
          <label>FEI ID</label>
          <input nbInput fullWidth type="text" formControlName="fei_id" id="fei_id" name="fei_id"
                 placeholder="FEI ID" >
        </div>
        <div class="col-6 form-floating mb-3">
          <label>Non horse entry number</label>
          <input nbInput fullWidth type="text" formControlName="non_horse_entry_number" id="non_horse_entry_number"
                 name="non_horse_entry_number"
                 placeholder="Non horse entry number" >
        </div>
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <nb-checkbox status="basic" formControlName="default_hs">Default HS</nb-checkbox>
        </div>
        <div class="col-6 mb-3">
          <nb-checkbox status="basic" formControlName="closed">Closed</nb-checkbox>
        </div>
      </div>
      <div class="form-floating mb-3">
        <label>Footer</label>
        <textarea rows="5" nbInput fullWidth formControlName="footer" class="form-control" name="federation_code"
                  placeholder="Footer"></textarea>
      </div>

      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

        <button nbButton shape="semi-round" status="primary" (click)="onSubmit()"
                class="btn-save">{{ edit ? 'Update' : 'Save' }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
