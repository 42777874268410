<nb-card [accent]="!edit ? 'info' : 'warning'" [nbSpinner]="getOrganizerAPI" nbSpinnerStatus="primary">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} ORGANIZER RECORD</h2>
  </nb-card-header>
  <nb-card-body>

    <ng-template #dialogOrganizer let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>HORSE SHOW ORGANIZER ALREADY EXISTS</nb-card-header>
        <nb-card-body>DO YOU WANT TO EDIT THE EXISTING ORGANIZER RECORD
          #{{ this.organizationForm.controls['organizer_number'].value }}?
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close(); editExistingOrganizer(false)">CLOSE</button>
          <button nbButton status="primary" (click)="ref.close(); editExistingOrganizer(true)">EDIT</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>


    <form [formGroup]="organizationForm" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>ORGANIZER NUMBER</label>
          <input nbInput fullWidth type="number" formControlName="organizer_number" id="organizer_number"
                 name="organizer_number"
                 placeholder="Organizer Number" required (keyup)="checkOrganization()">
        </div>
        <div class="col-8 form-floating mb-3">
          <label>ORGANIZER NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="Organizer Name" required>
        </div>
      </div>

      <div class="form-floating mb-3">
        <label>WEBSITE URL</label>
        <input nbInput fullWidth type="text" formControlName="url" id="url" name="url"
               placeholder="Website URL">
      </div>

      <div class="row">
        <div class="col-6 form-floating mb-3">
          <label>SHORT NAME</label>
          <input nbInput fullWidth type="text" formControlName="short_name" id="short_name" name="short_name"
                 placeholder="Short Name">
        </div>
        <div class="col-6 form-floating mb-3">
          <label>PAYMENT GATEWAY</label>
          <nb-select fullWidth placeholder="Select Payment" formControlName="payment_gateway">
            <nb-option value="Braintree">Braintree</nb-option>
            <nb-option value="Bambora">Bambora</nb-option>
            <nb-option value="Stripe">Stripe</nb-option>
            <nb-option value="">None</nb-option>
          </nb-select>
        </div>
      </div>

      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>MERCHANT ACCOUNT ID</label>
          <input nbInput fullWidth type="text" formControlName="merchant_account_id" id="merchant_account_id"
                 name="merchant_account_id"
                 placeholder="MERCHANT ACCOUNT ID">
        </div>
        <div class="col-4 mb-3" style="align-items: center;display: flex; padding-top: 20px;">
          <nb-checkbox status="basic" formControlName="collect_convenience_fee" style="padding-top: 20px">CHARGE
            CONVENIENCE FEE
          </nb-checkbox>
        </div>
        <div class="col-4 mb-3">
          <label>RATE (%)</label>
          <input nbInput fullWidth type="number" formControlName="convenience_fee_rate" id="arena_lat" name="arena_lat"
                 placeholder="RATE (%)">
        </div>
        <div class="col-12 mb-3">
          <label>CONVENIENCE FEE WORDING</label>
          <textarea rows="5" nbInput fullWidth formControlName="convenience_fee_wording" class="form-control"
                    name="federation_code"
                    placeholder="CONVENIENCE FEE WORDING"></textarea>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

        <button nbButton shape="semi-round" status="primary" (click)="onSubmit()"
                class="btn-save">{{ edit ? 'Update' : 'Save' }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
