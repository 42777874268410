import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {PagesComponent} from './pages.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotFoundComponent} from './miscellaneous/not-found/not-found.component';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from '../_guards/auth.guard';
import {HorseShowOrganizerComponent} from './set-up/horse-show-organizer/horse-show-organizer.component';
import {HorseShowComponent} from './set-up/horse-show/horse-show.component';
import {HorseShowAddEditComponent} from './set-up/horse-show/horse-show-add-edit/horse-show-add-edit.component';
import {VenuesComponent} from './set-up/venues/venues.component';
import {VenueAddEditComponent} from './set-up/venues/venue-add-edit/venue-add-edit.component';
import {ArenasComponent} from './set-up/arenas/arenas.component';
import {ArenaAddEditComponent} from './set-up/arenas/arena-add-edit/arena-add-edit.component';
import {
  BillingItemTemplateListComponent,
} from './Billing/billing-item-template-list/billing-item-template-list.component';
import {
  BillingItemTemplateComponent,
} from './Billing/billing-item-template-list/billing-item-template/billing-item-template.component';
import {
  BillingItemCategoryComponent,
} from './Billing/billing-item-template-list/billing-item-category/billing-item-category.component';
import {TaxTemplateComponent} from './Billing/tax-template/tax-template.component';
import {
  TaxTemplateAddEditComponent,
} from './Billing/tax-template/tax-template-add-edit/tax-template-add-edit.component';
import {PersonComponent} from './set-up/person/person.component';
import {PrizeMoneyComponent} from './Billing/prize-money/prize-money.component';
import {PrizeMoneyAddEditComponent} from './Billing/prize-money/prize-money-add-edit/prize-money-add-edit.component';
import {SectionsComponent} from './set-up/sections/sections.component';
import {SectionAddEditComponent} from './set-up/sections/section-add-edit/section-add-edit.component';
import {CompetitionsComponent} from './set-up/competitions/competitions.component';
import {CompetitionAddEditComponent} from './set-up/competitions/competition-add-edit/competition-add-edit.component';
import {EntryComponent} from './set-up/entry/entry.component';
import {EntryAddEditComponent} from './set-up/entry/entry-add-edit/entry-add-edit.component';
import {PersonAddEditComponent} from './set-up/person/person-add-edit/person-add-edit.component';
import {HorsesComponent} from './set-up/horses/horses.component';
import {HorseAddEditComponent} from './set-up/horses/horse-add-edit/horse-add-edit.component';
import {
  HorseShowOrganizerAddEditComponent,
} from './set-up/horse-show-organizer/horse-show-organizer-add-edit/horse-show-organizer-add-edit.component';
import {EntryListComponent} from './entry-list/entry-list.component';
import {AddEditEntryComponent} from './entry-list/add-edit-entry/add-edit-entry.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'dashboard',
      component: DashboardComponent,
    },
    {
      path: 'miscellaneous',
      loadChildren: () => import('./miscellaneous/miscellaneous.module')
        .then(m => m.MiscellaneousModule),
    },
    {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
    {path: 'entry-list', component: EntryListComponent, canActivate: [AuthGuard]},
    {path: 'entry-add-edit', component: AddEditEntryComponent, canActivate: [AuthGuard]},
    {path: 'horse-show-organizers', component: HorseShowOrganizerComponent, canActivate: [AuthGuard]},
    {path: 'horse-show-organizer-add-edit', component: HorseShowOrganizerAddEditComponent, canActivate: [AuthGuard]},
    {path: 'horse-show', component: HorseShowComponent, canActivate: [AuthGuard]},
    {path: 'horse-show-add-edit', component: HorseShowAddEditComponent, canActivate: [AuthGuard]},
    {path: 'venues', component: VenuesComponent, canActivate: [AuthGuard]},
    {path: 'venue-add-edit', component: VenueAddEditComponent, canActivate: [AuthGuard]},
    {path: 'arenas', component: ArenasComponent, canActivate: [AuthGuard]},
    {path: 'arena-add-edit', component: ArenaAddEditComponent, canActivate: [AuthGuard]},
    {path: 'billing-template-list', component: BillingItemTemplateListComponent, canActivate: [AuthGuard]},
    {path: 'billing-template', component: BillingItemTemplateComponent, canActivate: [AuthGuard]},
    {path: 'billing-category', component: BillingItemCategoryComponent, canActivate: [AuthGuard]},
    {path: 'tax-template-list', component: TaxTemplateComponent, canActivate: [AuthGuard]},
    {path: 'tax-template-add-edit', component: TaxTemplateAddEditComponent, canActivate: [AuthGuard]},
    {path: 'prize-money', component: PrizeMoneyComponent, canActivate: [AuthGuard]},
    {path: 'prize-money-add-edit', component: PrizeMoneyAddEditComponent, canActivate: [AuthGuard]},
    {path: 'sections', component: SectionsComponent, canActivate: [AuthGuard]},
    {path: 'section-add-edit', component: SectionAddEditComponent, canActivate: [AuthGuard]},
    {path: 'competitions', component: CompetitionsComponent, canActivate: [AuthGuard]},
    {path: 'competition-add-edit', component: CompetitionAddEditComponent, canActivate: [AuthGuard]},
    {path: 'entries', component: EntryComponent, canActivate: [AuthGuard]},
    {path: 'entry-add-edit', component: EntryAddEditComponent, canActivate: [AuthGuard]},
    {path: 'person', component: PersonComponent, canActivate: [AuthGuard]},
    {path: 'person-add-edit', component: PersonAddEditComponent, canActivate: [AuthGuard]},
    {path: 'horses', component: HorsesComponent, canActivate: [AuthGuard]},
    {path: 'horse-add-edit', component: HorseAddEditComponent, canActivate: [AuthGuard]},
    {path: '**', redirectTo: 'home'},
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    },
    {
      path: '**',
      component: NotFoundComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
