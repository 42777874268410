import {Component, ElementRef, ViewChild} from '@angular/core';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute} from '@angular/router';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'ngx-entry-add-edit',
  templateUrl: './entry-add-edit.component.html',
  styleUrls: ['./entry-add-edit.component.scss'],
})
export class EntryAddEditComponent {
  @ViewChild('dialogEntry', { static: true })
  dialogEntry: ElementRef;

  edit: boolean = false;
  entryEdit: any = {};
  horse_show = JSON.parse(localStorage.getItem('sms_default_horse_show'));

  entryForm: FormGroup = new FormGroup({
    entry_type_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    discipline: new FormControl(''),
    number_of_horses: new FormControl(''),
    description: new FormControl(''),
    closing_date: new FormControl(''),
    entry_available: new FormControl(false),
    payee_required: new FormControl(false),
    no_skip_payee: new FormControl(false),
    allow_competition_entry: new FormControl(false),
    skip_competition_add: new FormControl(false),
    competition_entry_required: new FormControl(false),
    default_competition_number: new FormControl(''),
    arrival_date_required: new FormControl(false),
    collect_entry_fees: new FormControl(false),
    auth_only: new FormControl(false),
    auth_full_amount: new FormControl(false),
    other_auth_amount: new FormControl(''),
    invitation_required: new FormControl(false),
    invitation_description: new FormControl(''),
    qualification_required: new FormControl(false),
    notify_stall_count: new FormControl(false),
    capacity_controlled: new FormControl(false),
    capacity: new FormControl(''),
    strict_closing_date: new FormControl(false),
    closing_time: new FormControl(''),
    billing_message: new FormControl(''),
    restrict_to_one_rider: new FormControl(false),
    restrict_rider_message: new FormControl(''),
    hide_section_entry: new FormControl(false),
    hide_competition_entry: new FormControl(false),
    additional_fields_message: new FormControl(''),
    additional_fields: new FormControl(''),
  });

  selected: any;
  getEntryAPI: boolean = false;

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService, private dialogService: NbDialogService) {

    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getEntry(params['uuid']);
        this.edit = true;
      }
    });
  }

  onSubmit() {
    const body: any = {
      horse_show_id: this.horse_show.id,
      entry_type_number: this.entryForm.controls['entry_type_number'].value,
      name: this.entryForm.controls['name'].value,
      discipline: this.entryForm.controls['discipline'].value,
      number_of_horses: this.entryForm.controls['number_of_horses'].value,
      description: this.entryForm.controls['description'].value,
      closing_date: this.entryForm.controls['closing_date'].value !== '' ?
        this.entryForm.controls['closing_date'].value.toISOString() : '',
      entry_available: this.entryForm.controls['entry_available'].value,
      payee_required: this.entryForm.controls['payee_required'].value,
      no_skip_payee: this.entryForm.controls['no_skip_payee'].value,
      allow_competition_entry: this.entryForm.controls['allow_competition_entry'].value,
      skip_competition_add: this.entryForm.controls['skip_competition_add'].value,
      competition_entry_required: this.entryForm.controls['competition_entry_required'].value,
      default_competition_number: this.entryForm.controls['default_competition_number'].value,
      arrival_date_required: this.entryForm.controls['arrival_date_required'].value,
      collect_entry_fees: this.entryForm.controls['collect_entry_fees'].value,
      auth_only: this.entryForm.controls['auth_only'].value,
      auth_full_amount: this.entryForm.controls['auth_full_amount'].value,
      other_auth_amount: this.entryForm.controls['other_auth_amount'].value,
      invitation_required: this.entryForm.controls['invitation_required'].value,
      invitation_description: this.entryForm.controls['invitation_description'].value,
      notify_stall_count: this.entryForm.controls['notify_stall_count'].value,
      capacity_controlled: this.entryForm.controls['capacity_controlled'].value,
      capacity: this.entryForm.controls['capacity'].value,
      strict_closing_date: this.entryForm.controls['strict_closing_date'].value,
      closing_time: this.entryForm.controls['closing_time'].value !== '' ?
        this.entryForm.controls['closing_time'].value.toISOString() : '',
      billing_message: this.entryForm.controls['billing_message'].value,
      restrict_to_one_rider: this.entryForm.controls['restrict_to_one_rider'].value,
      restrict_rider_message: this.entryForm.controls['restrict_rider_message'].value,
      hide_section_entry: this.entryForm.controls['hide_section_entry'].value,
      hide_competition_entry: this.entryForm.controls['hide_competition_entry'].value,
      additional_fields_message: this.entryForm.controls['additional_fields_message'].value,
      additional_fields: [],
    };

    if (this.entryForm.valid) {
      if (!this.edit) {
        this.showService.postEntryType(body).then(res => {
          this.entryForm.reset();
          this.toastService.success('', 'Show created');
        }, error => {
          this.toastService.danger('', 'Error creating show');
        });
      } else {
        body.id = this.entryEdit.id;
        // @ts-ignore
        this.showService.putEntryType(this.entryEdit.id, body).then(res => {
          this.toastService.success('', 'Show updated');
        }, error => {
          this.toastService.danger('', 'Error updating show');
        });
      }
    } else {
      // console.log('NOT VALID')
      this.toastService.warning('Please check the form!', 'Invalid form');
    }
  }

  private getEntry(param: any) {
    this.showService.getEntry(param).then((res: any) => {
      this.setEntry(res);
    });
  }

  setEntry(data: any) {
    this.entryEdit = data;
    this.edit = true;
    for (const key in data) {
      if (this.entryForm.get(key)) {
        this.entryForm.controls[key].setValue(data[key]);
      }
    }
    this.entryForm.controls['closing_date'].setValue(new Date(data.closing_date));
    this.entryForm.controls['closing_time'].setValue(new Date(data.closing_time));
  }

  editExistingEntry(edit: boolean) {
    if (edit) {
      this.entryForm.reset();
      this.setEntry(this.entryEdit);
    } else {
      this.entryForm.controls['entry_type_number'].setValue('');
    }
  }

  checkEntry() {
    if (!this.getEntryAPI && this.entryForm.controls['entry_type_number'].value !== null) {
      this.getEntryAPI = true;
      setTimeout(() => {
        this.showService.getEntriesByNumber(this.entryForm.controls['entry_type_number'].value).then((entry: any) => {
          this.entryEdit = entry;
          // @ts-ignore
          this.dialogService.open(this.dialogEntry, {closeOnBackdropClick: false});
        });
        this.getEntryAPI = false;
      }, 2000);
    }
  }

  resetForm() {
    this.edit = false;
    this.entryEdit = {};
    this.entryForm.reset();
  }
}
