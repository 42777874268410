<nb-card [accent]="!edit ? 'info' : 'warning'"  [nbSpinner]="getArenaAPI" nbSpinnerStatus="primary">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} ARENA FOR A VENUE</h2>
  </nb-card-header>
  <nb-card-body>
    <ng-template #dialogVenue let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>VENUE ALREADY EXISTS</nb-card-header>
        <nb-card-body>DO YOU WANT TO EDIT THE EXISTING VENUE
          #{{ this.arenaForm.controls['venue_number'].value }}?
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close(); editExistingArena(false)">CLOSE</button>
          <button nbButton status="primary" (click)="ref.close(); editExistingArena(true)">EDIT</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <form [formGroup]="arenaForm" class="arena-validation" novalidate>
      <div class="mb-3">
        <label>Select Venue</label>
        <nb-select fullWidth placeholder="Select Venue" formControlName="venue_number">
          <nb-option *ngFor="let venue of _venues" [value]="venue.id">{{ venue.name !== null ? venue.name : venue.fei_name }}</nb-option>
        </nb-select>
      </div>
      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>ARENA NUMBER</label>
          <input nbInput fullWidth type="number" formControlName="arena_number" id="arena_number" name="arena_number"
                 placeholder="ARENA NUMBER" (keyup)="checkArena()" required>
        </div>
        <div class="col-8 form-floating mb-3">
          <label>ARENA NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="ARENA NAME" required>
        </div>
      </div>

      <div class="mb-3">
        <label>DESCRIPTION</label>
        <textarea rows="5" nbInput fullWidth formControlName="description" placeholder="DESCRIPTION" required></textarea>
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label>WIDTH in METERS</label>
          <input nbInput fullWidth type="number" formControlName="width_m" id="width_m" name="width_m"
                 placeholder="WIDTH in METERS" required>
        </div>
        <div class="col-6 mb-3">
          <label>LENGTH in METERS</label>
          <input nbInput fullWidth type="number" formControlName="length_m" id="length_m" name="length_m"
                 placeholder="LENGTH in METERS" required>
        </div>
      </div>

      <div class="row">
        <div class="col-2 form-floating mb-3">
          <i class="btn location" (click)="getLocation()">GET LOCATION</i>
        </div>
        <div class="col-5 form-floating mb-3">
          <label>IN-GATE LATITUDE</label>
          <input nbInput fullWidth type="text" formControlName="gate_latitiude" id="gate_latitiude" name="gate_latitiude"
                 placeholder="IN-GATE LATITUDE" required>
        </div>
        <div class="col-5 form-floating mb-3">
          <label>IN-GATE LONGITUDE</label>
          <input nbInput fullWidth type="text" formControlName="gate_longitude" id="gate_longitude" name="gate_longitude"
                 placeholder="IN-GATE LONGITUDE" required>
        </div>
      </div>

      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>ORIENTATION</label>
          <input nbInput fullWidth type="number" formControlName="orientation" id="orientation" name="orientation"
                 placeholder="ORIENTATION" required>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

      <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
              class="btn-save">{{ edit ? 'Update' : 'Save' }}
      </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
