export class Arena {
  id: string = '';
  arena_number: number = 0;
  name: string = '';
  venue_id: string = '';
  venue_number: number = 0;
  description: string = '';
  width_m: number = 0;
  length_m: number = 0;
  gate_longitude: string = '';
  gate_latitiude: string = '';
  orientation: number = 0;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}
