<nb-card [accent]="!edit ? 'info' : 'warning'" [nbSpinner]="getVenueAPI" nbSpinnerStatus="primary">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} SECTIONS & COMPETITIONS</h2>
  </nb-card-header>
  <nb-card-body>

    <ng-template #dialog let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>SECTION ALREADY EXISTS</nb-card-header>
        <nb-card-body>DO YOU WANT TO EDIT THE EXISTING SECTION
          #{{ this.sectionForm.controls['section_number'].value }}?
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close(); editExisting(false)">CLOSE</button>
          <button nbButton status="primary" (click)="ref.close(); editExisting(true)">EDIT</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <form [formGroup]="sectionForm" class="needs-validation" novalidate>
      <div class="row">

        <div class="col-4 mb-3">
          <label>SECTION #</label>
          <input nbInput fullWidth type="number" formControlName="section_number" id="section"
                 name="name" (keyup)="checkSection()"
                 [status]="form('section_number') ?'danger':'basic'"
                 placeholder="SECTION #" required>
        </div>
        <div class="col-8 mb-3">
          <label>SECTION NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 [status]="form('name') ?'danger':'basic'"
                 placeholder="Name" required>
        </div>


        <div class="col-12 mb-3">
          <label>FEE OPTION</label>
          <nb-radio-group formControlName="fee_option" name="ng-model-group" (valueChange)="checkFee($event)">
            <nb-radio [value]="'C'">CHARGE ENTRY FEE PER COMPETITION</nb-radio>
            <nb-radio [value]="'F'">CHARGE SECTION ENTRY FEE</nb-radio>
            <nb-radio [value]="'D'">APPLY DISCOUNTED ENTRY FEE</nb-radio>
          </nb-radio-group>
        </div>
        <div class="col-4 mb-3">
          <label>ENTRY FEE</label>
          <input nbInput fullWidth type="number" formControlName="entry_fee" id="entry_fee" name="entry_fee"
                 [disabled]="sectionForm.value['fee_option'] === 'C'"
                 [status]="form('entry_fee') ?'danger':'basic'" placeholder="ENTRY FEE">
        </div>
        <div class="col-8 mb-3">
        </div>


        <div class="col-6 mb-3">
          <label>DISCIPLINE CODE</label>
          <nb-select fullWidth placeholder="Select discipline" [status]="form('discipline_code') ?'danger':'basic'"
                     formControlName="discipline_code">
            <nb-option *ngFor="let discipline of _discipline" [value]="discipline.option">{{ discipline.name }}
            </nb-option>
          </nb-select>
        </div>
        <div class="col-6 mb-3" *ngIf="horse_show.federation_code">
          <label>USEF SECTION CODE</label>
          <input nbInput fullWidth type="text" formControlName="usef_section_code" id="usef_section_code"
                 name="usef_section_code" [status]="form('usef_section_code') ?'danger':'basic'"
                 placeholder="USEF SECTION CODE">
        </div>
      </div>
    </form>
    <div style="display: flex; justify-content: space-between">
      <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

      <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
              class="btn-save">{{ edit ? 'UPDATE SECTION' : 'ADD SECTION' }}
      </button>
    </div>
    <br>
    <br>
    <div *ngIf="edit" style="margin-top: 30px">
      <h2>COMPETITIONS IN THIS SECTION</h2>
      <p *ngIf="_competitions.length === 0"> There are no competitions for this section</p>
      <div *ngIf="_competitions.length > 0">
        <nb-card-header class="row">
          <p class="col-2">Number</p>
          <p class="col-4">Name</p>
          <p class="col-2">Entry Fee</p>
          <p class="col-4">Date</p>
        </nb-card-header>
        <nb-list>
          <nb-list-item *ngFor="let competition of _competitions">
            <p class="col-2">{{ competition.competition_number }}</p>
            <p class="col-4">{{ competition.name }}</p>
            <p class="col-2">{{ competition.entry_fee }}</p>
            <p class="col-4">{{ competition.date | date }}</p>
          </nb-list-item>
        </nb-list>
      </div>

      <button nbButton shape="semi-round" status="primary" type="submit" (click)="addCompetition()"
              class="btn-save">{{ 'ADD COMPETITION' }}
      </button>
    </div>
  </nb-card-body>
</nb-card>
