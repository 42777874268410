<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} COMPETITION <span>FOR SECTION : {{section?.name}}</span></h2>
  </nb-card-header>
  <nb-card-body>

    <ng-template #dialogCompetition let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>COMPETITION ALREADY EXISTS</nb-card-header>
        <nb-card-body>DO YOU WANT TO EDIT THE EXISTING COMPETITION
          #{{ this.competitionForm.controls['competition_number'].value }}?
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close(); editExistingCompetition(false)">CLOSE</button>
          <button nbButton status="primary" (click)="ref.close(); editExistingCompetition(true)">EDIT</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <ng-template #dialogGroup let-data let-ref="dialogRef">
      <nb-card style="width: 90svw">
        <nb-card-body>
         <ngx-prize-money-add-edit [isModal]="true" (groupEvent)="handleGroupEvent($event)"></ngx-prize-money-add-edit>
        </nb-card-body>
        <nb-card-footer style="justify-content: end;display: flex;">
          <button nbButton status="danger" (click)="ref.close()">CLOSE</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <form [formGroup]="competitionForm" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>
      <div class="row">
        <div class="col-4 mb-3">
          <label>COMPETITION #</label>
          <input nbInput fullWidth type="number" formControlName="competition_number" id="validationCustom02"
                 name="name"  (keyup)="checkCompetition()"
                 placeholder="COMPETITION #" required>
        </div>
        <div class="col-8 mb-3">
          <label>COMPETITION NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="Name" required>
        </div>


        <div class="col-4 mb-3">
          <label>ENTRY FEE</label>
          <input nbInput fullWidth type="number" formControlName="entry_fee" id="entry_fee" name="entry_fee"
                 placeholder="ENTRY FEE">
        </div>
        <div class="col-8 mb-3">
          <label>POINT GROUP</label>
          <nb-select fullWidth placeholder="Select point group"
                     formControlName="point_group_number">
            <nb-option *ngFor="let point of _points" [value]="point.point_group_number">{{ point.name }}</nb-option>
          </nb-select>
        </div>

        <div class="col-6 mb-3">
          <label style="display: block;">
            Prize $ GROUP
            <span class="add-group-button" (click)="addPrizeGroup()">+ADD</span>
          </label>
          <nb-select fullWidth placeholder="Select prize group"
                     formControlName="prize_group_number">
            <nb-option *ngFor="let prize of _prizes" [value]="prize.prize_group_number">{{ prize.name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-6 mb-3">
          <label>NOMINATION FEES</label>
          <nb-select fullWidth placeholder="Select nomination fees" multiple
                     formControlName="nomination_fees">
            <nb-option *ngFor="let tax of _tax" [value]="tax.id">{{ tax.name }}</nb-option>
          </nb-select>
        </div>

        <div class="col-3 mb-3">
          <label>LEVEL</label>
          <input nbInput fullWidth type="text" formControlName="level" id="level" name="level"
                 placeholder="Level">
        </div>
        <div class="col-3 mb-3">
          <label>UNIT</label>
          <nb-select fullWidth placeholder="Select unit"
                     formControlName="unit">
            <nb-option *ngFor="let unit of _units" [value]="unit.option">{{ unit.name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-6 mb-3">
          <label>RULES</label>
          <nb-select fullWidth placeholder="Select rules"
                     formControlName="rule_id">
            <nb-option *ngFor="let rule of _rules" [value]="rule.id">{{ rule.name }}</nb-option>
          </nb-select>
        </div>


        <div class="col-6 mb-3">
          <label>DATE</label>
          <input nbInput fullWidth placeholder="DATE" [nbDatepicker]="date" formControlName="date">
          <nb-datepicker #date></nb-datepicker>
        </div>
        <div class="col-6 mb-3">
          <label>TIME</label>
          <input nbInput type="text" fullWidth [nbTimepicker]="timepicker" formControlName="scheduled_start_time"/>
          <nb-timepicker #timepicker="nbTimepicker" twelveHoursFormat></nb-timepicker>
        </div>

        <div class="col-6 mb-3" *ngIf="showSelectVenue">
          <label>VENUE</label>
          <nb-select fullWidth placeholder="Select venue" (selectedChange)="selectedVenue()"
                     formControlName="venue_number">
            <nb-option *ngFor="let venue of _venues" [value]="venue.id">{{venue.name !== null ? venue.name : venue.fei_name  }}</nb-option>
          </nb-select>
        </div>

        <div class="col-6 mb-3"  nbPopover="{{_arenas.length === 0 ? 'PLEASE SELECT VENUE':''}}" nbPopoverTrigger="hover"
        nbPopoverPlacement="bottom">
          <label>ARENA</label>
          <nb-select fullWidth placeholder="Select arena"
                     formControlName="arena_number">
            <nb-option *ngFor="let arena of _arenas" [value]="arena.arena_number">{{ arena.name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-6 mb-3" *ngIf="horse_show.venue_id !== null"></div>


        <div class="col-3 mb-3">
          <label>TYPE</label>
          <nb-select fullWidth  placeholder="SELECT UNIT"
                     formControlName="type">
            <nb-option [value]="'OPEN'">OPEN</nb-option>
            <nb-option [value]="'JUNIOR'">JUNIOR</nb-option>
            <nb-option [value]="'AMATEUR'">AMATEUR</nb-option>
            <nb-option [value]="'JUNIOR/AMATEUR'">JUNIOR/AMATEUR</nb-option>
          </nb-select>
        </div>
        <div class="col-9 mb-3" style="margin-top: 33px">
          <nb-checkbox status="basic" formControlName="flat" >FLAT CLASS (COMPETITORS ***** CONCURRENTLY NOT
            SEQUENTIALLY)
          </nb-checkbox>
        </div>

        <div class="col-6 form-floating mb-3">
          <label>(USEF COMPETITIONS ONLY) USEF SECTION CODE</label>
          <input nbInput fullWidth type="text" formControlName="usef_section_code" id="usef_section_code"
                 name="usef_section_code"
                 placeholder="USEF SECTION CODE">
        </div>
        <div class="col-6 form-floating mb-3"></div>


        <div class="col-6 form-floating mb-3">
          <label>ADD SPONSOR</label>
          <input nbInput fullWidth type="text" formControlName="sponsor" id="sponsor" name="sponsor"
                 placeholder="ADD SPONSOR">
        </div>
        <div class="col-6 mb-3">
          <label>SPONSOR TYPE</label>
          <nb-select fullWidth [selected]="selected" placeholder="Select sponsor type"
                     formControlName="sponsor_type">
            <nb-option value="TITLE">TITLE</nb-option>
            <nb-option value="PRESENTING">PRESENTING</nb-option>
          </nb-select>
        </div>
      </div>

      <button nbButton shape="semi-round" status="primary" type="submit"
              class="btn-save">{{ edit ? 'UPDATE' : 'SAVE' }}
      </button>
<!--      <button *ngIf="edit" nbButton shape="semi-round" status="danger" type="submit"-->
<!--              class="btn-save">DELETE COMPETITION-->
<!--      </button>-->
    </form>
  </nb-card-body>
</nb-card>
