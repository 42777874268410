import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NbToastrService} from '@nebular/theme';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'ngx-horse-add-edit',
  templateUrl: './horse-add-edit.component.html',
  styleUrls: ['./horse-add-edit.component.scss'],
})
export class HorseAddEditComponent implements OnInit {

  horseForm: FormGroup = new FormGroup({
    current_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    birth_name: new FormControl(''),
    commercial_name: new FormControl(''),
    date_of_birth: new FormControl(''),
    color: new FormControl(''),
    color_complement: new FormControl(''),
    pony: new FormControl(false),
    size_code: new FormControl(''),
    gender: new FormControl(''),
    gelded: new FormControl(''),
    gelded_date: new FormControl(''),
    height_hands: new FormControl(''),
    heel_measurement: new FormControl(''),
    height_cm: new FormControl(''),
    markings: new FormControl(''),
    breed: new FormControl(''),

    breed_other: new FormControl(''),

    breeder: new FormControl(''),
    sire: new FormControl(''),
    dam: new FormControl(''),
    dam_sire: new FormControl(''),
    country_of_origin: new FormControl(''),
    microchip_value: new FormControl(''),

    microchip_type: new FormControl(''),

    ueln: new FormControl(''),
    current_national_federation: new FormControl(''),
    current_national_id: new FormControl(''),
    current_fei_id: new FormControl(''),

    color_number: new FormControl(''),
    breed_number: new FormControl(''),
  });
  _colors: any = [];
  _breeds: any = [];
  colorComplements: any = [];
  edit: boolean = false;
  editHorse: any = {};
  membershipType = 'USEF';
  membershipId: any;
  _horseMemberships = [];
  @Input() isModal: boolean = false;
  @Input() horseId: string = '';
  @Output() horseEvent = new EventEmitter<unknown>();

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService) {
    this.getHorseColors();
    this.getHorseBreeds();
  }

  ngOnInit() {
    if (!this.isModal) {
      this.route.params.subscribe(params => {
        if (params['uuid']) {
          this.getHorse(params['uuid']);
          this.edit = true;
        }
      });
    } else {
      if (this.horseId !== '') {
      this.getHorse(this.horseId);
      this.edit = true;
      }
    }
  }

  form(name: string) {
    return this.horseForm.controls[name].invalid &&
      (this.horseForm.controls[name].touched || this.horseForm.controls[name].dirty);
  }

  onSubmit() {
    const body: any = {};
    Object.keys(this.horseForm.controls).forEach((key: string) => {
      if ((key !== 'date_of_birth' || key !== 'date_of_birth') && body[key] !== null) {
        body[key] = this.horseForm.controls[key].value;
      } else if ((key === 'date_of_birth' || key === 'date_of_birth') && body[key] !== null) {
        body[key] = this.horseForm.controls[key].value.toISOString();
      }
    });
    if (this.horseForm.valid) {
      if (!this.edit) {
        this.showService.postHorse(body).then(res => {
          this.toastService.success('', 'Horse created');
          this.edit = true;
          this.editHorse = res;
          if (this.isModal) {
            this.horseEvent.emit(res);
          }
        }, error => {
          this.toastService.danger('', 'Error creating Horse');
        });
      } else {
        body.id = this.editHorse.id;
        // @ts-ignore
        this.showService.putHorse(body, this.editHorse.id).then(res => {
          if (this.isModal) {
            this.horseEvent.emit(res);
          }
          this.toastService.success('', 'Horse updated');
        }, error => {
          this.toastService.danger('', 'Error updating horse');
        });
      }
    } else {
      Object.keys(this.horseForm.controls).forEach((key: string) => {
        if (!this.horseForm.controls[key].disabled) {
          this.horseForm.controls[key].markAsTouched();
        }
      });
      this.toastService.warning('Please check the form!', 'Invalid form');
    }
  }

  setColor(color: any) {
    this.colorComplements = [];
    this._colors.forEach((el: any) => {
      if (el.color_name === color) {
        this.colorComplements = el.color_complements.split(', ');
      }
    });
  }

  private getHorseColors() {
    this.showService.getHorseColors().then().then(colors => {
      this._colors = colors;
    });
  }

  private getHorseBreeds() {
    this.showService.getHorseBreeds().then().then(breeds => {
      this._breeds = breeds;
    });
  }

  private getHorse(param: any) {
    this.showService.getHorse(param).then().then(horse => {
      this.editHorse = horse;
      for (const key in horse) {
        if (this.horseForm.get(key)) {
          this.horseForm.controls[key].setValue(horse[key]);
        }
      }
      this.horseForm.controls['date_of_birth'].setValue(new Date(horse.date_of_birth));
      if (horse.gelded_date !== null || horse.gelded_date !== '') {
        this.horseForm.controls['gelded_date'].setValue(new Date(horse.gelded_date));
      }
    });

    this.showService.getHorseMemberships(param).then().then(membership => {
      this._horseMemberships = membership;
    });
  }

  addHorseMembership() {
    if (this.membershipId !== '') {
      this.showService.postHorseMemberships({federation: this.membershipType, membership_id: this.membershipId},
        this.editHorse.id).then().then(membership => {
        this._horseMemberships.push(membership);
      });
    }
  }

  resetForm() {
    this.edit = false;
    this.editHorse = {};
    this.horseForm.reset();
  }
}
