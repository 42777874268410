import {Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Position} from '../../../models/position';
// @ts-ignore
import * as countryData from '../../../../assets/data/countries-states.json';

@Component({
  selector: 'ngx-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @ViewChild('search', {static: true})
  searchElementRef: ElementRef;
  @Output()
  positionChanged: EventEmitter<Position> = new EventEmitter<Position>();
  @Output() addressAdded: EventEmitter<any> = new EventEmitter<any>();

  addressForm: FormGroup = new FormGroup({
    street_number: new FormControl(''),
    address_line1: new FormControl(''),
    address_line2: new FormControl(''),
    address_line3: new FormControl(''),
    locality: new FormControl(''),
    region: new FormControl(''),
    postal_code: new FormControl(''),
    country_code: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
  });
  addAddress: boolean;
  countries = countryData['default'];
  states: any = [];
  searchedAddress: boolean = false;

  constructor(private ngZone: NgZone) {
    this.addressForm.controls['country_code'].setValue('USA');
    this.setCountry();
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
    );

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        this.addressForm.reset();
        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.positionChanged.emit(new Position(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ));
        // store data in 'addressForm'
        this.addressForm.controls['address_line3'].setValue(place.formatted_address);
        this.addressForm.controls['latitude']
          .setValue(place.geometry.viewport[Object.keys(place.geometry.viewport)[0]].lo);
        this.addressForm.controls['longitude']
          .setValue(place.geometry.viewport[Object.keys(place.geometry.viewport)[1]].lo);

        place.address_components.forEach((el: any) => {
          if (el.types.includes('street_number')) {
            this.addressForm.controls['address_line1'].setValue(el.long_name);
          } else if (el.types.includes('route')) {
            this.addressForm.controls['address_line1'].setValue(
              (this.addressForm.controls['address_line1'].value !== null ?
                this.addressForm.controls['address_line1'].value + ' ' : '') + el.long_name);
          } else if (el.types.includes('locality')) {
            this.addressForm.controls['locality'].setValue(el.long_name);
          } else if (el.types.includes('administrative_area_level_2') && this.addressForm.controls['locality'].value === '') {
            this.addressForm.controls['locality'].setValue(el.long_name);
          } else if (el.types.includes('administrative_area_level_1')) {
            this.addressForm.controls['region'].setValue(el.short_name);
          } else if (el.types.includes('postal_code')) {
            this.addressForm.controls['postal_code'].setValue(el.long_name);
          } else if (el.types.includes('country')) {
            switch (el.short_name) {
              case 'US':
                this.addressForm.controls['country_code'].setValue('USA');
                break;
              case 'CA':
                this.addressForm.controls['country_code'].setValue(el.short_name);
                break;
              default :
                this.addressForm.controls['country_code'].setValue('');
                break;
            }
          }
          this.searchedAddress = true;
          if (this.addressForm.controls['locality'].value === '') {
            this.addressForm.controls['locality'].setValue(el.long_name);
          }
        });
      });
    });
  }

  onSubmitAddress() {
    const body = {
      address_line1: this.addressForm.controls['address_line1'].value,
      address_line2: this.addressForm.controls['address_line2'].value,
      address_line3: this.addressForm.controls['address_line3'].value,
      locality: this.addressForm.controls['locality'].value,
      region: this.addressForm.controls['region'].value,
      postal_code: this.addressForm.controls['postal_code'].value,
      country_code: this.addressForm.controls['country_code'].value,
      latitude: this.addressForm.controls['latitude'].value,
      longitude: this.addressForm.controls['longitude'].value,
    };
    this.addressAdded.emit(body);
  }

  setCountry() {
    this.states = [];
    this.addressForm.controls['region'].setValue('');
    const countrySelected = this.addressForm.controls['country_code'].value;
    this.countries.forEach((country: any) => {
      if (country.abbreviation === countrySelected) {
        this.states = country.states;
      }
    });
  }
}
