<nb-card [accent]="!edit ? 'info' : 'warning'" [nbSpinner]="getVenueAPI" nbSpinnerStatus="primary">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} VENUE</h2>
  </nb-card-header>
  <nb-card-body>

    <ng-template #dialogVenue let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-header>VENUE ALREADY EXISTS</nb-card-header>
        <nb-card-body>DO YOU WANT TO EDIT THE EXISTING VENUE
          #{{ this.venueForm.controls['venue_number'].value }}?
        </nb-card-body>
        <nb-card-footer style="justify-content: space-around;display: flex;">
          <button nbButton status="danger" (click)="ref.close(); editExistingVenue(false)">CLOSE</button>
          <button nbButton status="primary" (click)="ref.close(); editExistingVenue(true)">EDIT</button>
        </nb-card-footer>
      </nb-card>
    </ng-template>

    <form [formGroup]="venueForm" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>VENUE NUMBER</label>
          <input nbInput fullWidth type="number" formControlName="venue_number" id="venue_number" name="venue_number"
                 placeholder="VENUE NUMBER" (keyup)="checkVenue()" required>
        </div>
        <div class="col-8 form-floating mb-3">
          <label>VENUE NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" id="name" name="name"
                 placeholder="VENUE NAME" required>
        </div>
      </div>

      <div class="form-floating mb-3">
        <label>DESCRIPTION</label>
        <textarea rows="5" nbInput fullWidth type="text" formControlName="description" class="form-control"
                  id="validationCustom05"
                  name="address_2" placeholder="DESCRIPTION" ></textarea>
      </div>
      <div class="row">
        <div class="col-6 form-floating mb-3">
          <label>FEI NAME</label>
          <input nbInput fullWidth type="text" formControlName="fei_name" id="fei_name" name="fei_name"
                 placeholder="FEI NAME" >
        </div>
        <div class="col-6 form-floating mb-3">
          <label>SELECT COUNTRY</label>
          <nb-select fullWidth placeholder="SELECT COUNTRY" (selectedChange)="setCountry()"
                     formControlName="country">
            <nb-option *ngFor="let country of countries" [value]="country.abbreviation">{{ country.name }}</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="form-floating mb-3">
        <label>ADDRESS</label>

        <input nbInput fullWidth placeholder="Search for location" autocorrect="off" autocapitalize="off"
               spellcheck="off"
               type="text" class="form-control search-location" formControlName="address" #search>

        <!--        <input nbInput fullWidth type="text" formControlName="address" id="address" name="address"-->
        <!--               placeholder="ADDRESS" >-->

      </div>

      <div class="row">
        <div class="col-4 form-floating mb-3">
          <label>CITY</label>
          <input nbInput fullWidth type="text" formControlName="city" id="city" name="city"
                 placeholder="CITY" >
        </div>
        <div class="col-4 form-floating mb-3">
          <label>STATE/PROVINCE</label>
          <nb-select *ngIf="this.states.length > 0" fullWidth placeholder="STATE/PROVINCE" (change)="setCountry()"
                     formControlName="state">
            <nb-option *ngFor="let state of states" [value]="state.abbreviation">{{ state.name }}</nb-option>
          </nb-select>
          <input *ngIf="this.states.length === 0" nbInput fullWidth type="text" formControlName="state" id="state"
                 name="state"
                 placeholder="STATE/PROVINCE" >
        </div>
        <div class="col-4 form-floating mb-3">
          <label>ZIP/POSTAL CODE</label>
          <input nbInput fullWidth type="text" formControlName="postal_code" id="postal_code" name="postal_code"
                 placeholder="ZIP/POSTAL CODE" >
        </div>
      </div>

      <div class="row">
        <div class="col-8 form-floating mb-3">
          <label>WEB SITE ADDRESS</label>
          <input nbInput fullWidth type="text" formControlName="website" id="website" name="website"
                 placeholder="WEB SITE ADDRESS" >
        </div>
        <div class="col-4 form-floating mb-3">
          <label>PHONE</label>
          <input nbInput fullWidth type="number" formControlName="phone" id="phone" name="phone"
                 placeholder="PHONE" >
        </div>
      </div>

      <div class="row">
        <div class="col-2 mb-3">
          <i class="btn location" (click)="getLocation()">GET LOCATION</i>
        </div>
        <div class="col-3 form-floating mb-3">
          <label>LATITUDE</label>
          <input nbInput fullWidth type="text" formControlName="latitude" id="latitude" name="latitude"
                 placeholder="LATITUDE" >
        </div>
        <div class="col-3 form-floating mb-3">
          <label>LONGITUDE</label>
          <input nbInput fullWidth type="text" formControlName="longitude" id="longitude" name="longitude"
                 placeholder="LONGITUDE" >
        </div>
        <div class="col-4 form-floating mb-3">
          <label>TIME ZONE</label>
          <nb-select *ngIf="this.timeStamps.length > 0" fullWidth placeholder="TIME ZONE" (change)="setCountry()"
                     formControlName="utc_offset">
            <nb-option *ngFor="let time of timeStamps" [value]="time.offset">{{ time.text }}</nb-option>
          </nb-select>
          <input *ngIf="this.timeStamps.length === 0" nbInput fullWidth type="text" formControlName="time_zone"
                 id="time_zone" name="time_zone"
                 placeholder="TIME ZONE" >
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

        <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
                class="btn-save">{{ edit ? 'Update' : 'Save' }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
