<nb-card>
  <nb-card-header>
    <h2 class="card-title">VENUES</h2>
    <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditVenue('')">+Add</button>
  </nb-card-header>
  <nb-card-body>
    <table [nbTreeGrid]="dataSource" [nbSort]="dataSource">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>
      <ng-container *ngFor="let column of columnsHead; let index = index"
                    [nbTreeGridColumnDef]="column"
                    [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
          {{column}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column.toLowerCase().split(' ').join('_')] || '-'}}</td>
      </ng-container>
      <ng-container [nbTreeGridColumnDef]="'actions'">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditVenue(row.data.id)">Edit</button>
        </td>
      </ng-container>
    </table>
  </nb-card-body>
</nb-card>
