export class Horse_show {

  id: string = '';
  number: number = 0;
  horse_show_number: number = 0;
  name: string = '';
  location: string = '';
  short_name: string = '';
  credit_prize_money: boolean = false;
  start_date: Date = new Date();
  end_date: Date = new Date();
  venue_id: string = '';
  venue_name: string = '';
  venue_number: number = 0;
  horse_show_organizer_id: string = '';
  horse_show_organizer_number: number = 0;
  horse_show_organizer_short_name: string = '';
  organizer_short_name: string = '';
  federation_code: string = '';
  country_iso_3: string = '';
  country: string = '';
  usef_number: number = 0;
  usef_zone: number = 0;
  manager_usef_id: string = '';
  secretary_usef_id: string = '';

  // ec_hunter_rating: number = 0;
  // ec_jumper_rating: number = 0;
  // ec_show_number: number = 0;
  // ec_province: number = 0;
  // ec_sanction_level: number = 0;

  fei_id: string = '';
  non_horse_entry_number: number = 0;
  default_hs: boolean = false;
  closed: boolean = false;
  footer: string = '';
  last_modified: Date = new Date();

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}
