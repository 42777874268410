import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../providers/auth.service';
import {SessionService} from '../../providers/session.service';
import {User} from '../../models/user';
import {getDeepFromObject} from '@nebular/auth';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {HorseShowService} from '../../providers/horse-show.service';

@Component({
  selector: 'ngx-app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent {
  showMessages: any = {};
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  rememberMe = false;
  @Output() user_logged_in$: EventEmitter<User> = new EventEmitter<User>();
  protected options = {};
  @ViewChild('dialogLogin', {static: true})
  dialogLogin: ElementRef;
  horseShowOrganizers = [];
  horseShows = [];

  constructor(private authService: AuthService, private sessionService: SessionService,
              private horseShowService: HorseShowService,
              private toastService: NbToastrService, private dialogService: NbDialogService) {
  }

  login() {
    this.authService.login(this.user.username, this.user.password)
      .then(result => {
        if (result) {
          // login successful
          this.sessionService.user$.subscribe(user => {
            this.user_logged_in$.emit(user);
            // get horse show organizer and horse show in modal
            this.horseShowService.getHorseShowsOrganizers().then(res => {
              this.horseShowOrganizers = res;
              if (res.length === 0) {
                localStorage.setItem('redirect', 'organizer');
                location.reload();
              } else  if (res.length === 1) {
                this.getHorseSows(res[0]);
              } else {
                // @ts-ignore
                this.dialogService.open(this.dialogLogin, {closeOnBackdropClick: false});
              }
            });
          });
        } else {
          // login failed
          this.toastService.danger('', 'Username or password is incorrect');
        }
      }).catch((error: any) => {
      this.toastService.danger('', 'Username or password is incorrect');
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  getHorseSows(organizer: any) {
    this.sessionService.setHorse_show_organizer(organizer);
    this.horseShowService.getHorseShows(null).then(res => {
      this.horseShows = res;
      if (res.length === 0) {
        localStorage.setItem('redirect', 'show');
        location.reload();
      } else if (res.length === 1) {
        this.sessionService.setHorse_show(res[0]);
        location.reload();
      }
    });
  }
  setHorseShow(show: any) {
    this.sessionService.setHorse_show(show);
    location.reload();
  }
}
