import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Entries',
    icon: 'layout-outline',
    children: [
      {
        title: 'Create / Edit Entry',
        link: '/entry-add-edit',
      },
      // {
      //   title: 'Add / Scratch Competitions',
      // },
      // {
      //   title: 'Bill an Entry',
      // },
      {
        title: 'List All Entries',
        link: '/entry-list',
      },
      {
        title: 'Review Accounts',
        pathMatch: 'prefix',
      },
      {
        title: 'Accept Entries',
        pathMatch: 'prefix',
      },
      {
        title: 'Process Declarations',
        pathMatch: 'prefix',
      },
      {
        title: 'FEI Entry System',
        pathMatch: 'prefix',
      },
    ],
  },
  {
    title: 'Stabling',
    icon: 'home-outline',
    children: [
      {
        title: 'Assign & Review Stabling',
      },
    ],
  },
  {
    title: 'Competitions',
    icon: 'people-outline',
    children: [
      {
        title: 'List Competitions',
      },
    ],
  },
  {
    title: 'Start orders',
    icon: 'edit-2-outline',
    children: [
      {
        title: 'Manage Starting Order',
      },
    ],
  },
  {
    title: 'Results',
    icon: 'clipboard-outline',
    children: [
      {
        title: 'Verify Competitors',
      },
    ],
  },
  {
    title: 'Awards',
    icon: 'award-outline',
    children: [
      {
        title: 'Calculate Championships',
      },
    ],
  },
  {
    title: 'Reports',
    icon: 'file-text-outline',
    children: [
      {
        title: 'Overview',
      },
    ],
  },
  {
    title: 'Financials',
    icon: 'pricetags-outline',
    children: [
      {
        title: 'New',
      },
    ],
  },
  {
    title: 'Find',
    icon: 'search-outline',
    children: [
      {
        title: 'People',
        link: '/person',
      },
    ],
  },
  {
    title: 'Set up',
    icon: 'settings-2-outline',
    children: [
      {
        title: 'Horse Show Organizer',
        link: '/horse-show-organizers',
      },
      {
        title: 'Horse Show',
        link: '/horse-show',
      },
      {
        title: 'Horses',
        link: '/horses',
      },
      {
        title: 'Venues',
        link: '/venues',
      },
      {
        title: 'Arenas',
        link: '/arenas',
      },
      {
        title: 'Sections',
        link: '/sections',
      },
      {
        title: 'Competitions',
        link: '/competitions',
      },
      {
        title: 'Entries',
        link: '/entries',
      },
    ],
  },
  {
    title: 'Billing',
    icon: 'shopping-cart-outline',
    children: [
      {
        title: 'Bill item',
        link: '/billing-template-list',
      },
      {
        title: 'Tax item',
        link: '/tax-template-list',
      },
      {
        title: 'Prize money groups',
        link: '/prize-money',
      },
    ],
  },
];
