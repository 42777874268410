import {Component} from '@angular/core';
import {NgForOf} from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  NbButtonModule,
  NbCardModule, NbCheckboxModule,
  NbDatepickerModule,
  NbInputModule, NbListModule,
  NbOptionModule, NbRadioModule,
  NbSelectModule, NbToastrService, NbToggleModule,
} from '@nebular/theme';

@Component({
  selector: 'ngx-app-tax-template-add-edit',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    FormsModule,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
    NbInputModule,
    NbOptionModule,
    NbSelectModule,
    NbToggleModule,
    NbCheckboxModule,
    NbRadioModule,
    NbListModule,
  ],
  templateUrl: './tax-template-add-edit.component.html',
  styleUrls: ['./tax-template-add-edit.component.scss'],
})
export class TaxTemplateAddEditComponent {
  public edit: boolean = false;
  public _billing_templates: any[] = [];
  public horse_show: any;
  taxForm: FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    listing_order: new FormControl(''),
    applies_to_entry_fees: new FormControl(true),
    effective_date: new FormControl(new Date(), [Validators.required, Validators.minLength(1)]),
    sunset_date: new FormControl(new Date(), [Validators.required, Validators.minLength(1)]),
    percent_based: new FormControl(true),
    percentage_rate: new FormControl(''),
    amount: new FormControl(''),
    flag: new FormControl(true),
  });
  private editBillingItem: any;

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService) {
    // @ts-ignore
    this.horse_show = JSON.parse(localStorage.getItem('sms_default_horse_show'));
    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getTax(params['uuid']);
      }
    });
    this.getBillingItems();
  }

  onSubmit() {
    if (this.taxForm.valid) {
      const body: any = {
        code: this.taxForm.controls['code'].value,
        name: this.taxForm.controls['name'].value,
        listing_order: this.taxForm.controls['listing_order'].value,
        applies_to_entry_fees: this.taxForm.controls['applies_to_entry_fees'].value,
        effective_date:  this.taxForm.controls['effective_date'].value.toISOString(),
        sunset_date:  this.taxForm.controls['sunset_date'].value.toISOString(),
        percent_based: this.taxForm.controls['percent_based'].value,
        flat_amount: !this.taxForm.controls['percent_based'].value,
        percentage_rate: this.taxForm.controls['percentage_rate'].value,
        amount: this.taxForm.controls['amount'].value,
        // flag: this.taxForm.controls['flag'].value,
      };

      if (!this.edit) {
        this.showService.postTaxTemplates(body).then(res => {
          this.taxForm.reset();
          this.toastService.success('', 'Tax template created');
          const items: any = [];
          this._billing_templates.forEach((el: any) => {
            if (el.check) {
              delete el.check;
              items.push(el);
              el.check = false;
            }
          });
          if (items.length > 0) {
            this.showService.postTaxTemplatesItems(res.id, items).then(() => {
              this.toastService.success('', 'Tax item updated');
            });
          }

        }, error => {
          this.toastService.danger('', 'Error creating tax template');
        });
      } else {
        body.id = this.editBillingItem.id;
        // @ts-ignore
        this.showService.putTaxTemplates(this.editBillingItem.id, body).then(res => {
          this.toastService.success('', 'Bill item updated');
        }, error => {
          this.toastService.danger('', 'Error updating bill item');
        });
      }
    } else {
      this.toastService.warning('', 'Please check the form');
    }
  }

  getBillingItems() {
    this.showService.getBillingTemplates(this.horse_show.id).then((templates: []) => {
      templates.forEach((el: any) => {
        el.check = false;
      });
      this._billing_templates = templates;
    });
  }

  private getTax(code: string) {
    this.showService.getTaxTemplate(code).then((tax: any) => {
      this.editBillingItem = tax ;
      this.edit = true;
      this.taxForm.controls['code'].setValue(tax.code);
      this.taxForm.controls['name'].setValue(tax.name);
      this.taxForm.controls['listing_order'].setValue(tax.listing_order);
      this.taxForm.controls['applies_to_entry_fees'].setValue(tax.applies_to_entry_fees);
      this.taxForm.controls['effective_date'].setValue(new Date(tax.effective_date));
      this.taxForm.controls['sunset_date'].setValue(new Date(tax.sunset_date));
      this.taxForm.controls['percent_based'].setValue(tax.percent_based);
      this.taxForm.controls['percentage_rate'].setValue(tax.percentage_rate);
      this.taxForm.controls['amount'].setValue(tax.amount);
    });
  }

  resetForm() {
    this.edit = false;
    this.editBillingItem = {};
    this.taxForm.reset();
  }
}
