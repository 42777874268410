import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Horse_show} from '../../../models/horse_show';
import {HorseShowService} from '../../../providers/horse-show.service';
import {DatePipe, NgForOf, NgIf, SlicePipe} from '@angular/common';
import {Router} from '@angular/router';
import {
  NbButtonModule,
  NbCardModule,
  NbListModule, NbToastrService,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
  NbTreeGridModule,
} from '@nebular/theme';

@Component({
  selector: 'ngx-app-horse-show',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    SlicePipe,
    DatePipe,
    NgIf,
    NbButtonModule,
    NbCardModule,
    NbListModule,
    NbTreeGridModule,
  ],
  templateUrl: './horse-show.component.html',
  styleUrls: ['./horse-show.component.scss'],
})
export class HorseShowComponent {

  _shows: Horse_show[] = [];
  columnsHead = ['Number', 'Name', 'Venue Name', 'Start Date', 'End Date'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];

  constructor(private showService: HorseShowService, private router: Router, private toastService: NbToastrService,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    if (localStorage.getItem('sms_default_horse_show_organizer')) {
      this.getHorseShows();
    }
  }

  private getHorseShows() {
    this.showService.getHorseShows(null).then((shows: any) => {
      // @ts-ignore
      this._shows = shows;
      shows.forEach((el: any) => {
        el.start_date = new Date(el.start_date).toDateString();
        el.end_date = new Date(el.end_date).toDateString();
        el.organizer = el.horse_show_organizer_short_name;
        el.number = el.horse_show_number;
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);    });
  }

  addEditShow(uuid: any) {
    localStorage.setItem('hs_edit', JSON.stringify(uuid));
    if (uuid !== '') {
      this.router.navigate(['horse-show-add-edit', {uuid: uuid.horse_show_number}]).then();
    } else {
      this.router.navigate(['horse-show-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
