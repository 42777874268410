import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgForOf} from '@angular/common';
import {Venues} from '../../../../models/venues';
import {Horse_show} from '../../../../models/horse_show';
import {Organizer} from '../../../../models/organizer';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  NbButtonModule,
  NbCardModule, NbCheckboxModule,
  NbComponentStatus, NbDatepickerModule, NbDialogService,
  NbInputModule,
  NbOptionModule,
  NbSelectModule, NbSpinnerModule, NbToastrService, NbToggleModule,
} from '@nebular/theme';
import {VenueAddEditComponent} from '../../venues/venue-add-edit/venue-add-edit.component';
import {AppSettings} from '../../../../app.settings';
import {SessionService} from '../../../../providers/session.service';

@Component({
  selector: 'ngx-app-horse-show-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    ReactiveFormsModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbOptionModule,
    NbSelectModule,
    NbToggleModule,
    NbDatepickerModule,
    NbCheckboxModule,
    NbSpinnerModule,
    VenueAddEditComponent,
  ],
  templateUrl: './horse-show-add-edit.component.html',
  styleUrls: ['./horse-show-add-edit.component.scss'],
})
export class HorseShowAddEditComponent {
  @ViewChild('dialogHorseShow', {static: true})
  dialogHorseShow: ElementRef;
  @ViewChild('venueDialog', {static: true})
  venueDialog: ElementRef;

  horseShowForm: FormGroup = new FormGroup({
    horse_show_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    location: new FormControl(''),
    short_name: new FormControl(''),
    credit_prize_money: new FormControl(false),
    horse_show_organizer_id: new FormControl(''),
    start_date: new FormControl(),
    end_date: new FormControl(),
    venue_id: new FormControl(''),
    federation_code: new FormControl(''),
    country_iso_3: new FormControl(''),
    usef_number: new FormControl(''),
    usef_zone: new FormControl(''),
    manager_usef_id: new FormControl(''),
    secretary_usef_id: new FormControl(''),
    fei_id: new FormControl(''),
    non_horse_entry_number: new FormControl(''),
    default_hs: new FormControl(false),
    closed: new FormControl(false),
    footer: new FormControl(''),
  });
  _venues: Venues[] = [];
  _show: Horse_show = new Horse_show();
  _organizers: Organizer[] = [];
  public edit: boolean = false;
  selected: any = 0;
  getHorseShowAPI: boolean = false;


  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private sessionService: SessionService,
              private toastService: NbToastrService, private dialogService: NbDialogService) {
    this.getVenues();
    // this.getHorseShow();
    this.getHorseOrganizers();

    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getHorseShow(params['uuid']);
        this.edit = true;
      }
    });
    if (localStorage.getItem(AppSettings.DEFAULT_HORSE_SHOW_STORAGE) === null) {
      this.toastService.warning(`Organizer doesn't have a horse show`, 'Please add a horse show');
    }
  }

  onSubmit() {
    const body: any = {
      horse_show_number: this.horseShowForm.controls['horse_show_number'].value,
      name: this.horseShowForm.controls['name'].value,
      location: this.horseShowForm.controls['location'].value,
      short_name: this.horseShowForm.controls['short_name'].value,
      credit_prize_money: this.horseShowForm.controls['credit_prize_money'].value,
      start_date: this.horseShowForm.controls['start_date'].value !== null ?
        new Date(this.horseShowForm.controls['start_date'].value).toISOString() : '',
      end_date: this.horseShowForm.controls['end_date'].value !== null ?
        new Date(this.horseShowForm.controls['end_date'].value).toISOString() : '',
      federation_code: this.horseShowForm.controls['federation_code'].value,
      country_iso_3: this.horseShowForm.controls['country_iso_3'].value,
      usef_number: this.horseShowForm.controls['usef_number'].value,
      usef_zone: this.horseShowForm.controls['usef_zone'].value,
      manager_usef_id: this.horseShowForm.controls['manager_usef_id'].value,
      secretary_usef_id: this.horseShowForm.controls['secretary_usef_id'].value,
      fei_id: this.horseShowForm.controls['fei_id'].value,
      non_horse_entry_number: this.horseShowForm.controls['non_horse_entry_number'].value,
      default_hs: this.horseShowForm.controls['default_hs'].value,
      closed: this.horseShowForm.controls['closed'].value,
      footer: this.horseShowForm.controls['footer'].value,
      horse_show_organizer_id: undefined,
      horse_show_organizer_number: undefined,
      horse_show_organizer_short_name: undefined,
      venue_id: undefined,
      venue_name: undefined,
      venue_number: undefined,
    };
    // set the organization and venue
    this._organizers.forEach((el: any) => {
      if (el.id === this.horseShowForm.controls['horse_show_organizer_id'].value) {
        body.horse_show_organizer_id = el.id;
        body.horse_show_organizer_number = el.number;
        body.horse_show_organizer_short_name = el.short_name;
      }
    });

    this._venues.forEach((el: any) => {
      if (el.id === this.horseShowForm.controls['venue_id'].value) {
        body.venue_id = el.id;
        body.venue_name = el.number;
        body.venue_number = el.short_name;
      }
    });

    if (this.horseShowForm.valid) {
      if (!this.edit) {
        this.showService.postHorseShow(body).then(res => {
          this.horseShowForm.reset();
          if (localStorage.getItem(AppSettings.DEFAULT_HORSE_SHOW_STORAGE) === null) {
            this.sessionService.setHorse_show(res);
            location.reload();
          }
          this.toastService.success('', 'Show created');
        }, error => {
          this.toastService.danger('', 'Error creating show');
        });
      } else {
        // set new body and add only the touched
        const updateBody: any = {};
        Object.keys(this.horseShowForm.controls).forEach((key: string) => {
          if (!this.horseShowForm.controls[key].pristine) {
            updateBody[key] = this.horseShowForm.controls[key].value;
          }
        });
        // @ts-ignore
        this.showService.putHorseShow(this._show.id, updateBody).then(res => {
          this.toastService.success('', 'Show updated');
          // it the res.id is the same as local update local
          if (res.id === this._show.id) {
            localStorage.setItem('sms_default_horse_show', JSON.stringify(res));
          }
        }, error => {
          this.toastService.danger('', 'Error updating show');
        });
      }
    } else {
      // console.log('NOT VALID')
      this.toastService.warning('Please check the form!', 'Invalid form');
    }
  }

  private getVenues() {
    this.showService.getShowVenues().then(venues => {
      // @ts-ignore
      this._venues = venues;
    });
  }

  private getHorseShow(number: string) {
    // @ts-ignore
    this.showService.getHorseShow(number).then(
      res => {
        this._show = res;
        this.setShow();
      },
    );
  }

  setShow() {
    this.edit = true;
    for (const key in this._show) {
      if (this.horseShowForm.get(key)) {
        this.horseShowForm.controls[key].setValue(this._show[key]);
        if (key === 'start_date' || key === 'end_date') {
          this.horseShowForm.controls[key].setValue(new Date(this._show[key]));
        }
      }
    }
  }

  private getHorseOrganizers() {
    this.showService.getShowOrganizers().then(organizers => {
      // @ts-ignore
      this._organizers = organizers;
    });
  }

  editExisting(edit: boolean) {
    if (edit) {
      this.horseShowForm.reset();
      this.setShow();

    } else {
      this.horseShowForm.controls['horse_show_number'].setValue('');
    }
  }

  checkHorseShow() {
    if (!this.getHorseShowAPI && this.horseShowForm.controls['horse_show_number'].value !== null) {
      this.getHorseShowAPI = true;
      setTimeout(() => {
        this.showService.getHorseShow(this.horseShowForm.controls['horse_show_number'].value).then(res => {
          this._show = res;
          // @ts-ignore
          this.dialogService.open(this.dialogHorseShow, {closeOnBackdropClick: false});
        }, error => {
        });
        this.getHorseShowAPI = false;
      }, 2000);
    }
  }

  createVenue() {
    // @ts-ignore
    this.dialogService.open(this.venueDialog, {closeOnBackdropClick: false});
  }

  resetForm() {
    this.edit = false;
    this.horseShowForm.reset();
  }

  handleVenue(event: any) {
    if (event) {
      this.getVenues();
    }
  }
}
