<nb-card class="list-card">
  <nb-card-header style="display: flex;justify-content: space-between;">
    <h2 class="card-title">ARENAS FOR VENUE</h2>
    <nb-select style="width: 400px;" placeholder="Select Venue" [(ngModel)]="venueSelected" (selectedChange)="getArenas()">
      <nb-option *ngFor="let venue of _venues" [value]="venue.id">{{ venue.name !== null ? venue.name : venue.fei_name }}</nb-option>
    </nb-select>
    <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditArena('')">+Add</button>
  </nb-card-header>
  <nb-card-body>
    <p *ngIf="_arenas.length === 0 && gotArenas">There are no arenas for this VENUE</p>
    <table *ngIf="_arenas.length > 0" [nbTreeGrid]="dataSource" [nbSort]="dataSource">
      <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>
      <ng-container *ngFor="let column of columnsHead; let index = index"
                    [nbTreeGridColumnDef]="column"
                    [showOn]="getShowOn(index)">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
          {{column}}
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column.toLowerCase().split(' ').join('_')] || '-'}}</td>
      </ng-container>
      <ng-container [nbTreeGridColumnDef]="'actions'">
        <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
        </th>
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditArena(row.data.id)">Edit</button>
        </td>
      </ng-container>
    </table>
<!--    <nb-list role="list">-->
<!--      <nb-list-item *ngFor="let arena of _arenas">-->
<!--        <div class="col-3 mb-3">-->
<!--          <label for="show_name">Name</label>-->
<!--          <p class="data" id="show_name">{{ arena.name }}</p>-->
<!--        </div>-->
<!--        <div class="col-3 mb-3">-->
<!--          <label for="show_venue">Number</label>-->
<!--          <p class="data" id="show_venue">{{ arena.arena_number }}</p>-->
<!--        </div>-->
<!--        <div class="col-3 mb-3">-->
<!--          <label for="show_location">Venue Number</label>-->
<!--          <p class="data" id="show_location">{{ arena.venue_number }}</p>-->
<!--        </div>-->
<!--        <div class="col-3 mb-3">-->
<!--          <button nbButton shape="semi-round" status="primary" class="btn add-button" (click)="addEditArena(arena.id)">-->
<!--            Edit-->
<!--          </button>-->
<!--        </div>-->
<!--      </nb-list-item>-->
<!--    </nb-list>-->
  </nb-card-body>
</nb-card>


