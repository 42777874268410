import {Component, ElementRef, ViewChild} from '@angular/core';
import {Horse_show_organizer} from '../../../../models/horse_show_organizer';
import {Person} from '../../../../models/person';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NbDialogService, NbToastrService} from '@nebular/theme';

@Component({
  selector: 'ngx-horse-show-organizer-add-edit',
  templateUrl: './horse-show-organizer-add-edit.component.html',
  styleUrls: ['./horse-show-organizer-add-edit.component.scss'],
})
export class HorseShowOrganizerAddEditComponent {


  constructor(private showService: HorseShowService, private route: ActivatedRoute, private router: Router,
              private toastService: NbToastrService, private dialogService: NbDialogService) {
    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getHorseShowOrganizer(params['uuid']);
        this.edit = true;
      }
    });
  }
  @ViewChild('dialogOrganizer', { static: true })
  dialogOrganizer: ElementRef;

  editOrganizer: any = {};
  getOrganizerAPI: boolean = false;
  person: Person = new Person();
  edit: boolean = false;
  organizationForm: FormGroup = new FormGroup({
    organizer_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    url: new FormControl(''),
    short_name: new FormControl(''),
    payment_gateway: new FormControl(''),
    merchant_account_id: new FormControl(''),
    collect_convenience_fee: new FormControl(''),
    convenience_fee_rate: new FormControl(''),
    convenience_fee_wording: new FormControl(''),
  });

  onSubmit() {
    const body: any = {
      organizer_number: this.organizationForm.controls['organizer_number'].value,
      name: this.organizationForm.controls['name'].value,
      url: this.organizationForm.controls['url'].value,
      short_name: this.organizationForm.controls['short_name'].value,
      payment_gateway: this.organizationForm.controls['payment_gateway'].value,
      merchant_account_id: this.organizationForm.controls['merchant_account_id'].value,
      collect_convenience_fee: this.organizationForm.controls['collect_convenience_fee'].value,
      convenience_fee_rate: this.organizationForm.controls['convenience_fee_rate'].value,
      convenience_fee_wording: this.organizationForm.controls['convenience_fee_wording'].value,
    };

    if (this.organizationForm.valid) {
      if (!this.edit) {
        this.showService.postShowOrganizers(body).then(res => {
          // this.organizationForm.reset();
          // redirect to organizer list
          this.router.navigate(['horse-show-organizers']).then();
          this.toastService.success('', 'Organizer created');
        }, error => {
          this.toastService.danger('', 'Error creating organizer');
        });
      } else {
        body.id = this.editOrganizer.id;
        this.showService.putShowOrganizers(body).then( res => {
          this.toastService.success('', 'Organizer updated');
        }, error => {
          this.toastService.danger('', 'Error updating organizer');
        });
      }
    } else {
      this.toastService.warning('Please check the form!', 'Invalid form');
    }
  }

  checkOrganization() {
    if (!this.getOrganizerAPI && this.organizationForm.controls['organizer_number'].value !== null) {
      this.getOrganizerAPI = true;
      setTimeout(() => {
        this.showService.getHorseShowsOrganizerByNumber(this.organizationForm.controls['organizer_number'].value)
          .then(res => {
          this.editOrganizer = res;
          // @ts-ignore
          this.dialogService.open(this.dialogOrganizer, { closeOnBackdropClick: false });
        }, error => {
            if (this.edit) {
              this.edit = !this.edit;
              const number = this.organizationForm.controls['organizer_number'].value;
              this.organizationForm.reset();
              this.organizationForm.controls['organizer_number'].setValue(number);
            }
        });
        this.getOrganizerAPI = false;
      }, 2000);
    }
  }

  getHorseShowOrganizer(id: string) {
    this.showService.getHorseShowsOrganizerById(id).then(organizer => {
      this.setOrganizer(organizer);
    });
  }

  setOrganizer(organizer: any) {
      this.edit = true;
      this.editOrganizer = organizer;
      for (const key in organizer) {
        if (this.organizationForm.get(key)) {
          this.organizationForm.controls[key].setValue(organizer[key]);
        }
      }
    }

  editExistingOrganizer(edit: boolean) {
    if (edit) {
      this.organizationForm.reset();
      this.setOrganizer(this.editOrganizer);
    } else {
      this.organizationForm.controls['organizer_number'].setValue('');
    }
  }

  resetForm() {
    this.edit = false;
    this.editOrganizer = {};
    this.organizationForm.reset();
  }
}
