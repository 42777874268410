import {Component} from '@angular/core';
import {NgForOf} from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {HorseShowService} from '../../../../providers/horse-show.service';
import {ActivatedRoute} from '@angular/router';
import {Venues} from '../../../../models/venues';
import {Arena} from '../../../../models/arena';
import {
  NbButtonModule,
  NbCardModule,
  NbInputModule,
  NbOptionModule,
  NbSelectModule, NbSpinnerModule,
  NbToastrService,
} from '@nebular/theme';

@Component({
  selector: 'ngx-app-arena-add-edit',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    NbButtonModule,
    NbCardModule,
    NbOptionModule,
    NbSelectModule,
    NbInputModule,
    NbSpinnerModule,
  ],
  templateUrl: './arena-add-edit.component.html',
  styleUrls: ['./arena-add-edit.component.scss'],
})
export class ArenaAddEditComponent {
  arenaForm: FormGroup = new FormGroup({
    venue_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    arena_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
    name: new FormControl('', [Validators.required, Validators.minLength(1)]),
    description: new FormControl(''),
    width_m: new FormControl(''),
    length_m: new FormControl(''),
    gate_latitiude: new FormControl(''),
    gate_longitude: new FormControl(''),
    orientation: new FormControl(''),
  });

  _venues: Venues[] = [];
  editArena: Arena | undefined;
  edit: boolean = false;
  selectedVenue: string = '';
  getArenaAPI: boolean = false;

  constructor(private showService: HorseShowService, private route: ActivatedRoute,
              private toastService: NbToastrService) {
    this.getVenues();
    this.route.params.subscribe(params => {
      if (params['uuid']) {
        this.getArena(params['uuid']);
        this.edit = true;
      }
      this.selectedVenue = params['venue'];
    });
  }

  onSubmit() {
    if (this.arenaForm.valid) {
      const body = {
        arena_number: this.arenaForm.controls['arena_number'].value,
        name: this.arenaForm.controls['name'].value,
        venue_id: 'string',
        venue_number: 0,
        description: this.arenaForm.controls['description'].value,
        width_m: this.arenaForm.controls['width_m'].value,
        length_m: this.arenaForm.controls['length_m'].value,
        gate_longitude: this.arenaForm.controls['gate_longitude'].value,
        gate_latitiude: this.arenaForm.controls['gate_latitiude'].value,
        orientation: this.arenaForm.controls['orientation'].value,
      };

      // this.arenaForm.controls['arena_lat'].value;
      const venueSelected = this.arenaForm.controls['venue_number'].value;
      this._venues.forEach((venue: Venues) => {
        if (venueSelected === venue.id) {
          body.venue_id = venue.id;
          body.venue_number = venue.venue_number;
        }
      });
      if (!this.edit) {
        this.showService.postArena(body).then(res => {
          this.arenaForm.reset();
          this.toastService.success('', 'Arena created');
        }, error => {
          this.toastService.danger('', 'Error creating arena');
        });
      } else {
        this.showService.putArena(this.editArena.id, body).then(res => {
          this.toastService.success('', 'Arena updated');
        }, error => {
          this.toastService.danger('', 'Error updating arena');
        });
      }
    } else {
      this.toastService.warning('', 'Please check the form');
    }
  }

  private getVenues() {
    this.showService.getShowVenues().then(venues => {
      this._venues = venues;
      this.arenaForm.controls['venue_number'].setValue(this.selectedVenue);
    });
  }

  getLocation() {
    if (!navigator.geolocation) {
    } else {
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.arenaForm.controls['gate_latitiude'].setValue(position.coords.latitude);
        this.arenaForm.controls['gate_longitude'].setValue(position.coords.longitude);
      });
    }
  }

  private getArena(uuid: string) {
    this.showService.getArena(uuid).then(editArena => {
      this.setArena(editArena);
    });
  }

  setArena(arena: any) {
    this.edit = true;
    this.editArena = arena;
    for (const key in arena) {
      if (this.arenaForm.get(key)) {
        this.arenaForm.controls[key].setValue(arena[key]);
      }
    }
  }


  editExistingArena(edit: boolean) {
    if (edit) {
      this.arenaForm.reset();
      this.edit = true;
      this.getArena(this.arenaForm.controls['arena_number'].value);
    } else {
      this.arenaForm.controls['competition_number'].setValue('');
    }
  }

  checkArena() {
      if (!this.getArenaAPI && this.arenaForm.controls['arena_number'].value !== null) {
        this.getArenaAPI = true;
        setTimeout(() => {
          this.showService.getArenaByNumber(this.selectedVenue, this.arenaForm.controls['arena_number'].value)
            .then((arena: any) => {
              this.setArena(arena);
            // @ts-ignore
            this.dialogService.open(this.dialogVenue, { closeOnBackdropClick: false });
          });
          this.getArenaAPI = false;
        }, 2000);
    }
  }

  resetForm() {
    this.edit = false;
    this.editArena = new Arena();
    this.arenaForm.reset();
  }
}
