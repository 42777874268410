import {Component} from '@angular/core';
import {NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-competitions',
  templateUrl: './competitions.component.html',
  styleUrls: ['./competitions.component.scss'],
})
export class CompetitionsComponent {
  columnsHead = ['Competition Number', 'Name', 'Arena Number', 'Entry Fee'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];
  sectionSelected: any = '';
  allCompetitions: any[] = [];
  competitions: any[] = [];
  _sections: any[] = [];
  loadingSpinner: boolean = false;

  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    if (localStorage.getItem('sms_default_horse_show_organizer')) {
      this.getCompetitions();
      this.getSections();
    }
  }

  private getCompetitions() {
    this.loadingSpinner = true;
    this.showService.getShowCompetitions().then(competitions => {
      this.allCompetitions = competitions;
      this.competitions = competitions;
      this.loadingSpinner = false;
      // competitions.forEach((el: any) => {
      //   el.country = el.country_iso_alpha_3;
      //   Object.keys(el).forEach(key => {
      //     if (typeof el[key] === 'boolean') {
      //       el[key] = el[key].toString();
      //     }
      //   });
      //   this.data.push({data: el});
      // });
      // this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addEditCompetition(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['competition-add-edit', {uuid: uuid}]).then();
    } else {
      this.router.navigate(['competition-add-edit', {section: true}]).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  // get competitions based on section selected and store section for creat
  selectSection() {
    if (this.sectionSelected === 'all') {
      localStorage.removeItem('section');
      this.competitions = this.allCompetitions;
      this.sectionSelected = '';
    } else {
      localStorage.setItem('section', JSON.stringify(this.sectionSelected));
      this.getCompetitionsBySection();
    }
  }

  getCompetitionsBySection() {
    this.loadingSpinner = true;
    this.showService.getSectionCompetitions(this.sectionSelected.section_number).then(competitions => {
      this.competitions = competitions;
      this.loadingSpinner = false;
      // this.data = [];
      // competitions.forEach((el: any) => {
      //   el.country = el.country_iso_alpha_3;
      //   Object.keys(el).forEach(key => {
      //     if (typeof el[key] === 'boolean') {
      //       el[key] = el[key].toString();
      //     }
      //   });
      //   this.data.push(el);
      // });
      // this.competitions = this.data;
    });
  }

  private getSections() {
    this.showService.getShowSections().then(sections => {
      this._sections = sections;
    });
  }
}
