import {Component, Input} from '@angular/core';
import {DatePipe, LowerCasePipe, NgForOf} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HorseShowService} from '../../../providers/horse-show.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule, NbIconModule,
  NbListModule, NbSortDirection,
  NbToggleModule, NbTreeGridDataSource, NbTreeGridDataSourceBuilder,
  NbTreeGridModule,
} from '@nebular/theme';


@Component({
  selector: 'ngx-app-tax-template',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    FormsModule,
    DatePipe,
    NbButtonModule,
    NbCardModule,
    NbListModule,
    NbToggleModule,
    NbCheckboxModule,
    NbTreeGridModule,
    LowerCasePipe,
  ],
  templateUrl: './tax-template.component.html',
  styleUrls: ['./tax-template.component.scss'],
})
// 	Applies To Entry Fees
//  applies_to_entry_fees
//  applies_to entry fees

export class TaxTemplateComponent {
  _taxTemplate: any = [];
  columnsHead = ['Code', 'Name', 'Effective Date', 'Sunset Date'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];

  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getTaxTemplates();
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  addTax(id: any) {
    if (id !== '') {
      this.router.navigate(['tax-template-add-edit', {uuid: id}]).then();
    } else {
      this.router.navigate(['tax-template-add-edit']).then();
    }
  }

  private getTaxTemplates() {
    this.showService.getTaxTemplates().then((res: any) => {
      this._taxTemplate = res;
      res.forEach((el: any) => {
        el.effective_date = new Date(el.effective_date).toDateString();
        el.sunset_date = new Date(el.sunset_date).toDateString();
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }
}
