import { Component } from '@angular/core';
import {NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-app-horse-show-organizer',
  templateUrl: './horse-show-organizer.component.html',
  styleUrls: ['./horse-show-organizer.component.scss'],
})
export class HorseShowOrganizerComponent {
  _horse_shows: any[] = [];
  columnsHead = ['Organizer Number', 'Name', 'Short name', 'Contact email', 'Address', 'Country'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];

  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getOrganizers();
  }

  private getOrganizers() {
    this.showService.getHorseShowsOrganizers().then(shows => {
      // @ts-ignore
      this._horse_shows = shows;
      shows.forEach((el: any) => {
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addHorseShow(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['horse-show-organizer-add-edit', {uuid: uuid}]).then();
    } else {
      this.router.navigate(['horse-show-organizer-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
