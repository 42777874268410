import { Component } from '@angular/core';
import {HorseShowService} from '../../providers/horse-show.service';
import {Router} from '@angular/router';
import {NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';

@Component({
  selector: 'ngx-entry-list',
  templateUrl: './entry-list.component.html',
  styleUrls: ['./entry-list.component.scss'],
})
export class EntryListComponent {
  _entries: any[] = [];
  columnsHead = ['Entry Number', 'Horses', 'Payee Name', 'Payer Name', 'Riders'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];
  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getEntries();
  }

  addEditEntry(id: string) {
    if (id !== '') {
      this.router.navigate(['entry-add-edit', {uuid: id}]).then();
    } else {
      this.router.navigate(['entry-add-edit']).then();
    }
  }

  private getEntries() {
    this.showService.getHSEntries().then( res => {
      res.forEach((el: any) => {
        Object.keys(el).forEach(key => {
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
