import { Component } from '@angular/core';
import {HorseShowService} from '../../../providers/horse-show.service';
import {Router} from '@angular/router';
import {NbTreeGridDataSource, NbTreeGridDataSourceBuilder} from '@nebular/theme';

@Component({
  selector: 'ngx-horses',
  templateUrl: './horses.component.html',
  styleUrls: ['./horses.component.scss'],
})
export class HorsesComponent {
  _horses: any[] = [];
  columnsHead = ['Current Name', 'Breed', 'Breeder', 'Color', 'Gender', 'Country'];
  allColumns = [...this.columnsHead, 'actions'];
  dataSource: NbTreeGridDataSource<any>;
  private data = [];


  constructor(private showService: HorseShowService, private router: Router,
              private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    this.getVenues();
  }

  private getVenues() {
    this.showService.getHorses().then(horses => {
      this._horses = horses;
      horses.forEach((el: any) => {
        el.country = el.country_iso_alpha_3;
        Object.keys(el).forEach(key => {
          el.country = el.country_of_origin;
          if (typeof el[key] === 'boolean') {
            el[key] = el[key].toString();
          }
        });
        this.data.push({data: el});
      });
      this.dataSource = this.dataSourceBuilder.create(this.data);
    });
  }

  addEditHorse(uuid: string) {
    if (uuid !== '') {
      this.router.navigate(['horse-add-edit', {uuid: uuid}]).then();
    } else {
      this.router.navigate(['horse-add-edit']).then();
    }
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}
