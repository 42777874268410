import { NgModule } from '@angular/core';
import {
    NbAccordionModule,
    NbAutocompleteModule,
    NbButtonModule, NbCalendarModule,
    NbCardModule,
    NbCheckboxModule, NbDatepickerModule, NbIconModule, NbInputModule, NbListModule,
    NbMenuModule, NbPopoverModule, NbRadioModule,
    NbSelectModule, NbSpinnerModule, NbTabsetModule, NbTimepickerModule,
    NbTreeGridModule,
} from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { PagesRoutingModule } from './pages-routing.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { EntryComponent } from './set-up/entry/entry.component';
import { EntryAddEditComponent } from './set-up/entry/entry-add-edit/entry-add-edit.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PersonComponent } from './set-up/person/person.component';
import { PersonAddEditComponent } from './set-up/person/person-add-edit/person-add-edit.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {GoogleMapsModule} from '@angular/google-maps';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { HorsesComponent } from './set-up/horses/horses.component';
import { HorseAddEditComponent } from './set-up/horses/horse-add-edit/horse-add-edit.component';
import { HorseShowOrganizerAddEditComponent } from './set-up/horse-show-organizer/horse-show-organizer-add-edit/horse-show-organizer-add-edit.component';
import { AddEditEntryComponent } from './entry-list/add-edit-entry/add-edit-entry.component';
import {EntryListComponent} from './entry-list/entry-list.component';
import {VenueAddEditComponent} from "./set-up/venues/venue-add-edit/venue-add-edit.component";

@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    DashboardModule,
    MiscellaneousModule,
    NbButtonModule,
    NbCardModule,
    NbTreeGridModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbCheckboxModule,
    NbDatepickerModule,
    NbInputModule,
    NbTimepickerModule,
    NbIconModule,
    FormsModule,
    NbSpinnerModule,
    NgxIntlTelInputModule,
    GoogleMapsModule,
    NbListModule,
    NbRadioModule,
    NbTabsetModule,
    NbAutocompleteModule,
    NbPopoverModule,
    NbCalendarModule,
    NbAccordionModule,
    VenueAddEditComponent,
  ],
  declarations: [
    PagesComponent,
    EntryComponent,
    EntryListComponent,
    EntryAddEditComponent,
    PersonComponent,
    PersonAddEditComponent,
    AddressFormComponent,
    HorsesComponent,
    HorseAddEditComponent,
    HorseShowOrganizerAddEditComponent,
    AddEditEntryComponent,
  ],
  exports: [
    PersonAddEditComponent,
    HorseAddEditComponent
  ]
})
export class PagesModule {
}
