<nb-card [accent]="!edit ? 'info' : 'warning'">
  <nb-card-header>
    <h2 class="nb-card-title">{{ edit ? 'EDIT' : 'ADD' }} BILL ITEM TEMPLATE</h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="billingForm" class="arena-validation" novalidate>
      <div class="row">
        <div class="col-6 mb-3">
          <label>CODE</label>
          <input nbInput fullWidth type="text" formControlName="code" id="validationCustom1"
                 name="code" required placeholder="CODE">
        </div>
        <div class="col-6 mb-3">
          <label>Choose Category</label>
          <nb-select fullWidth placeholder="Choose Category..." formControlName="category">
            <nb-option [value]="''" >Select Category</nb-option>
            <nb-option *ngFor="let categ of categories" [value]="categ.id">{{ categ.name }}</nb-option>
          </nb-select>
        </div>
        <div class="col-12 mb-3">
          <label>NAME</label>
          <input nbInput fullWidth type="text" formControlName="name" placeholder="NAME" id="validationCustom02"
                 name="address_2" required>
        </div>
      </div>
      <div class="row">
        <div class="col-6 mb-3">
          <div class="mb-3">
            <label>UNIT PRICE</label>
            <input nbInput fullWidth type="number" formControlName="unit_price" id="validationCustom03" name="name"
                   placeholder="UNIT PRICE" required>
          </div>
          <nb-radio-group formControlName="quantity_amount">
            <nb-radio [value]="true">QUANTITY BASED</nb-radio>
            <nb-radio [value]="false">AMOUNT BASED</nb-radio>
          </nb-radio-group>
        </div>
        <div class="col-6 form-floating mb-3">
          <div class="form-floating mb-3">
            <label>LISTING ORDER</label>
            <input nbInput fullWidth type="text" formControlName="listing_order" placeholder="LISTING ORDER"
                   id="validationCustom04"
                   name="address_2" required>
          </div>
          <div class="form-floating mb-3">
            <label>PER CHARGE QUANTITY</label>
            <input nbInput fullWidth type="text" formControlName="charge_quantity" placeholder="PER CHARGE QUANTITY"
                   id="validationCustom05"
                   name="address_2">
          </div>
          <div class="form-floating mb-3">
            <label>PER CHARGE AMOUNT</label>
            <input nbInput fullWidth type="text" formControlName="charge_amount" placeholder="PER CHARGE AMOUNT"
                   id="validationCustom06"
                   name="address_2">
          </div>
        </div>
      </div>


      <div class=" mb-3">
        <nb-toggle status="basic" formControlName="add_to_item_check">ADD TO ITEM</nb-toggle>
      </div>

      <div class="row">
        <div class="col-6">
          <div class=" mb-3">
            <nb-toggle status="basic" formControlName="nominating_check">NOMINATING FEE</nb-toggle>
          </div>
          <div class=" mb-3">
            <nb-toggle status="basic" formControlName="request_check">REQUEST ONLY</nb-toggle>
          </div>
        </div>
        <div class="col-6">
          <div class=" mb-3">
            <nb-toggle status="basic" formControlName="stabling_check">STABLING ITEM</nb-toggle>
          </div>
          <div class=" mb-3">
            <nb-toggle status="basic" formControlName="chargeable_check">CHARGEABLE</nb-toggle>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button nbButton shape="semi-round" status="warning" (click)="resetForm()" class="btn-save">Reset</button>

        <button nbButton shape="semi-round" status="primary" type="submit" (click)="onSubmit()"
                class="btn-save">{{ edit ? 'Update' : 'Save' }}
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
